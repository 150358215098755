import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import { RouteComponentProps } from "react-router-dom";
import * as MENU from "../../constants/menu";
import * as ROUTE from "../../constants/route";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";
import NewLink from "../common/newLink";
import React, { useEffect, useRef, useState } from "react";
import setDocumentTitle from "../../utils/document";
import SupplierDto from "../../models/supplierDto";
import supplierService from "../../services/supplierService";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
  {
    field: "vatNumber",
    headerText: "Adószám",
  },
];

const Suppliers = ({ history }: RouteComponentProps) => {
  const [supplies, setSuppliers] = useState<SupplierDto[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateSuppliers = async () => {
      const { data } = await supplierService.getSuppliers();
      setSuppliers(data);
    };

    populateSuppliers();
  }, []);

  const handleModify = () => {
    if (!selectedSupplier) return;
    history.push(`${ROUTE.SUPPLIERS}/${selectedSupplier.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "beszallitok.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.SUPPLIERS);

  return (
    <>
      <h1>{MENU.SUPPLIERS}</h1>
      <NewLink to={`${ROUTE.SUPPLIERS}/new`} label="Új beszállító" />
      <ModifyButton
        disabled={!selectedSupplier}
        onClick={handleModify}
        label="Beszállító módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="suppliers"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedSupplier: SupplierDto) => {
          setSelectedSupplier(selectedSupplier);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Suppliers;
