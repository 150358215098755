import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";

import TextField from "../form/textField";
import SaveTenantDto from "../../models/saveTenantDto";
import SubmitButton from "../form/submitButton";
import RemoveButton from "../form/removeButton";
import Form from "../form/form";

import tenantService from "../../api/tenants";
import setDocumentTitle from "../../utils/document";
import { handleError } from "../../utils/serverError";
import { toast } from "react-toastify";
import * as ROUTE from "../../constants/route";
import MultiSelectField from "../form/multiSelectField";
import ModuleDto from "../../models/moduleDto";
import moduleService from "../../services/moduleService";
import MultiLineTextField from "../form/multiLineTextField";

const validationSchema = yup.object().shape<SaveTenantDto>({
  name: yup.string().required().max(255),
  host: yup.string().required().max(255),
  modules: yup.array(),
  localSettings: yup.string(),
});

const TenantForm = (props: RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [initialValues, setInitialValues] = useState<SaveTenantDto>({
    name: "",
    host: "",
    modules: [],
    localSettings: "",
  });
  const [modules, setModules] = useState<ModuleDto[]>([]);
  const [title, setTitle] = useState("Új partner");

  useEffect(() => {
    const populateModules = async () => {
      const { data, ok, originalError } = await moduleService.getModules();
      if (!ok) return handleError(props, originalError);
      if (data) setModules(data);
    };
    populateModules();

    if (id === "new") return;

    const populateTenant = async () => {
      const { data, ok, originalError } = await tenantService.getTenant(id);

      if (!ok) return handleError(props, originalError);

      if (data)
        setInitialValues({
          name: data.name,
          host: data.host,
          modules: data.modules.map((module) => module.id),
          localSettings: data.localSettings ? data.localSettings : "",
        });
    };

    populateTenant();
  }, [id, props]);

  useEffect(() => {
    if (id === "new") return;
    setTitle(`${initialValues.name} módosítása`);
  }, [id, initialValues.name]);

  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);

  const handleOnSubmit = async (values: SaveTenantDto) => {
    console.log(values);
    try {
      await (id === "new"
        ? tenantService.addTenant(values)
        : tenantService.modifyTenant(id, values));
      toast.success("Partner sikeresen mentve.");
      props.history.push(ROUTE.TENANTS);
    } catch (e) {
      handleError(props, e);
    }
  };

  const handleRemove = async () => {
    try {
      await tenantService.removeTenant(id);
      toast.success("Partner sikeresen törölve.");
      props.history.replace(ROUTE.TENANTS);
    } catch (e) {
      handleError(props, e);
    }
  };

  return (
    <>
      <h1>{title}</h1>
      <Form
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        <TextField name="name" label="Megnevezés" required />
        <TextField name="host" label="Url" required append=".eszaf.hu" />
        <MultiSelectField
          name="modules"
          label="Modulok"
          labelKey="name"
          valueKey="id"
          options={modules}
        />
        <MultiLineTextField
          name="localSettings"
          label="Helyi kút beállítások"
        />
        <SubmitButton />
        {id !== "new" && <RemoveButton onRemove={handleRemove} />}
      </Form>
    </>
  );
};

export default TenantForm;
