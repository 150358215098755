import React from "react";

interface Props {
  label?: string;
  icon?: string;
}

const SubmitButton = ({ label = "Mentés", icon = "fa fa-save" }: Props) => {
  return (
    <button type="submit" className="btn btn-primary mr-1">
      <i className={icon} /> {label}
    </button>
  );
};

export default SubmitButton;
