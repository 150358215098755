import React from "react";
import CSS from "csstype";
import InputMask from "react-input-mask";

interface Props {
  mask: string;
  name: string;
  label: string | JSX.Element;
  value: string;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput = ({ mask, name, label, value, error, onChange }: Props) => {
  const divStyle: CSS.Properties = {};
  const labelStyle: CSS.Properties = {};
  if (label === "") {
    divStyle.margin = 0;
    labelStyle.margin = 0;
    labelStyle.display = "block";
  }
  return (
    <div className="form-group" style={divStyle}>
      <label htmlFor={name} style={labelStyle}>
        {label}
      </label>

      <InputMask
        mask={mask}
        name={name}
        value={value}
        onChange={onChange}
        id={name}
        className={error ? "form-control is-invalid" : "form-control"}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default MaskedInput;
