import React from "react";

interface RadioProps {
  name: string;
  group: string;
  label: string;
  value: string;
  checked: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

const Radio: React.FC<RadioProps> = ({
  name,
  group,
  label,
  value,
  checked,
  onChange
}) => {
  return (
    <div className="custom-control custom-radio custom-control-inline">
      <input
        type="radio"
        id={name + "_" + value}
        name={group}
        onChange={onChange}
        checked={checked}
        value={value}
        className="custom-control-input"
      />
      <label className="custom-control-label" htmlFor={name + "_" + value}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
