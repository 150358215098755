import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnModel,
  Inject,
  Page,
  Sort,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateColumnModel,
  Reorder,
  Resize,
  ColumnMenu,
  Group,
  GroupSettings,
  ContextMenu,
  ContextMenuItemModel,
  Column,
  FilterSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DataManager } from "@syncfusion/ej2-data";
import React, { Component } from "react";
import authStorage from "../../auth/storage";
import { CustomODataAdaptor } from "../../utils/costumOData4Adaptor.js";
import moment from "moment";
import { YYYY_MM_DD } from "../../utils/format";
import { toast } from "react-toastify";

interface Props {
  id: string;
  columns: ColumnModel[];
  aggregates?: AggregateColumnModel[];
  api: string;
  onGridReady?: (grid: GridComponent) => void;
  excelExportComplate?: () => void;
  rowSelected?: (row: any) => void;
  recordDoubleClick?: () => void;
  enablePersistence?: boolean;
  groupSettings?: Partial<GroupSettings>;
  contextMenuItems?: ContextMenuItemModel[];
  contextMenuClick?: (args: any) => void;
  contextMenuOpen?: (args: any) => void;
  queryCellInfo?: (args: any) => void;
}

class DataGridOData<T> extends Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props) {
    return this.props.api !== nextProps.api;
  }

  grid: GridComponent | null = null;

  filterMessage = (filterSettings: FilterSettingsModel) => {
    if (!filterSettings.columns) return "";
    return filterSettings.columns.reduce((prev, current) => {
      const header = (this.grid?.columns as ColumnModel[]).find(
        (column) => column.field === current.field
      );

      const headerText = header?.headerText;
      const operator =
        current.operator === "greaterthan"
          ? "<i>nagyobb</i> mint"
          : current.operator === "lessthan"
          ? "<i>kisebb</i> mint"
          : current.operator === "greaterthanorequal"
          ? "<i>nagyobb vagy egyenlő</i> mint"
          : current.operator === "lessthanorequal"
          ? "<i>kisebb vagy egyenlő<i/> mint"
          : current.operator === "notequal"
          ? "<i>nem egyenlő</i>"
          : current.operator === "startswith"
          ? "úgy <i>kezdődik</i>, hogy"
          : "<i>egyenlő</i>";

      let value = !current.value
        ? "-"
        : header?.type === "date"
        ? moment(current.value as string).format(YYYY_MM_DD)
        : current.value;

      return (
        prev +
        " - <b>" +
        headerText +
        ":</b> " +
        operator +
        " <b>" +
        value +
        "</b><br>"
      );
    }, "Szűrési mezőkben megadott megszorítások: <br>");
  };

  render() {
    return (
      <GridComponent
        id={"_" + this.props.id}
        locale="hu"
        contextMenuItems={this.props.contextMenuItems}
        contextMenuClick={(args) => {
          if (this.props.contextMenuClick && args)
            this.props.contextMenuClick(args.item);
        }}
        allowGrouping={true}
        groupSettings={{
          ...this.props.groupSettings,
          disablePageWiseAggregates: true,
        }}
        enablePersistence={
          this.props.enablePersistence !== undefined
            ? this.props.enablePersistence
            : true
        }
        gridLines="Both"
        rowHeight={30}
        allowPaging={true}
        allowExcelExport={true}
        allowPdfExport={true}
        allowSorting={true}
        allowFiltering={true}
        allowSelection={true}
        selectionSettings={{
          enableToggle: false,
        }}
        rowSelected={(args) => {
          if (this.props.rowSelected && args && args.data)
            this.props.rowSelected(args.data as T);
        }}
        recordDoubleClick={() => {
          if (this.props.recordDoubleClick) this.props.recordDoubleClick();
        }}
        allowResizing={true}
        allowReordering={true}
        showColumnMenu={true}
        actionFailure={(e: any) => {
          console.log(e);
          if (
            e?.error?.error?.response &&
            (e?.error?.error?.response as string).indexOf(
              "The limit of '5000' for Top query has been exceeded."
            )
          ) {
            toast.error(
              "Maximum 5000 elem kérdezhető le. Kérlek adjon meg szűrési felételeket."
            );
          }
        }}
        actionComplete={() => {
          if (!this.grid) return;
          this.grid.updateExternalMessage(
            this.filterMessage(this.grid.filterSettings)
          );
        }}
        ref={(g) => {
          if (this.props.onGridReady) {
            this.grid = g;

            if (this.grid) {
              const settings = localStorage.getItem("grid_" + this.props.id);

              if (settings) {
                const filterSettings = JSON.parse(settings)
                  .filterSettings as FilterSettingsModel;

                this.grid?.updateExternalMessage(
                  this.filterMessage(filterSettings)
                );
              }
            }

            this.props.onGridReady(g as GridComponent);
          }
        }}
        excelExportComplete={this.props.excelExportComplate}
        dataSource={
          new DataManager({
            adaptor: new CustomODataAdaptor(),
            url: this.props.api,
            headers: [{ "x-auth-token": authStorage.getToken() }],
          })
        }
        pageSettings={{ pageSize: 20, pageSizes: ["All", 20, 50, 100] }}
        filterSettings={{
          mode: "OnEnter",
          showFilterBarStatus: false,
        }}
        excelQueryCellInfo={(args: any) => {
          if (args.column.type === "number") {
            args.value = args.data[args.column.field];
          }
        }}
        queryCellInfo={(args: any) => {
          if (!args.cell.innerText) {
            args.cell.innerText = "-";
          }
          if (this.props.queryCellInfo) this.props.queryCellInfo(args);
        }}
        contextMenuOpen={(args: any) => {
          if (!args.rowInfo.rowData) {
            args.cancel = true;
            return;
          }
          if (!this.props.contextMenuOpen) return;
          this.props.contextMenuOpen(args);
        }}
      >
        <ColumnsDirective>
          <ColumnDirective visible={false} isPrimaryKey={true} field="id" />
          {this.props.columns.map((column, index) => (
            <ColumnDirective
              key={index}
              type="string"
              clipMode="EllipsisWithTooltip"
              {...column}
              filterBarTemplate={{
                create: (args: any) => {
                  const input = document.createElement("input");

                  const settings = localStorage.getItem(
                    "grid_" + this.props.id
                  );

                  if (!settings) return input;
                  const filterSettings = JSON.parse(settings)
                    .filterSettings as FilterSettingsModel;

                  this.grid?.updateExternalMessage(
                    this.filterMessage(filterSettings)
                  );

                  if (filterSettings.columns) {
                    const c = filterSettings.columns.find(
                      (c) => c.field === args.column.field
                    );

                    if (c?.value === null) {
                      input.value = "-";
                      return input;
                    }
                    if (c) {
                      if (args.column.type === "string") {
                        input.value = c.value as string;
                      }
                      if (args.column.type === "number") {
                        const value = (c.value as number)
                          .toString()
                          .replace(".", ",");

                        if (c.operator === "greaterthan")
                          input.value = ">" + value;
                        else if (c.operator === "lessthan")
                          input.value = "<" + value;
                        else if (c.operator === "greaterthanorequal")
                          input.value = ">=" + value;
                        else if (c.operator === "lessthanorequal")
                          input.value = "<=" + value;
                        else if (c.operator === "notequal")
                          input.value = "!=" + value;
                        else input.value = value;
                      }
                      if (args.column.type === "date") {
                        const value = (input.value = moment(
                          c.value as string
                        ).format(YYYY_MM_DD));

                        if (c.operator === "greaterthan")
                          input.value = ">" + value;
                        else if (c.operator === "lessthan")
                          input.value = "<" + value;
                        else if (c.operator === "greaterthanorequal")
                          input.value = ">=" + value;
                        else if (c.operator === "lessthanorequal")
                          input.value = "<=" + value;
                        else if (c.operator === "notequal")
                          input.value = "!=" + value;
                        else input.value = value;
                      }
                    }
                    return input;
                  }
                },
                write: (args: { element: Element; cc: Column }) => {
                  args.element.addEventListener("input", (evt) => {
                    if (this.grid && evt.currentTarget) {
                      const elem: HTMLInputElement = evt.currentTarget as HTMLInputElement;
                      if (elem.value === "-") {
                        elem.value = "-";
                      }
                    }
                  });
                },
              }}
            />
          ))}
        </ColumnsDirective>
        {this.props.aggregates && this.props.aggregates.length > 0 && (
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                {this.props.aggregates.map((aggregate, index) => (
                  <AggregateColumnDirective key={index} {...aggregate} />
                ))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
        )}
        <Inject
          services={[
            Page,
            Sort,
            Filter,
            Toolbar,
            ExcelExport,
            PdfExport,
            Aggregate,
            Reorder,
            Resize,
            ColumnMenu,
            Group,
            ContextMenu,
          ]}
        />
      </GridComponent>
    );
  }
}

export default DataGridOData;
