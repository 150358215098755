import React from "react";
import _ from "lodash";

interface PaginationProps {
  itemsCount: number;
  pageSize: number;
  currentPage: number;
  onPageChange(page: number): void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange
}) => {
  if (itemsCount === 0) return null;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
    <nav>
      <ul className="pagination">
        {
          <li
            className={currentPage === 1 ? "page-item disabled" : "page-item"}
          >
            <button onClick={() => onPageChange(1)} className="page-link">
              Első
            </button>
          </li>
        }
        {
          <li
            className={currentPage === 1 ? "page-item disabled" : "page-item"}
          >
            <button
              onClick={() => onPageChange(currentPage - 1)}
              className="page-link"
            >
              Előző
            </button>
          </li>
        }
        {
          <li className="page-item active">
            <button className="page-link">
              {currentPage} / {pages[pages.length - 1]}
            </button>
          </li>
        }
        {
          <li
            className={
              currentPage === pages[pages.length - 1]
                ? "page-item disabled"
                : "page-item"
            }
          >
            <button
              onClick={() => onPageChange(currentPage + 1)}
              className="page-link"
            >
              Következő
            </button>
          </li>
        }
        {
          <li
            className={
              currentPage === pages[pages.length - 1]
                ? "page-item disabled"
                : "page-item"
            }
          >
            <button
              onClick={() => onPageChange(pages[pages.length - 1])}
              className="page-link"
            >
              Utolsó
            </button>
          </li>
        }
      </ul>
    </nav>
  );
};

export default Pagination;
