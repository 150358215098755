import { ODataV4Adaptor } from "@syncfusion/ej2-data";

export class CustomODataAdaptor extends ODataV4Adaptor {
  onPredicate(predicate, query, requiresCast) {
    if (predicate.value === "-" && predicate.operator === "startswith") {
      predicate.value = null;
      predicate.operator = "equal";
    }
    let p = super.onPredicate(predicate, query, requiresCast);
    p = p.replace("NaN", "null");
    p = p.replace(`"-"`, null);
    return p;
  }

  processResponse(data, ds, query, xhr, request, changes) {
    const response = super.processResponse(
      data,
      ds,
      query,
      xhr,
      request,
      changes
    );

    return response;
  }
}
