import React from "react";

interface Props {
  onClick: () => void;
}

const PdfExportButton = ({ onClick }: Props) => (
  <button className="btn btn-primary my-2 mr-2" onClick={onClick}>
    <i className="fa fa-file-pdf-o" /> Pdf export
  </button>
);

export default PdfExportButton;
