import http from "./httpService";
import ForeignTaxProductCategoryDto from "../models/foreignTaxProductCategoryDto";

const baseUrl = "/api/foreign-tax-product-categories";

function getForeignTaxProductCategories(countryId: string) {
  return http.get<ForeignTaxProductCategoryDto[]>(baseUrl, {
    params: { countryId }
  });
}

function getForeignTaxProductCategory(id: string) {
  return http.get<ForeignTaxProductCategoryDto>(`${baseUrl}/${id}`);
}

export default {
  getForeignTaxProductCategories,
  getForeignTaxProductCategory
};
