import React from "react";

interface Props {
  onClick: () => void;
  icon: string;
  label: string;
  disabled?: boolean;
  type?: "primary" | "danger";
}

const Button = ({
  onClick,
  disabled = false,
  icon,
  label,
  type = "primary",
}: Props) => (
  <button
    disabled={disabled}
    className={`btn btn-${type} my-2 mr-2`}
    onClick={onClick}
  >
    <i className={icon} /> {label}
  </button>
);

export default Button;
