import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import Required from "../common/required";
import companyService from "../../services/companyService";
import confirm from "../../utils/confirm";
import countryService from "../../services/countryService";
import teaorService from "../../services/teaorService";
import SaveCompanyDto from "../../models/saveCompanyDto";
import CountryDto from "../../models/countryDto";
import TeaorDto from "../../models/teaorDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import CompanyDto from "../../models/companyDto";
import { numberToString } from "../../utils/format";

interface State extends FormState<SaveCompanyDto> {
  data: SaveCompanyDto;
  countries: CountryDto[];
  teaors: TeaorDto[];
}

class CompanyForm extends Form<
  SaveCompanyDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.nameOfTaxPlayer} - cég módosítása`
      : "Új cég";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      vatNumberOfTaxPlayer: "",
      nameOfTaxPlayer: "",
      zipCode: "",
      townVillage: "",
      publicPlaceName: "",
      typeOfPublicDomain: "",
      houseNumber: "",
      building: "",
      stairCase: "",
      floor: "",
      door: "",
      nameOfContactPerson: "",
      phoneNumberOfContactPerson: "",
      cardRefuelingSettlement: "1",

      residentTaxpayerPaymentOrPaymentAccountNumber: "",
      leadingPaymentServiceProviderAccountName: "",
      internationalIbanAccountNumber: "",
      foreignFinancialAccountManagerServiceName: "",
      allocationOfTheTotalAmountRequestedByDesignation: false,
      allocationCanAskForDesignation: false,
      orPartialApplicationForTheAllocationAndTransfer: false,

      locality: "",
      reportId: "",

      teaorId: "",
      teaors: [],

      bankCountryId: "",
      accountNumber: "",
      swift: "",
      bank: "",
      accountOwnerType: "",
      accountOwnerName: "",

      contactPerson: "",
      contactCountryId: "",
      address: "",
      idNumber: "",
      idType: "",
      idCountryId: "",
      contactPersonEmail: "",
      statement1: false,
      statement2: false,
    },
    errors: {},
    countries: [],
    teaors: [],
  };

  schema = yup.object().shape<SaveCompanyDto>({
    vatNumberOfTaxPlayer: yup.string().max(11),
    nameOfTaxPlayer: yup.string().max(80).required(),
    zipCode: yup.string().max(4),
    townVillage: yup.string().max(50),
    publicPlaceName: yup.string().max(40),
    typeOfPublicDomain: yup.string().max(20),
    houseNumber: yup.string().max(10),
    building: yup.string().max(6),
    stairCase: yup.string().max(6),
    floor: yup.string().max(6),
    door: yup.string().max(6),
    nameOfContactPerson: yup.string().max(40),
    phoneNumberOfContactPerson: yup.string().max(40),
    cardRefuelingSettlement: yup.string().required(),
    residentTaxpayerPaymentOrPaymentAccountNumber: yup.string().max(24),
    leadingPaymentServiceProviderAccountName: yup.string().max(80),
    internationalIbanAccountNumber: yup.string().max(32),
    foreignFinancialAccountManagerServiceName: yup.string().max(80),
    allocationOfTheTotalAmountRequestedByDesignation: yup.bool(),
    allocationCanAskForDesignation: yup.bool(),
    orPartialApplicationForTheAllocationAndTransfer: yup.bool(),
    locality: yup.string().max(40),
    reportId: yup.string().max(10),
    teaorId: yup.string(),
    teaors: yup.array(),

    bankCountryId: yup.string(),
    accountNumber: yup.string().max(32),
    swift: yup.string().max(11),
    bank: yup.string().max(80),
    accountOwnerType: yup.string(),
    accountOwnerName: yup.string().max(100),

    contactPerson: yup.string().max(40),
    contactCountryId: yup.string(),
    address: yup.string().max(80),
    idNumber: yup.string().max(20),
    idType: yup.string(),
    idCountryId: yup.string(),
    contactPersonEmail: yup.string().max(80),
    statement1: yup.bool(),
    statement2: yup.bool(),
  });

  async componentDidMount() {
    await this.populateCountries();
    await this.populateTeaors();
    if (this.id) await this.populateCompany();
  }

  async populateCountries() {
    const { data: countries } = await countryService.getCountries();
    this.setState({ countries });
  }

  async populateTeaors() {
    const { data: teaors } = await teaorService.getTeaors();
    this.setState({ teaors });
  }

  async populateCompany() {
    try {
      const { data } = await companyService.getCompany(this.id);

      const company = this.mapToViewModel(data);

      this.setState({ data: company });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(company: CompanyDto): SaveCompanyDto {
    return {
      vatNumberOfTaxPlayer: company.vatNumberOfTaxPlayer,
      nameOfTaxPlayer: company.nameOfTaxPlayer,
      zipCode: company.zipCode,
      townVillage: company.townVillage,
      publicPlaceName: company.publicPlaceName,
      typeOfPublicDomain: company.typeOfPublicDomain,
      houseNumber: company.houseNumber,
      building: company.building,
      stairCase: company.stairCase,
      floor: company.floor,
      door: company.door,
      nameOfContactPerson: company.nameOfContactPerson,
      phoneNumberOfContactPerson: company.phoneNumberOfContactPerson,
      cardRefuelingSettlement: numberToString(company.cardRefuelingSettlement),
      residentTaxpayerPaymentOrPaymentAccountNumber:
        company.residentTaxpayerPaymentOrPaymentAccountNumber,
      leadingPaymentServiceProviderAccountName:
        company.leadingPaymentServiceProviderAccountName,
      internationalIbanAccountNumber: company.internationalIbanAccountNumber,
      foreignFinancialAccountManagerServiceName:
        company.foreignFinancialAccountManagerServiceName,
      allocationOfTheTotalAmountRequestedByDesignation:
        company.allocationOfTheTotalAmountRequestedByDesignation,
      allocationCanAskForDesignation: company.allocationCanAskForDesignation,
      orPartialApplicationForTheAllocationAndTransfer:
        company.orPartialApplicationForTheAllocationAndTransfer,
      locality: company.locality,
      reportId: company.reportId,
      teaorId: numberToString(company.teaorId),
      teaors: company.teaors,
      bankCountryId: numberToString(company.bankCountryId),
      accountNumber: company.accountNumber,
      swift: company.swift,
      bank: company.bank,
      accountOwnerType: numberToString(company.accountOwnerType),
      accountOwnerName: company.accountOwnerName,
      contactPerson: company.contactPerson,
      contactCountryId: numberToString(company.contactCountryId),
      address: company.address,
      idNumber: company.idNumber,
      idType: numberToString(company.idType),
      idCountryId: numberToString(company.idCountryId),
      contactPersonEmail: company.contactPersonEmail,
      statement1: company.statement1,
      statement2: company.statement2,
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? companyService.modifyCompany(this.id, this.state.data)
        : companyService.addCompany(this.state.data));
      toast.success("Cég sikeresen mentve.");
      this.props.history.push(ROUTE.COMPANIES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a céget?", async () => {
      try {
        await companyService.removeCompany(this.id);
        toast.success("Cég sikeresen törölve.");
        this.props.history.replace(ROUTE.COMPANIES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#B"
                role="tab"
              >
                Ker. gáz - B, Külföldi áfa - A
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#D" role="tab">
                Ker. gáz - D
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#F" role="tab">
                Ker. gáz - F
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#KC" role="tab">
                Külföldi áfa - KC
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#K01" role="tab">
                Külföldi áfa - K01
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#TEAOR"
                role="tab"
              >
                Külföldi áfa - TEAOR
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="B" role="tabpanel">
              <br />
              <div className="row">
                <div className="col-sm-6">
                  {this.renderInput(
                    "nameOfTaxPlayer",
                    <Required label="Adózó neve" />
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderInput("vatNumberOfTaxPlayer", "Adózó adószám")}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3">
                  {this.renderInput("zipCode", "Irányítószám")}
                </div>
                <div className="col-sm-3">
                  {this.renderInput("townVillage", "Város, község")}
                </div>
                <div className="col-sm-3">
                  {this.renderInput("publicPlaceName", "Közterület neve")}
                </div>
                <div className="col-sm-3">
                  {this.renderInput("typeOfPublicDomain", "Közterület jellege")}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput("houseNumber", "Házszám")}
                </div>
                <div className="col">
                  {this.renderInput("building", "Épület")}
                </div>
                <div className="col">
                  {this.renderInput("stairCase", "Lépcsőház")}
                </div>
                <div className="col">{this.renderInput("floor", "Emelet")}</div>
                <div className="col">{this.renderInput("door", "Ajtó")}</div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderInput("nameOfContactPerson", "Ügyintéző neve")}
                </div>
                <div className="col-sm-6">
                  {this.renderInput(
                    "phoneNumberOfContactPerson",
                    "Ügyintéző telefonszáma"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderSelect(
                    "cardRefuelingSettlement",
                    <Required label="Kártyás tankolás elszámolás" />,
                    [
                      { id: 1, name: "Számla dátuma szerinti elszámolás" },
                      { id: 2, name: "Tankolás dátuma szerinti elszámolás" },
                    ],
                    "name"
                  )}
                </div>
              </div>
            </div>
            <div className="tab-pane fade show" id="D" role="tabpanel">
              <br />
              {this.renderInput(
                "residentTaxpayerPaymentOrPaymentAccountNumber",
                "Adózó belföldi pénzforgalmi vagy fizetési számlaszáma"
              )}
              {this.renderInput(
                "leadingPaymentServiceProviderAccountName",
                "Számlavezető pénzforgalmi szolgáltató neve"
              )}
              {this.renderInput(
                "internationalIbanAccountNumber",
                "IBAN nemzetközi pénzforgalmi jelzőszám"
              )}
              {this.renderInput(
                "foreignFinancialAccountManagerServiceName",
                "Külföldi számlát vezető pénzügyi szolgáltató neve"
              )}
              {this.renderCheckBox(
                "allocationOfTheTotalAmountRequestedByDesignation",
                "Teljes összeg kiutalása kérésének jelölése"
              )}
              {this.renderCheckBox(
                "allocationCanAskForDesignation",
                "Kiutalást nem kér jelölése"
              )}
              {this.renderCheckBox(
                "orPartialApplicationForTheAllocationAndTransfer",
                "Átvezetési és vagy részleges kiutalási kérelem"
              )}
              <br />
            </div>
            <div className="tab-pane fade show" id="F" role="tabpanel">
              <br />
              {this.renderInput("locality", "Helység")}
              {this.renderInput("reportId", "Bejelentő azonosító")}
            </div>
            <div className="tab-pane fade show" id="KC" role="tabpanel">
              <br />
              {this.renderSelect(
                "bankCountryId",
                "A számlavezető pénzforgalmi szolgáltató országa",
                this.state.countries,
                "name"
              )}
              {this.renderInput("accountNumber", "A fizetési számla száma")}
              {this.renderInput("swift", "BIC (SWIFT)")}
              {this.renderInput(
                "bank",
                "A fizetési számlát vezető pénzforgalmi szolgáltató neve és pontos címe"
              )}
              {this.renderSelect(
                "accountOwnerType",
                "A számlatulajdonos típusa",
                [
                  { id: 1, name: "Kérelmező" },
                  { id: 2, name: "Képviselő" },
                ],
                "name"
              )}
              {this.renderInput("accountOwnerName", "A számlatualjdonos neve")}
            </div>
            <div className="tab-pane fade show" id="K01" role="tabpanel">
              <br />
              {this.renderInput("contactPerson", "A kapcsolattartó neve")}
              {this.renderSelect(
                "contactCountryId",
                "Országkód",
                this.state.countries,
                "name"
              )}
              {this.renderInput("address", "Címe")}
              {this.renderInput("idNumber", "Személyazonosító szám")}
              {this.renderSelect(
                "idType",
                "Személyazonosító szám típusa",
                [
                  { id: 1, name: "Adóazonosító jel" },
                  { id: 2, name: "Áfás adószám" },
                  { id: 3, name: "Egyéb" },
                ],
                "name"
              )}
              {this.renderSelect(
                "idCountryId",
                "Kiállító ország kódja",
                this.state.countries,
                "name"
              )}
              {this.renderInput(
                "contactPersonEmail",
                "Elektronikus levelezési címe"
              )}
              {this.renderCheckBox(
                "statement1",
                "Az adó visszatéríttetési időszakban a 2008/9/EK tanácsi irányelv 3. cikk b) pontjának i. és ii. alpontjában említett ügyletek kivételével nem teljesített olyan termékértékesítést, szolgáltatásnyújtást, amelynek teljesítési helye - a visszatéríttetés tagállamának joga szerint - a visszatéríttetés tagállamának területén van."
              )}
              <br />
              {this.renderCheckBox(
                "statement2",
                "Amennyiben '1'-es áfakóddal rendelkező adóalany és nem kizárólag az általános forgalmi adóról szóló 2007. évi CXXVII. törvény mezőgazdasági tevékenységet folytató adóalanyra vonatkozó különös szabályokról szóló XIV. fejezetének hatálya alá tartozó tevékenységet végez."
              )}
              <br />
            </div>
            <div className="tab-pane fade show" id="TEAOR" role="tabpanel">
              <br />
              {this.renderSelect(
                "teaorId",
                "Üzleti főtevékenysége",
                this.state.teaors,
                (teaor) => teaor.code + " - " + teaor.name,
                null,
                ["code", "name"]
              )}
              {this.renderMultiSelect(
                "teaors",
                "A kérelmező további tevékenységei",
                this.state.teaors,
                (teaor) => teaor.code + " - " + teaor.name,
                ["code", "name"]
              )}
            </div>
          </div>
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default CompanyForm;
