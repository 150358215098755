import http from "./httpService";
import CardDto from "../models/cardDto";
import { AxiosResponse } from "axios";

const baseUrl = "/api/cards";

function getCards(): Promise<AxiosResponse<CardDto[]>> {
  return http.get<CardDto[]>(baseUrl);
}

export default {
  getCards,
};
