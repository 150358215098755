import httpService from "./httpService";
import TrailerDto from "../models/trailerDto";
import SaveTrailerDto from "../models/saveTrailerDto";

const baseUrl = "/api/trailers";

function getTrailers() {
  return httpService.get<TrailerDto[]>(baseUrl);
}

function getTrailer(id: string) {
  return httpService.get<TrailerDto>(`${baseUrl}/${id}`);
}

function addTrailer(saveTrailerDto: SaveTrailerDto) {
  return httpService.post(baseUrl, saveTrailerDto);
}

function modifyTrailer(id: string, saveTrailerDto: SaveTrailerDto) {
  return httpService.put(`${baseUrl}/${id}`, saveTrailerDto);
}

function removeTrailer(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getTrailers,
  getTrailer,
  addTrailer,
  modifyTrailer,
  removeTrailer
};
