import React, { Component } from "react";
import { Link } from "react-router-dom";
import placeService from "../../services/placeService";
import Table from "../common/table";
import _ from "lodash";
import paginate from "../../utils/paginate";
import Pagination from "../common/pagination";
import PlaceDto from "../../models/placeDto";
import SortColumn from "../../models/sortColumn";
import path from "../../utils/path";
import Column from "../../models/column";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";

interface State {
  page: number;
  pageSize: number;
  places: PlaceDto[];
  sortColumn: SortColumn;
}

class Places extends Component<{}, State> {
  state: State = {
    page: 1,
    pageSize: 10,
    places: [],
    sortColumn: { path: path<PlaceDto>()("name"), order: "asc" }
  };

  columns: Column<PlaceDto>[] = [
    {
      path: path<PlaceDto>()("name"),
      label: "Megnevezés",
      content: place => (
        <Link to={`${ROUTE.PLACES}/${place.id}`}>{place.name}</Link>
      )
    }
  ];

  async componentDidMount() {
    const { data: places } = await placeService.getPlaces();
    this.setState({ places });
  }

  handleSort = (sortColumn: SortColumn) => this.setState({ sortColumn });

  handlePageChange = (page: number) => this.setState({ page: page });

  render() {
    setDocumentTitle(MENU.PLACES);

    const { places, sortColumn, page, pageSize } = this.state;

    const sortedPlaces = _.orderBy(
      places,
      [sortColumn.path],
      [sortColumn.order]
    );

    const pagedPlaces = paginate(sortedPlaces, page, pageSize);

    return (
      <React.Fragment>
        <h1>{MENU.PLACES}</h1>
        <Link to={`${ROUTE.PLACES}/new`} className="btn btn-primary my-2">
          Új telephely
        </Link>
        <Table
          columns={this.columns}
          data={pagedPlaces}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
        <Pagination
          itemsCount={places.length}
          pageSize={pageSize}
          currentPage={page}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }
}

export default Places;
