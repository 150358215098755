import React from "react";
import { toast } from "react-toastify";
import confirm from "../../utils/confirm";
import Form, { FormState } from "../common/form";
import foreignTaxRefundService from "../../services/foreignTaxRefundService";
import companyService from "../../services/companyService";
import SaveForeignTaxRefundDto from "../../models/saveForeignTaxRefundDto";
import CompanyDto from "../../models/companyDto";
import { RouteComponentProps } from "react-router";
import ForeignTaxRefundDto from "../../models/foreignTaxRefundDto";
import { numberToString } from "../../utils/format";
import { ForeignTaxRefundType } from "../../models/foreignTaxRefundType";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";

interface State extends FormState<SaveForeignTaxRefundDto> {
  data: SaveForeignTaxRefundDto;
  companies: CompanyDto[];
}

class ForeignTaxRefundForm extends Form<
  SaveForeignTaxRefundDto,
  RouteComponentProps<{ countryId: string; foreignTaxRefundId: string }>,
  State
> {
  get title() {
    return this.foreignTaxRefundId
      ? `${this.state.data.name} - visszaigénylés módosítása`
      : "Új visszaigénylés";
  }
  get countryId() {
    return this.props.match.params.countryId;
  }
  get foreignTaxRefundId() {
    return this.props.match.params.foreignTaxRefundId === "new"
      ? ""
      : this.props.match.params.foreignTaxRefundId;
  }
  state: State = {
    data: {
      name: "",
      type: ForeignTaxRefundType.Yearly,
      year: "",
      quarter: "",
      companyId: "",
      countryId: this.countryId,
    },
    errors: {},
    companies: [],
  };

  schema = yup.object().shape<SaveForeignTaxRefundDto>({
    name: yup.string().required().max(255),
    type: yup.string().required(),
    year: yup.string().required(),
    quarter: yup.string(),
    companyId: yup.string().required(),
    countryId: yup.string().required(),
  });

  async componentDidMount() {
    await this.populateCompanies();
    if (this.foreignTaxRefundId) await this.populateForeignTaxRefund();
  }

  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  async populateForeignTaxRefund() {
    try {
      const { data } = await foreignTaxRefundService.getForeignTaxRefund(
        this.foreignTaxRefundId
      );
      const foreignTaxRefund = this.mapToViewModel(data);
      this.setState({ data: foreignTaxRefund });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(
    foreignTaxRefund: ForeignTaxRefundDto
  ): SaveForeignTaxRefundDto {
    return {
      name: foreignTaxRefund.name,
      type: foreignTaxRefund.type.toString(),
      year: numberToString(foreignTaxRefund.year),
      quarter: numberToString(foreignTaxRefund.quarter),
      companyId: foreignTaxRefund.companyId.toString(),
      countryId: foreignTaxRefund.countryId.toString(),
    };
  }

  doSubmit = async () => {
    try {
      await (this.foreignTaxRefundId
        ? foreignTaxRefundService.modifyForeignTaxRefund(
            this.foreignTaxRefundId,
            this.state.data
          )
        : foreignTaxRefundService.addForeignTaxRefund(this.state.data));
      toast.success("Visszaigénylés sikeresen mentve.");
      this.props.history.push(
        `${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.countryId}/refunds`
      );
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a visszaigénylést?", async () => {
      try {
        await foreignTaxRefundService.removeForeignTaxRefund(
          this.foreignTaxRefundId
        );
        toast.success("Visszaigénylés sikeresen törölve.");
        this.props.history.replace(
          `${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.state.data.countryId}/refunds`
        );
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);
    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Megnevezés")}
          <div className="row">
            <div className="col-sm-3">
              {this.renderRadio(
                "type",
                "Éves visszaigénylés",
                ForeignTaxRefundType.Yearly
              )}
            </div>
            <div className="col-sm-3">
              {this.renderRadio(
                "type",
                "Negyedéves visszaigénylés",
                ForeignTaxRefundType.Quarterly
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-6">
              {this.renderSelect(
                "year",
                "Év",
                [
                  { id: 2017, name: "2017" },
                  { id: 2018, name: "2018" },
                  { id: 2019, name: "2019" },
                  { id: 2020, name: "2020" },
                  { id: 2021, name: "2021" },
                  { id: 2022, name: "2022" },
                  { id: 2023, name: "2023" },
                ],
                "name"
              )}
            </div>
            {this.state.data.type === ForeignTaxRefundType.Quarterly && (
              <div className="col-sm-6">
                {this.renderSelect(
                  "quarter",
                  "Negyedév",
                  [
                    { id: 1, name: "Első" },
                    { id: 2, name: "Második" },
                    { id: 3, name: "Harmadik" },
                    { id: 4, name: "Negyedik" },
                  ],
                  "name"
                )}
              </div>
            )}
          </div>
          {this.renderSelect(
            "companyId",
            "Cég",
            this.state.companies,
            "nameOfTaxPlayer"
          )}

          {this.renderButton("Mentés")}
          {this.foreignTaxRefundId && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default ForeignTaxRefundForm;
