import React, { Component } from "react";
import Pagination from "../common/pagination";
import foreignTaxRefundService from "../../services/foreignTaxRefundService";
import GroupTable from "../common/groupTable";
import ForeignTaxRefundDto from "../../models/foreignTaxRefundDto";
import QueryResultDto from "../../models/queryResultDto";
import QueryResultItemDto from "../../models/queryResultItemDto";
import TransactionDto from "../../models/transactionDto";
import ReportColumnDto from "../../models/reportColumnDto";
import { RouteComponentProps } from "react-router";
import { ForeignTaxRefundType } from "../../models/foreignTaxRefundType";
import { handleError } from "../../utils/serverError";
import {
  formatNumber,
  FLOAT2,
  YYYY_MM_DD_HH_mm_ss,
  formatDate,
  JSON_DATE,
} from "../../utils/format";
import setDocumentTitle from "../../utils/document";

interface State {
  foreignTaxRefund: ForeignTaxRefundDto;
  transactions: QueryResultDto<QueryResultItemDto<TransactionDto>>;
  columns: ReportColumnDto[];
  page: number;
  pageSize: number;
}

class ForeignTaxRefundView extends Component<
  RouteComponentProps<{ countryId: string; foreignTaxRefundId: string }>,
  State
> {
  get title() {
    return `${this.state.foreignTaxRefund.name} - visszaigénylés`;
  }
  get countryId() {
    return this.props.match.params.countryId;
  }
  get foreignTaxRefundId() {
    return this.props.match.params.foreignTaxRefundId;
  }
  state: State = {
    foreignTaxRefund: {
      id: 0,
      name: "",
      type: ForeignTaxRefundType.Yearly,
      year: 0,
      quarter: 0,
      companyId: 0,
      countryId: 0,
      refundFrom: "",
      refundTo: "",
    },
    transactions: {
      totalItems: 0,
      items: [],
    },
    columns: [],
    page: 1,
    pageSize: 15,
  };

  async componentDidMount() {
    await this.populateForeignTaxRefund();
  }

  async populateForeignTaxRefund() {
    try {
      const {
        data: foreignTaxRefund,
      } = await foreignTaxRefundService.getForeignTaxRefund(
        this.foreignTaxRefundId
      );

      const {
        data: columns,
      } = await foreignTaxRefundService.getForeignTaxRefundViewColumns();

      const {
        data: transactions,
      } = await foreignTaxRefundService.getForeignTaxRefundView(
        this.foreignTaxRefundId,
        this.state.page,
        this.state.pageSize
      );

      this.setState({ transactions, columns, foreignTaxRefund });
    } catch (ex) {
      handleError(this.props, ex);
    }
  }

  getCellValue = (data: TransactionDto, field: keyof TransactionDto) => {
    switch (field) {
      case "supplierNetTotal":
      case "supplierVatPrice":
      case "supplierGrossTotal": {
        return formatNumber(data[field], FLOAT2);
      }
      default: {
        return data[field];
      }
    }
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, async () => this.populateForeignTaxRefund());
  };

  handleExport = async () => {
    await foreignTaxRefundService.exportForeignTaxRefundView(
      this.foreignTaxRefundId
    );
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <React.Fragment>
        <h1>{this.title}</h1>

        <button onClick={this.handleExport} className="btn btn-default my-2">
          Export
        </button>

        <ul className="list-group mb-3">
          <li className="list-group-item">
            <strong>Visszaigénylés kezdete: </strong>
            {formatDate(
              this.state.foreignTaxRefund.refundFrom,
              JSON_DATE,
              YYYY_MM_DD_HH_mm_ss
            )}
          </li>
          <li className="list-group-item">
            <strong>Visszaigénylés vége: </strong>
            {formatDate(
              this.state.foreignTaxRefund.refundTo,
              JSON_DATE,
              YYYY_MM_DD_HH_mm_ss
            )}
          </li>
        </ul>

        <GroupTable
          columns={this.state.columns}
          items={this.state.transactions.items}
          getCellValue={this.getCellValue}
        />
        <Pagination
          itemsCount={this.state.transactions.totalItems}
          pageSize={this.state.pageSize}
          currentPage={this.state.page}
          onPageChange={this.handlePageChange}
        />
      </React.Fragment>
    );
  }
}

export default ForeignTaxRefundView;
