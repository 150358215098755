import httpService from "./httpService";
import DeliveryNoteDto from "../models/deliveryNoteDto";
import SaveDeliveryNoteDto from "../models/saveDeliveryNoteDto";
import DeliveryNoteQueryObjectDto from "../models/deliveryNoteQueryObjectDto";
import TransactionDto from "../models/transactionDto";

const baseUrl = "/api/delivery-notes";

function getDeliveryNote(id: string) {
  return httpService.get<DeliveryNoteDto>(`${baseUrl}/${id}`);
}

function getNextDeliveryNote(vehicleId: string) {
  return httpService.get<DeliveryNoteDto>(`${baseUrl}/next`, {
    params: { vehicleId },
  });
}

function addDeliveryNote(saveDeliveryNoteDto: SaveDeliveryNoteDto) {
  return httpService.post(baseUrl, saveDeliveryNoteDto);
}

function modifyDeliveryNote(
  id: string,
  saveDeliveryNoteDto: SaveDeliveryNoteDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveDeliveryNoteDto);
}

function removeDeliveryNote(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function getTransactions(startDate: string, vehicleId: string) {
  return httpService.get<TransactionDto[]>(`${baseUrl}/transactions`, {
    params: { startDate, vehicleId },
  });
}

function getDeliveryNotes() {
  return httpService.get<DeliveryNoteDto[]>(baseUrl);
}

function exportDeliveryNotes(
  deliveryNoteQueryObjectDto: DeliveryNoteQueryObjectDto
) {
  return httpService.get<BlobPart>(`${baseUrl}/export`, {
    responseType: "blob",
    params: deliveryNoteQueryObjectDto,
  });
}

export default {
  getDeliveryNotes,
  getDeliveryNote,
  getNextDeliveryNote,
  addDeliveryNote,
  modifyDeliveryNote,
  removeDeliveryNote,
  getTransactions,
  exportDeliveryNotes,
};
