import httpService from "./httpService";
import DeliveryNotePeriodDto from "../models/deliveryNotePeriodDto";
import SaveDeliveryNotePeriodDto from "../models/saveDeliveryNotePeriodDto";

const baseUrl = "/api/delivery-note-periods";

function getDeliveryNotePeriods() {
  return httpService.get<DeliveryNotePeriodDto[]>(baseUrl);
}

function getDeliveryNotePeriod(id: string) {
  return httpService.get<DeliveryNotePeriodDto>(`${baseUrl}/${id}`);
}

function addDeliveryNotePeriod(deliveryNotePeriod: SaveDeliveryNotePeriodDto) {
  return httpService.post<void>(baseUrl, deliveryNotePeriod);
}

function modifyDeliveryNotePeriod(
  id: string,
  deliveryNotePeriod: SaveDeliveryNotePeriodDto
) {
  return httpService.put(`${baseUrl}/${id}`, deliveryNotePeriod);
}

function removeDeliveryNotePeriod(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function closeDeliveryNotePeriod(id: string) {
  return httpService.post(`${baseUrl}/${id}/close`);
}

function openDeliveryNotePeriod(id: string) {
  return httpService.post(`${baseUrl}/${id}/open`);
}

function exportDeliveryNotePeriod(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/export`, {
    responseType: "blob"
  });
}

function dailyExportDeliveryNotePeriod(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/daily-export`, {
    responseType: "blob"
  });
}

function energyExport(id: number) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/energy-export`, {
    responseType: "blob"
  });
}

export default {
  getDeliveryNotePeriods,
  getDeliveryNotePeriod,
  addDeliveryNotePeriod,
  modifyDeliveryNotePeriod,
  removeDeliveryNotePeriod,
  closeDeliveryNotePeriod,
  openDeliveryNotePeriod,
  exportDeliveryNotePeriod,
  dailyExportDeliveryNotePeriod,
  energyExport
};
