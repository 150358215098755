import React from "react";
import { toast } from "react-toastify";
import Form from "../common/form";
import MultiSelect from "../common/multiSelect";
import Input from "../common/input";
import deliveryNoteService from "../../services/deliveryNoteService";
import driverService from "../../services/driverService";
import vehicleService from "../../services/vehicleService";
import destinationService from "../../services/destinationService";
import correctiveNormService from "../../services/correctiveNormService";
import deliveryNotePeriodService from "../../services/deliveryNotePeriodService";
import confirm from "../../utils/confirm";
import moment from "moment";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";
import SaveDeliveryNoteDto from "../../models/saveDeliveryNoteDto";
import { RouteComponentProps } from "react-router";
import DestinationDto from "../../models/destinationDto";
import VehicleDto from "../../models/vehicleDto";
import DriverDto from "../../models/driverDto";
import CorrectiveNormDto from "../../models/correctiveNormDto";
import DeliveryNotePeriodDto from "../../models/deliveryNotePeriodDto";
import TransactionDto from "../../models/transactionDto";
import {
  YYYY_MM_DD_HH_mm,
  numberToString,
  formatDate,
  YYYY_MM_DD_HH_mm_ss,
  JSON_DATE,
  YYYY_MM_DD,
} from "../../utils/format";
import DeliveryNoteDto from "../../models/deliveryNoteDto";
import { remove } from "lodash";
import TrailerDto from "../../models/trailerDto";
import trailerService from "../../services/trailerService";

const initialState = {
  data: {
    number: "",
    flightNumber: "1",
    startKm: "",
    endKm: "",
    cityKm: "",
    packedKm: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    callDate: "",
    coolingStartDate: "",
    coolingEndDate: "",
    driverId: "",
    driverAssistantId: "",
    vehicleId: "",
    trailerId: "",
    destinationId: "",
    cityCorrectiveNormId: "",
    deliveryNotePeriodId: "",
    transactions: [],
    layeredWeight: "",
    fullCooling: false,
    halfCooling: false,
  } as SaveDeliveryNoteDto,

  errors: {},

  drivers: [] as DriverDto[],
  vehicles: [] as VehicleDto[],
  destinations: [] as DestinationDto[],
  cityCorrectiveNorms: [] as CorrectiveNormDto[],
  selectableTransactions: [] as TransactionDto[],
  selectedTransactions: [] as TransactionDto[],
  deliveryNotePeriods: [] as DeliveryNotePeriodDto[],
  trailers: [] as TrailerDto[],
};

type State = typeof initialState;

class DeliveryNoteForm extends Form<
  SaveDeliveryNoteDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.number} - menetlevél módosítása`
      : "Új menetlevél";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  readonly state: State = initialState;

  schema = yup.object().shape<SaveDeliveryNoteDto>({
    number: yup.string().required().max(255),
    flightNumber: yup.string().required().max(255),
    startKm: yup.string().required(),
    endKm: yup.string().required(),
    cityKm: yup.string(),
    packedKm: yup.string(),
    startDate: yup.string().required().date(YYYY_MM_DD),
    endDate: yup.string().required().date(YYYY_MM_DD),
    startTime: yup.string().required().date("HH:mm"),
    endTime: yup.string().required().date("HH:mm"),
    callDate: yup.string().date(YYYY_MM_DD_HH_mm),
    coolingStartDate: yup.string().date(YYYY_MM_DD_HH_mm),
    coolingEndDate: yup.string().date(YYYY_MM_DD_HH_mm),
    driverId: yup.string().required(),
    driverAssistantId: yup.string(),
    vehicleId: yup.string().required(),
    trailerId: yup.string(),
    deliveryNotePeriodId: yup.string().required(),
    destinationId: yup.string(),
    cityCorrectiveNormId: yup.string(),
    transactions: yup.array(),
    layeredWeight: yup.string(),
    fullCooling: yup.boolean(),
    halfCooling: yup.boolean(),
  });

  async componentDidMount() {
    await this.populateDrivers();
    await this.populateVehicles();
    await this.populateDestinations();
    await this.populateCityCorrectiveNorms();
    await this.populateDeliveryNotePeriods();
    await this.populateTrailers();

    if (this.id) await this.populateDeliveryNote();
  }

  async populateDrivers() {
    const { data: drivers } = await driverService.getDrivers();
    this.setState({ drivers });
  }

  async populateVehicles() {
    const { data: vehicles } = await vehicleService.getVehicles();
    this.setState({ vehicles });
  }

  async populateDestinations() {
    const { data: destinations } = await destinationService.getDestinations();
    this.setState({ destinations });
  }

  async populateCityCorrectiveNorms() {
    const {
      data: cityCorrectiveNorms,
    } = await correctiveNormService.getCityCorrectiveNorms();
    this.setState({ cityCorrectiveNorms });
  }

  async populateDeliveryNotePeriods() {
    const {
      data: deliveryNotePeriods,
    } = await deliveryNotePeriodService.getDeliveryNotePeriods();
    this.setState({ deliveryNotePeriods });
  }

  async populateTrailers() {
    const { data: trailers } = await trailerService.getTrailers();
    this.setState({ trailers });
  }

  async populateDeliveryNote() {
    try {
      const { data } = await deliveryNoteService.getDeliveryNote(this.id);

      const deliveryNote = this.mapToViewModel(data);

      this.setState({ data: deliveryNote }, async () => {
        await this.populateTransactions();
      });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(deliveryNote: DeliveryNoteDto): SaveDeliveryNoteDto {
    return {
      number: deliveryNote.number,
      flightNumber: deliveryNote.flightNumber,
      startKm: numberToString(deliveryNote.startKm),
      endKm: numberToString(deliveryNote.endKm),
      cityKm: numberToString(deliveryNote.cityKm),
      packedKm: numberToString(deliveryNote.packedKm),
      startDate: formatDate(deliveryNote.startDate, JSON_DATE, YYYY_MM_DD),
      endDate: formatDate(deliveryNote.endDate, JSON_DATE, YYYY_MM_DD),
      startTime: formatDate(deliveryNote.startDate, JSON_DATE, "HH:mm"),
      endTime: formatDate(deliveryNote.endDate, JSON_DATE, "HH:mm"),
      callDate: formatDate(deliveryNote.callDate, JSON_DATE, YYYY_MM_DD_HH_mm),
      coolingStartDate: formatDate(
        deliveryNote.coolingStartDate,
        JSON_DATE,
        YYYY_MM_DD_HH_mm
      ),
      coolingEndDate: formatDate(
        deliveryNote.coolingEndDate,
        JSON_DATE,
        YYYY_MM_DD_HH_mm
      ),
      driverId: numberToString(deliveryNote.driverId),
      driverAssistantId: numberToString(deliveryNote.driverAssistantId),
      vehicleId: numberToString(deliveryNote.vehicleId),
      trailerId: numberToString(deliveryNote.trailerId),
      deliveryNotePeriodId: numberToString(deliveryNote.deliveryNotePeriodId),
      destinationId: numberToString(deliveryNote.destinationId),
      cityCorrectiveNormId: numberToString(deliveryNote.cityCorrectiveNormId),
      transactions: deliveryNote.transactions,
      layeredWeight: numberToString(deliveryNote.layeredWeight),
      fullCooling: deliveryNote.fullCooling,
      halfCooling: deliveryNote.halfCooling,
    };
  }

  async componentDidUpdate(_prevProps: any, prevState: State) {
    const isStartDateChanged =
      prevState.data.startDate !== this.state.data.startDate;
    const isVehicleChanged =
      prevState.data.vehicleId !== this.state.data.vehicleId;

    const isVehicleValid =
      this.validateProperty("vehicleId", this.state.data.vehicleId) === null;
    const isStartDateValid =
      this.validateProperty("startDate", this.state.data.startDate) === null;

    const isPopulateTransactionsCallable =
      (isVehicleChanged || isStartDateChanged) &&
      isVehicleValid &&
      isStartDateValid;

    if (isPopulateTransactionsCallable) await this.populateTransactions();
  }

  handleVehicleChanged = async () => {
    const { vehicleId } = this.state.data;

    if (vehicleId) {
      await this.populateNextDeliveryNote();
    }
  };

  populateNextDeliveryNote = async () => {
    const data = { ...this.state.data };

    try {
      const {
        data: {
          number,
          driverId,
          startDate,
          endDate,
          startKm,
          callDate,
          coolingStartDate,
          coolingEndDate,
        },
      } = await deliveryNoteService.getNextDeliveryNote(data.vehicleId);

      data.number = number;
      data.driverId = numberToString(driverId);
      data.startDate = formatDate(startDate, JSON_DATE, YYYY_MM_DD);
      data.endDate = formatDate(endDate, JSON_DATE, YYYY_MM_DD);
      data.startTime = formatDate(startDate, JSON_DATE, "HH:mm");
      data.endTime = formatDate(endDate, JSON_DATE, "HH:mm");
      data.startKm = numberToString(startKm);
      data.callDate = formatDate(callDate, JSON_DATE, YYYY_MM_DD_HH_mm);
      data.coolingStartDate = formatDate(
        coolingStartDate,
        JSON_DATE,
        YYYY_MM_DD_HH_mm
      );
      data.coolingEndDate = formatDate(
        coolingEndDate,
        JSON_DATE,
        YYYY_MM_DD_HH_mm
      );

      this.setState({ data });
    } catch (ex) {
      console.warn(ex);
    }
  };

  populateTransactions = async () => {
    const data = { ...this.state.data };
    const transactions = [...this.state.data.transactions];
    data.transactions = transactions;

    const {
      data: selectableTransactions,
    } = await deliveryNoteService.getTransactions(
      formatDate(data.startDate, YYYY_MM_DD, JSON_DATE),
      data.vehicleId
    );

    transactions.forEach(({ transactionId }) => {
      remove(selectableTransactions, (t) => t.id === transactionId);
    });

    this.setState({ selectableTransactions, data });
  };

  doSubmit = async () => {
    const data = {
      ...this.state.data,
    };
    data.startDate = `${data.startDate}T${data.startTime}:00`;
    data.endDate = `${data.endDate}T${data.endTime}:00`;

    const startDateTime = moment(
      this.state.data.startDate + this.state.data.startTime,
      "YYYY.MM.DDHH:mm"
    );
    const endDateTime = moment(
      this.state.data.endDate + this.state.data.endTime,
      "YYYY.MM.DDHH:mm"
    );

    const duration = moment.duration(endDateTime.diff(startDateTime)).asHours();

    const maxKm = duration * 65;
    const actualKm =
      parseInt(this.state.data.endKm) - parseInt(this.state.data.startKm);

    if (actualKm >= maxKm) {
      confirm("A menetlevélen szereplő km érték valós?", async () => {
        try {
          if (this.id)
            await deliveryNoteService.modifyDeliveryNote(this.id, data);
          else await deliveryNoteService.addDeliveryNote(data);
          toast.success("Menetlevél sikeresen mentve.");
          this.props.history.push(ROUTE.DELIVERY_NOTES);
        } catch (ex) {
          this.handleError(ex);
        }
      });
    } else {
      try {
        if (this.id)
          await deliveryNoteService.modifyDeliveryNote(this.id, data);
        else await deliveryNoteService.addDeliveryNote(data);
        toast.success("Menetlevél sikeresen mentve.");
        this.props.history.push(ROUTE.DELIVERY_NOTES);
      } catch (ex) {
        this.handleError(ex);
      }
    }
  };

  handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a menetlevelet?", async () => {
      try {
        await deliveryNoteService.removeDeliveryNote(this.id);
        toast.success("Menetlevél sikeresen törölve.");
        this.props.history.replace(ROUTE.DELIVERY_NOTES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  handleTransactionsSelected = (selectedTransactions: TransactionDto[]) => {
    this.setState({ selectedTransactions });
  };

  handleAddTransactions = (e: React.MouseEvent) => {
    e.preventDefault();

    const { selectedTransactions } = this.state;
    const selectableTransactions = [...this.state.selectableTransactions];
    const data = { ...this.state.data };
    const transactions = [...this.state.data.transactions];
    data.transactions = transactions;

    selectedTransactions.forEach((selectedTransaction) => {
      transactions.push({
        transactionId: selectedTransaction.id,
        transaction: selectedTransaction,
        quantity: numberToString(selectedTransaction.quantity),
      });
      remove(selectableTransactions, (t) => t.id === selectedTransaction.id);
    });

    this.setState({
      data,
      selectedTransactions: [],
      selectableTransactions,
    });
  };

  handleTransactionRemove = (e: React.MouseEvent, transactionId: number) => {
    e.preventDefault();

    const selectableTransactions = [...this.state.selectableTransactions];
    const data = { ...this.state.data };
    const transactions = [...this.state.data.transactions];
    data.transactions = transactions;

    const removedTransactions = remove(
      transactions,
      (t) => t.transactionId === transactionId
    );

    selectableTransactions.push(removedTransactions[0].transaction);

    this.setState({
      data,
      selectableTransactions,
    });
  };

  handleQuantityChange = (
    { currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>,
    transactionId: number
  ) => {
    const data = { ...this.state.data };
    data.transactions = [...data.transactions];
    const transactionIndex = data.transactions.findIndex(
      (t) => t.transactionId === transactionId
    );
    const transaction = { ...data.transactions[transactionIndex] };
    data.transactions[transactionIndex] = transaction;

    transaction.quantity = value;

    this.setState({ data });
  };

  calculateTime = () => {
    if (this.validateProperty("startDate", this.state.data.startDate) !== null)
      return;
    if (this.validateProperty("startTime", this.state.data.startTime) !== null)
      return;
    if (this.validateProperty("endDate", this.state.data.endDate) !== null)
      return;
    if (this.validateProperty("endTime", this.state.data.endTime) !== null)
      return;

    const startDateTime = moment(
      this.state.data.startDate + this.state.data.startTime,
      "YYYY.MM.DDHH:mm"
    );
    const endDateTime = moment(
      this.state.data.endDate + this.state.data.endTime,
      "YYYY.MM.DDHH:mm"
    );

    const duration = moment.duration(endDateTime.diff(startDateTime));

    return (
      (duration.asMinutes() / 60).toString().split(".")[0] +
      " óra " +
      (duration.asMinutes() % 60) +
      " perc"
    );
  };

  calculateKm = () => {
    if (this.validateProperty("startKm", this.state.data.startKm) !== null)
      return;
    if (this.validateProperty("endKm", this.state.data.endKm) !== null) return;

    const km =
      parseInt(this.state.data.endKm) - parseInt(this.state.data.startKm);

    return km + " km";
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#torzs"
                role="tab"
              >
                Törzs
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#tranzakciok"
                role="tab"
              >
                Tranzakciók
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="torzs"
              role="tabpanel"
            >
              <br />
              <div className="row">
                <div className="col">
                  {this.renderInput(
                    "number",
                    <Required label="Menetlevél szám" />
                  )}
                </div>
                <div className="col">
                  {this.renderInput(
                    "flightNumber",
                    <Required label="Járat szám" />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderSelect(
                    "deliveryNotePeriodId",
                    <Required label="Időszak" />,
                    this.state.deliveryNotePeriods,
                    "name",
                    () => this.populateDeliveryNotePeriods()
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "vehicleId",
                    <Required label="Jármű" />,
                    this.state.vehicles,
                    "licensePlate",
                    () => this.populateVehicles(),
                    ["licensePlate"],
                    this.handleVehicleChanged
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "trailerId",
                    "Pótkocsi",
                    this.state.trailers,
                    "licensePlate",
                    () => this.populateTrailers(),
                    ["licensePlate"]
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "driverId",
                    <Required label="Sofőr" />,
                    this.state.drivers,
                    "name",
                    () => this.populateDrivers()
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "driverAssistantId",
                    "Kisérő",
                    this.state.drivers,
                    "name",
                    () => this.populateDrivers()
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput(
                    "startDate",
                    <Required label="Fuvar kezdete" />
                  )}
                </div>
                <div className="col">
                  {this.renderMaskedInput("startTime", "Idő", "99:99")}
                </div>
                <div className="col">
                  {this.renderInput("endDate", <Required label="Fuvar vége" />)}
                </div>
                <div className="col">
                  {this.renderMaskedInput("endTime", "Idő", "99:99")}
                </div>
                <div className="col">
                  <br />
                  <br />
                  Menetidő: {this.calculateTime()}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput("coolingStartDate", "Hűtés kezdete")}
                </div>
                <div className="col">
                  {this.renderInput("coolingEndDate", "Hűtés vége")}
                </div>
                <div className="col">
                  <br />
                  {this.renderCheckBox("fullCooling", "Teljes hűtés")}
                  {this.renderCheckBox("halfCooling", "Fél hűtés")}
                </div>
                <div className="col">
                  {this.renderInput("callDate", "Behívás ideje")}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput(
                    "startKm",
                    <Required label="Kezdő km" />,
                    "number"
                  )}
                </div>
                <div className="col">
                  {this.renderInput(
                    "endKm",
                    <Required label="Záró km" />,
                    "number"
                  )}
                </div>
                <div className="col">
                  <br />
                  <br />
                  Megtett km: {this.calculateKm()}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderInput("cityKm", "Városi km", "number")}
                </div>
                <div className="col">
                  {this.renderInput("packedKm", "Rakott km", "number")}
                </div>
                <div className="col">
                  {this.renderInput("layeredWeight", "Rakott súly", "number")}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {this.renderSelect(
                    "destinationId",
                    "Viszonylat",
                    this.state.destinations,
                    "name",
                    () => this.populateDestinations()
                  )}
                </div>
                <div className="col">
                  {this.renderSelect(
                    "cityCorrectiveNormId",
                    "Városi korrekciós norma",
                    this.state.cityCorrectiveNorms,
                    "name"
                  )}
                </div>
              </div>
              <div className="row"></div>
            </div>
            <div
              className="tab-pane fade show"
              id="tranzakciok"
              role="tabpanel"
            >
              <br />
              <div className="row">
                <div className="col-sm-9">
                  <MultiSelect
                    labelKey={(transaction) =>
                      `${formatDate(
                        transaction.date,
                        JSON_DATE,
                        YYYY_MM_DD_HH_mm_ss
                      )} - ${transaction.transactionType} - ${
                        transaction.product
                      }`
                    }
                    filterBy={["date", "transactionType", "product"]}
                    name="transactions"
                    label="Választható tranzakciók"
                    error=""
                    options={this.state.selectableTransactions}
                    selected={this.state.selectedTransactions}
                    onChange={this.handleTransactionsSelected}
                  />
                </div>
                <div className="col-sm-3">
                  <button
                    style={{ width: "100%", marginTop: 32 }}
                    onClick={(e) => this.handleAddTransactions(e)}
                    className="btn btn-primary"
                  >
                    Hozzáadás
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th className="align-middle">Időpont</th>
                      <th className="align-middle">Típus</th>
                      <th className="align-middle">Termék</th>
                      <th className="align-middle">Mennyiség</th>
                      <th className="align-middle">Felhasznált mennyiség</th>
                      <th className="align-middle">Műveletek</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.transactions.map(
                      ({ transaction, transactionId, quantity }) => (
                        <tr key={transactionId}>
                          <td className="align-middle">
                            {moment(transaction.date.toString()).format(
                              "YYYY.MM.DD HH:mm:SS"
                            )}
                          </td>
                          <td className="align-middle">
                            {transaction.transactionType}
                          </td>
                          <td className="align-middle">
                            {transaction.product}
                          </td>
                          <td className="align-middle">
                            {transaction.quantity}
                          </td>
                          <td className="align-middle">
                            <Input
                              type="number"
                              name="quantity"
                              label=""
                              value={quantity.toString()}
                              onChange={(e) => {
                                this.handleQuantityChange(e, transactionId);
                              }}
                              error=""
                            />
                          </td>
                          <td className="align-middle">
                            <button
                              onClick={(e) =>
                                this.handleTransactionRemove(e, transactionId)
                              }
                              className="btn btn-danger"
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default DeliveryNoteForm;
