import { Predicate } from "@syncfusion/ej2-react-grids";
import { JsonAdaptor } from "@syncfusion/ej2-data";
import _ from "lodash";
import moment from "moment";

export class CostumJsonAdaptor extends JsonAdaptor {
  onWhere(ds: any[], e: any): any[] {
    if (_.get(ds, "GroupGuid", undefined)) return ds;

    let filtered = ds;
    (e.predicates as Predicate[]).forEach((predicate: Predicate) => {
      const field = predicate.field;
      let value = predicate.value as string;
      if (value === "-" || value === null) {
        filtered = _.filter(filtered, (o: any) => {
          return _.get(o, field, null) === null;
        });
      } else {
        filtered = _.filter(filtered, (o: any) => {
          const fieldValue = _.get(o, field, null);
          if (fieldValue === null) return false;
          if (typeof fieldValue === "number") {
            value = value.toString().replace(",", ".");
            return fieldValue.toString() === value;
          } else if (value.length === 10 && moment(fieldValue).isValid()) {
            return moment(fieldValue).date() === moment(value).date();
          } else if (typeof fieldValue === "boolean") {
            return (fieldValue ? "Igen" : "Nem")
              .toLowerCase()
              .startsWith(value.toLowerCase());
          } else {
            return fieldValue.toLowerCase().startsWith(value.toLowerCase());
          }
        });
      }
    });
    return filtered;
  }
}
