import React, { useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import commercialGasRefundService from "../../services/commercialGasRefundService";
import CommercialGasRefundDto from "../../models/commercialGasRefundDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import ViewButton from "../common/viewButton";
import ImpExportButton from "../common/impExportButton";
import { saveFile } from "../../utils/file";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
];

const CommercialGasRefunds = ({ history }: RouteComponentProps) => {
  const [refunds, setRefunds] = useState<CommercialGasRefundDto[]>([]);
  const [
    selectedRefund,
    setSelectedRefund,
  ] = useState<CommercialGasRefundDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    setDocumentTitle(MENU.COMMERCIAL_GAS_REFUNDS);

    const populateUsers = async () => {
      const {
        data,
      } = await commercialGasRefundService.getCommercialGasRefunds();
      setRefunds(data);
    };

    populateUsers();
  }, []);

  const handleModify = () => {
    if (!selectedRefund) return;
    history.push(`${ROUTE.COMMERCIAL_GAS_REFUNDS}/${selectedRefund.id}`);
  };

  const handleView = () => {
    if (!selectedRefund) return;
    history.push(`${ROUTE.COMMERCIAL_GAS_REFUNDS}/${selectedRefund.id}/view`);
  };

  const handleExcelExport = () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "visszaigenylesek.xlsx" });
  };

  const handleImpExport = async () => {
    if (!selectedRefund) return;

    const file = await commercialGasRefundService.exportCommercialGasRefund(
      selectedRefund.id.toString()
    );

    saveFile(file);
  };

  return (
    <>
      <h1>{MENU.COMMERCIAL_GAS_REFUNDS}</h1>
      <NewLink
        to={`${ROUTE.COMMERCIAL_GAS_REFUNDS}/new`}
        label="Új visszaigénylés"
      />
      <ModifyButton
        disabled={!selectedRefund}
        onClick={handleModify}
        label="Visszaigénylés módosítása"
      />
      <ViewButton
        disabled={!selectedRefund}
        onClick={handleView}
        label="Visszaigénylés megtekintése"
      />
      <ExcelExportButton onClick={handleExcelExport} />
      <ImpExportButton onClick={handleImpExport} />
      <DataGrid
        id="commercial_gas_refunds"
        columns={columns}
        data={refunds}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedUser) => {
          setSelectedRefund(selectedUser);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default CommercialGasRefunds;
