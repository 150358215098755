export const CREDIT_CARD = "fa fa-credit-card";
export const HOME = "fa fa-home";
export const ROAD = "fa fa-road";
export const STICKY_NOTE = "fa fa-sticky-note";
export const MAGIC = "fa fa-magic";
export const USD = "fa fa-usd";
export const DATABASE = "fa fa-database";
export const TRUCK = "fa fa-truck";
export const USER = "fa fa-user";
export const LOCK = "fa fa-lock";
export const BUILDING = "fa fa-building";
export const USERS = "fa fa-users";
export const SIGN_IN = "fa fa-sign-in";
export const TEXT_FILE = "fa fa-file-text";
export const DOWNLOAD = "fa fa-download";
export const TRASH = "fa fa-trash";
export const REFRESH = "fa fa-refresh";
export const GLOBE = "fa fa-globe";
export const UNIVERSITY = "fa fa-university";
