import React from 'react';
import { toast } from 'react-toastify';
import confirm from '../../utils/confirm';
import Form, { FormState } from '../common/form';
import commercialGasRefundService from '../../services/commercialGasRefundService';
import companyService from '../../services/companyService';
import SaveCommercialGasRefundDto from '../../models/saveCommercialGasRefundDto';
import CompanyDto from '../../models/companyDto';
import { RouteComponentProps } from 'react-router';
import { CommercialGasRefundType } from '../../models/commercialGasRefundType';
import * as yup from 'yup';
import {
  YYYY_MM_DD,
  numberToString,
  formatDate,
  JSON_DATE,
} from '../../utils/format';
import CommercialGasRefundDto from '../../models/commercialGasRefundDto';
import * as ROUTE from '../../constants/route';
import setDocumentTitle from '../../utils/document';

interface State extends FormState<SaveCommercialGasRefundDto> {
  data: SaveCommercialGasRefundDto;
  companies: CompanyDto[];
}

class CommercialGasRefundForm extends Form<
  SaveCommercialGasRefundDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} kereskedelmi gázolaj visszaigénylés módosítása`
      : 'Új kereskedelmi gázolaj visszaigénylés';
  }
  get id() {
    return this.props.match.params.id === 'new'
      ? ''
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: '',
      type: CommercialGasRefundType.Yearly,
      year: '',
      quarter: '',
      month: '',
      dateTimeFrom: '',
      dateTimeTo: '',
      companyId: '',
    },
    errors: {},
    companies: [],
  };

  schema = yup.object().shape<SaveCommercialGasRefundDto>({
    name: yup.string().required().max(255),
    type: yup.string().required(),
    year: yup.string(),
    quarter: yup.string(),
    month: yup.string(),
    dateTimeFrom: yup.string().date(YYYY_MM_DD),
    dateTimeTo: yup.string().date(YYYY_MM_DD),
    companyId: yup.string().required(),
  });
  async componentDidMount() {
    await this.populateCompanies();
    if (this.id) await this.populateCommercialGasRefund();
  }
  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  async populateCommercialGasRefund() {
    try {
      const { data } = await commercialGasRefundService.getCommercialGasRefund(
        this.id
      );
      const commercialGasRefund = this.mapToViewModel(data);
      this.setState({ data: commercialGasRefund });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(
    commercialGasRefund: CommercialGasRefundDto
  ): SaveCommercialGasRefundDto {
    return {
      name: commercialGasRefund.name,
      type: commercialGasRefund.type.toString(),
      year: numberToString(commercialGasRefund.year),
      quarter: numberToString(commercialGasRefund.quarter),
      month: numberToString(commercialGasRefund.month),
      dateTimeFrom: formatDate(
        commercialGasRefund.dateTimeFrom,
        JSON_DATE,
        YYYY_MM_DD
      ),
      dateTimeTo: formatDate(
        commercialGasRefund.dateTimeTo,
        JSON_DATE,
        YYYY_MM_DD
      ),
      companyId: commercialGasRefund.companyId.toString(),
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? commercialGasRefundService.modifyCommercialGasRefund(
            this.id,
            this.state.data
          )
        : commercialGasRefundService.addCommercialGasRefund(this.state.data));
      toast.success('Kereskedelmi gázolaj visszaigénylés sikeresen mentve.');
      this.props.history.push(ROUTE.COMMERCIAL_GAS_REFUNDS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm(
      'Biztos törölni szeretné a kereskedelmi gázolaj visszaigénylést?',
      async () => {
        try {
          await commercialGasRefundService.removeCommercialGasRefund(this.id);
          toast.success(
            'Kereskedelmi gázolaj visszaigénylés sikeresen törölve.'
          );
          this.props.history.replace(ROUTE.COMMERCIAL_GAS_REFUNDS);
        } catch (ex) {
          this.handleError(ex);
        }
      }
    );
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Megnevezés')}
          <div className="row">
            <div className="col-sm-3">
              {this.renderRadio('type', 'Éves visszaigénylés', '1')}
            </div>
            <div className="col-sm-3">
              {this.renderRadio('type', 'Negyedéves visszaigénylés', '2')}
            </div>
            <div className="col-sm-3">
              {this.renderRadio('type', 'Havi visszaigénylés', '3')}
            </div>
            <div className="col-sm-3">
              {this.renderRadio('type', 'Időszakos visszaigénylés', '4')}
            </div>
          </div>
          <br />
          <div className="row">
            {(this.state.data.type === '1' ||
              this.state.data.type === '2' ||
              this.state.data.type === '3') && (
              <div className="col-sm-6">
                {this.renderSelect(
                  'year',
                  'Év',
                  [
                    { id: 2017, name: '2017' },
                    { id: 2018, name: '2018' },
                    { id: 2019, name: '2019' },
                    { id: 2020, name: '2020' },
                    { id: 2021, name: '2021' },
                    { id: 2022, name: '2022' },
                    { id: 2023, name: '2023' },
                    { id: 2024, name: '2024' },
                  ],
                  'name'
                )}
              </div>
            )}
            {this.state.data.type === '2' && (
              <div className="col-sm-6">
                {this.renderSelect(
                  'quarter',
                  'Negyedév',
                  [
                    { id: 1, name: 'Első' },
                    { id: 2, name: 'Második' },
                    { id: 3, name: 'Harmadik' },
                    { id: 4, name: 'Negyedik' },
                  ],
                  'name'
                )}
              </div>
            )}
            {this.state.data.type === '3' && (
              <div className="col-sm-6">
                {this.renderSelect(
                  'month',
                  'Hónap',
                  [
                    { id: 1, name: 'Január' },
                    { id: 2, name: 'Február' },
                    { id: 3, name: 'Március' },
                    { id: 4, name: 'Április' },
                    { id: 5, name: 'Május' },
                    { id: 6, name: 'Június' },
                    { id: 7, name: 'Július' },
                    { id: 8, name: 'Augusztus' },
                    { id: 9, name: 'Szeptember' },
                    { id: 10, name: 'Október' },
                    { id: 11, name: 'November' },
                    { id: 12, name: 'December' },
                  ],
                  'name'
                )}
              </div>
            )}
            {this.state.data.type === '4' && (
              <React.Fragment>
                <div className="col-sm-6">
                  {this.renderInput('dateTimeFrom', 'Időszak kezdete')}
                </div>
                <div className="col-sm-6">
                  {this.renderInput('dateTimeTo', 'Időszak vége')}
                </div>
              </React.Fragment>
            )}
          </div>
          {this.renderSelect(
            'companyId',
            'Cég',
            this.state.companies,
            'nameOfTaxPlayer'
          )}

          {this.renderButton('Mentés')}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </>
    );
  }
}

export default CommercialGasRefundForm;
