import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import useAuth from "../../hooks/useAuth";

const ReportMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (!user.hasModule(MODULE.REPORT)) return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.REPORT} icon={ICON.MAGIC} />
      <div className="dropdown-menu">
        <MenuLink label={MENU.REPORTS} url={ROUTE.REPORTS} />
        <MenuLink
          label={MENU.VEHICLE_CATEGORIES}
          url={ROUTE.VEHICLE_CATEGORIES}
        />
        <MenuLink
          label={MENU.PRODUCT_CATEGORIES}
          url={ROUTE.PRODUCT_CATEGORIES}
        />
      </div>
    </div>
  );
};

export default ReportMenu;
