import httpService from "./httpService";
import ProductDto from "../models/productDto";
import SaveProductDto from "../models/saveProductDto";

const baseUrl = "/api/products";

function getProducts() {
  return httpService.get<ProductDto[]>(baseUrl);
}

function getProduct(id: string) {
  return httpService.get<ProductDto>(`${baseUrl}/${id}`);
}

function getManualProducts() {
  return httpService.get<ProductDto[]>(`${baseUrl}/manual`);
}

function addProduct(saveProductDto: SaveProductDto) {
  return httpService.post(baseUrl, saveProductDto);
}

function modifyProduct(id: string, saveProductDto: SaveProductDto) {
  return httpService.put(`${baseUrl}/${id}`, saveProductDto);
}

function modifyProductCommercialGasRefund(
  id: number,
  commercialGasRefund: boolean
) {
  return httpService.put(`${baseUrl}/${id}/commercial-gas-refund`, null, {
    params: { commercialGasRefund },
  });
}

function modifyProductDeliveryNote(id: number, deliveryNote: boolean) {
  return httpService.put(`${baseUrl}/${id}/delivery-note`, null, {
    params: { deliveryNote },
  });
}

function modifyProductEnergyDielsel(id: number, energyDiesel: boolean) {
  return httpService.put(`${baseUrl}/${id}/energy-diesel`, null, {
    params: { energyDiesel },
  });
}

function modifyProductEnergyPetrol(id: number, energyPetrol: boolean) {
  return httpService.put(`${baseUrl}/${id}/energy-petrol`, null, {
    params: { energyPetrol },
  });
}

function removeProduct(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function getForeignTaxProductCategoryProducts(
  countryId: string,
  foreignTaxProductCategoryId: string
) {
  return httpService.get<ProductDto[]>(
    "/api/foreign-tax-product-category-products",
    {
      params: { countryId, foreignTaxProductCategoryId },
    }
  );
}

function modifyForeignTaxProductCategoryProducts(
  countryId: string,
  foreignTaxProductCategoryId: string,
  productIds: number[]
) {
  return httpService.put(
    "/api/foreign-tax-product-category-products",
    productIds,
    {
      params: { countryId, foreignTaxProductCategoryId },
    }
  );
}

function getSelectableForeignTaxProductCategoryProducts(
  countryId: string,
  foreignTaxProductCategoryId: string
) {
  return httpService.get<ProductDto[]>(
    "/api/selectable-foreign-tax-product-category-products",
    {
      params: { countryId, foreignTaxProductCategoryId },
    }
  );
}

export default {
  getProducts,
  getProduct,
  addProduct,
  modifyProduct,
  removeProduct,
  getForeignTaxProductCategoryProducts,
  getSelectableForeignTaxProductCategoryProducts,
  modifyForeignTaxProductCategoryProducts,
  modifyProductCommercialGasRefund,
  modifyProductDeliveryNote,
  modifyProductEnergyDielsel,
  modifyProductEnergyPetrol,
  getManualProducts,
};
