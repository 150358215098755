import React, { Component } from "react";
import Input, { InputType } from "./input";
import Radio from "./radio";
import CheckBox from "./checkBox";
import FileInput from "./fileInput";
import Select from "./select";
import MultiSelect from "./multiSelect";
import Option from "../../models/option";
import { TypeaheadLabelKey } from "react-bootstrap-typeahead";
import * as yup from "yup";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { addMethod } from "yup";
import { isEmpty } from "lodash";
import { AxiosError } from "axios";
import { handleError } from "../../utils/serverError";
import { Prompt } from "react-router-dom";
import MaskedInput from "./maskedInput";

yup.setLocale({
  mixed: {
    required: "Kötelező kitölteni.",
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: "Minimum ${min} karakter hosszúnak kell lennie.",
    // eslint-disable-next-line no-template-curly-in-string
    max: "Maximum ${max} karakter hosszúnak kell lennie.",
  },
});

function date(this: yup.StringSchema, format: string) {
  return this.test("date", "", function (value) {
    const { path, createError } = this;
    const parsed = moment(value, format, true);

    return (
      value === "" ||
      parsed.isValid() ||
      createError({
        path,
        message: `Érvényes dátumot kell megadni a következő formátumban ${format}`,
      })
    );
  });
}
addMethod(yup.string, "date", date);

type Errors<T> = {
  [key in keyof T]?: string;
};

type Data<T> = {
  [key in keyof T]?: any;
};

export interface FormState<T> {
  data: Data<T>;
  errors: Errors<T>;
}

abstract class Form<
  T extends object,
  Props extends RouteComponentProps,
  State extends FormState<T>
> extends Component<Props, State> {
  abstract schema: yup.ObjectSchema<T>;
  abstract doSubmit(): void;

  isDirty = false;

  validate = () => {
    try {
      this.schema.validateSync(this.state.data);
      return null;
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: Errors<T> = {};
        for (let item of error.inner) {
          const key = item.path as keyof T;
          errors[key] = item.message;
        }

        return errors;
      }
      throw Error(error);
    }
  };

  validateProperty = (key: keyof T, value: any) => {
    try {
      this.schema.validateSyncAt(key.toString(), { [key]: value } as T);
      return null;
    } catch (error) {
      if (error instanceof yup.ValidationError) return error.message;
    }
  };

  validateFileProperty = ({ name, files }: HTMLInputElement) => {
    let tempFiles: File[] = [];
    for (let i = 0; i < (files as FileList).length; i++) {
      tempFiles.push((files as FileList)[i]);
    }

    const key = name as keyof T;

    return this.validateProperty(key, tempFiles);
  };

  validateSelectProperty = (selected: any[], name: keyof T) => {
    const selection = selected.length > 0 ? selected[0].id.toString() : "";
    return this.validateProperty(name, selection);
  };

  validateMultiSelectProperty = (selected: any[], name: keyof T) => {
    return this.validateProperty(name, selected);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = this.validate();

    console.log(errors);

    this.setState({ errors: errors || {} });

    if (errors) return;

    this.isDirty = false;

    this.doSubmit();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const key = e.currentTarget.name as keyof T;

    const data: Data<T> = { ...this.state.data };
    const errors: Errors<T> = { ...this.state.errors };

    const errorMessage = this.validateProperty(key, value);
    if (errorMessage) errors[key] = errorMessage;
    else delete errors[key];

    data[key] = value;

    this.isDirty = true;

    this.setState({ data, errors });
  };

  handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.currentTarget.name as keyof T;

    const data: Data<T> = { ...this.state.data };
    const errors: Errors<T> = { ...this.state.errors };

    const errorMessage = this.validateProperty(key, !data[key]);
    if (errorMessage) errors[key] = errorMessage;
    else delete errors[key];

    data[key] = !data[key];

    this.isDirty = true;

    this.setState({ data, errors });
  };

  handleSelectChange = (
    selected: Option[],
    key: keyof T,
    callback?: () => void
  ) => {
    const data: Data<T> = { ...this.state.data };
    const errors: Errors<T> = { ...this.state.errors };

    const errorMessage = this.validateSelectProperty(selected, key);
    if (errorMessage) errors[key] = errorMessage;
    else delete errors[key];

    data[key] = selected.length > 0 ? selected[0].id.toString() : "";

    this.isDirty = true;

    this.setState({ data, errors }, callback);
  };

  handleMultiSelectChange = (selected: Option[], key: keyof T) => {
    const data: Data<T> = { ...this.state.data };
    const errors: Errors<T> = { ...this.state.errors };

    const errorMessage = this.validateMultiSelectProperty(selected, key);
    if (errorMessage) errors[key] = errorMessage;
    else delete errors[key];

    data[key] = selected.map((s) => s.id);

    this.isDirty = true;

    this.setState({ data, errors });
  };

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    const data: Data<T> = { ...this.state.data };
    const errors: Errors<T> = { ...this.state.errors };

    const key = input.name as keyof T;

    const errorMessage = this.validateFileProperty(input);
    if (errorMessage) errors[key] = errorMessage;
    else delete errors[key];

    const files: File[] = [];
    for (let i = 0; i < (input.files as FileList).length; i++) {
      files.push((input.files as FileList)[i]);
    }

    data[key] = files;

    this.isDirty = true;

    this.setState({ data, errors });
  };

  handleError = (ex: AxiosError) => {
    handleError(this.props, ex);
  };

  renderButton(label: string) {
    return (
      <>
        <Prompt
          when={this.isDirty}
          message="Biztos elhagyod mentés nélkül az oldalt?"
        />
        <button disabled={this.validate() !== null} className="btn btn-primary">
          {label}
        </button>
      </>
    );
  }

  renderInput(
    name: keyof T,
    label: string | JSX.Element,
    type: InputType = "text",
    disabled: boolean = false
  ) {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name.toString()}
        label={label}
        value={data[name] as string}
        onChange={this.handleChange}
        error={errors[name] as string}
        disabled={disabled}
      />
    );
  }

  renderMaskedInput(name: keyof T, label: string | JSX.Element, mask: string) {
    const { data, errors } = this.state;

    return (
      <MaskedInput
        mask={mask}
        name={name.toString()}
        label={label}
        value={data[name] as string}
        onChange={this.handleChange}
        error={errors[name] as string}
      />
    );
  }

  renderRadio(name: keyof T, label: string, value: string) {
    const { data } = this.state;

    return (
      <Radio
        name={name as string}
        group={name as string}
        value={value}
        label={label}
        checked={data[name] === value}
        onChange={this.handleChange}
      />
    );
  }

  renderCheckBox(name: keyof T, label: string, disabled: boolean = false) {
    const { data } = this.state;

    return (
      <CheckBox
        name={name as string}
        label={label}
        checked={data[name] as boolean}
        onChange={this.handleCheckBoxChange}
        disabled={disabled}
      />
    );
  }

  renderFileInput(name: keyof T, label: string) {
    return (
      <FileInput
        name={name as string}
        label={label}
        onChange={this.handleFileChange}
        multiple={true}
      />
    );
  }

  renderSelect<O extends Option>(
    name: keyof T,
    label: string | JSX.Element,
    options: O[],
    labelKey: TypeaheadLabelKey<O>,
    onRefresh?: any,
    filterBy?: string[],
    onChange?: () => void,
    disabled: boolean = false
  ) {
    const { data, errors } = this.state;

    const selected: O[] = [];
    if (data[name] !== "") {
      const option = options.find((o) => o.id.toString() === data[name]);
      if (option) selected.push(option);
    }

    return (
      <Select
        name={name as string}
        labelKey={labelKey}
        filterBy={filterBy}
        label={label}
        selected={selected}
        options={options}
        onChange={(selected: Option[]) => {
          this.handleSelectChange(selected, name, onChange);
        }}
        error={errors[name] as string}
        onRefresh={onRefresh}
        disabled={disabled}
      />
    );
  }

  renderMultiSelect<O extends Option>(
    name: keyof T,
    label: string,
    options: O[],
    labelKey: TypeaheadLabelKey<O>,
    filterBy?: string[]
  ) {
    const { data, errors } = this.state;

    const selected: O[] = [];

    if (!isEmpty(data[name])) {
      (data[name] as number[]).forEach((id) => {
        const option = options.find((o) => o.id === id);
        if (option) selected.push(option);
      });
    }

    return (
      <MultiSelect
        name={name as string}
        labelKey={labelKey}
        filterBy={filterBy}
        label={label}
        selected={selected}
        options={options}
        onChange={(selected: O[]) =>
          this.handleMultiSelectChange(selected, name)
        }
        error={errors[name] as string}
      />
    );
  }
}

export default Form;
