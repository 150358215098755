import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnModel,
  Inject,
  Page,
  Sort,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
  Aggregate,
  AggregatesDirective,
  AggregateDirective,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateColumnModel,
  Reorder,
  Resize,
  ColumnMenu,
  Group,
  ContextMenuItemModel,
  ContextMenu,
  GroupSettings,
} from "@syncfusion/ej2-react-grids";
import { CostumJsonAdaptor } from "../../utils/costumJsonAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import React, { Component } from "react";

interface Props<T> {
  id: string;
  columns: ColumnModel[];
  aggregates?: AggregateColumnModel[];
  data: T[];
  onGridReady?: (grid: GridComponent) => void;
  excelExportComplate?: () => void;
  rowSelected?: (row: T) => void;
  recordDoubleClick?: () => void;
  enablePersistence?: boolean;
  groupSettings?: Partial<GroupSettings>;
  contextMenuOpen?: (args: any) => void;
  queryCellInfo?: (args: any) => void;
  contextMenuItems?: ContextMenuItemModel[];
  contextMenuClick?: (args: ContextMenuItemModel) => void;
}

class DataGrid<T> extends Component<Props<T>, {}> {
  shouldComponentUpdate(nextProps: Props<T>) {
    return this.props.data.length !== nextProps.data.length;
  }

  render() {
    return (
      <GridComponent
        id={"_" + this.props.id}
        locale="hu"
        contextMenuItems={this.props.contextMenuItems}
        contextMenuClick={(args) => {
          if (this.props.contextMenuClick && args)
            this.props.contextMenuClick(args.item);
        }}
        allowGrouping={true}
        groupSettings={{
          ...this.props.groupSettings,
          disablePageWiseAggregates: true,
        }}
        enablePersistence={
          this.props.enablePersistence !== undefined
            ? this.props.enablePersistence
            : true
        }
        gridLines="Both"
        rowHeight={30}
        allowPaging={true}
        allowExcelExport={true}
        allowPdfExport={true}
        allowSorting={true}
        allowFiltering={true}
        allowSelection={true}
        selectionSettings={{
          enableToggle: false,
        }}
        rowSelected={(args) => {
          if (this.props.rowSelected && args && args.data)
            this.props.rowSelected(args.data as T);
        }}
        onClick={(args) => {
          console.log(args);
        }}
        recordDoubleClick={() => {
          if (this.props.recordDoubleClick) this.props.recordDoubleClick();
        }}
        allowResizing={true}
        allowReordering={true}
        showColumnMenu={true}
        actionFailure={(e) => console.log(e)}
        ref={(g) => {
          if (this.props.onGridReady)
            this.props.onGridReady(g as GridComponent);
        }}
        excelExportComplete={this.props.excelExportComplate}
        dataSource={
          new DataManager(this.props.data, undefined, new CostumJsonAdaptor())
        }
        pageSettings={{ pageSize: 20 }}
        filterSettings={{
          mode: "Immediate",
          immediateModeDelay: 500,
        }}
        excelQueryCellInfo={(args: any) => {
          if (args.column.type === "number") {
            args.value = args.data[args.column.field];
          }
        }}
        queryCellInfo={(args: any) => {
          if (!args.cell.innerText) {
            args.cell.innerText = "-";
          }
          if (this.props.queryCellInfo) this.props.queryCellInfo(args);
        }}
        contextMenuOpen={(args: any) => {
          if (!args.rowInfo.rowData) {
            args.cancel = true;
            return;
          }
          if (!this.props.contextMenuOpen) return;
          this.props.contextMenuOpen(args);
        }}
      >
        <ColumnsDirective>
          <ColumnDirective visible={false} isPrimaryKey={true} field="id" />
          {this.props.columns.map((column, index) => (
            <ColumnDirective
              key={index}
              type="string"
              clipMode="EllipsisWithTooltip"
              {...column}
            />
          ))}
        </ColumnsDirective>
        {this.props.aggregates && this.props.aggregates.length > 0 && (
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                {this.props.aggregates.map((aggregate, index) => (
                  <AggregateColumnDirective key={index} {...aggregate} />
                ))}
              </AggregateColumnsDirective>
            </AggregateDirective>
          </AggregatesDirective>
        )}
        <Inject
          services={[
            Page,
            Sort,
            Filter,
            Toolbar,
            ExcelExport,
            PdfExport,
            Aggregate,
            Reorder,
            Resize,
            ColumnMenu,
            Group,
            ContextMenu,
          ]}
        />
      </GridComponent>
    );
  }
}

export default DataGrid;
