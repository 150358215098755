import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import transactionTypeService from "../../services/transactionTypeService";
import confirm from "../../utils/confirm";
import SaveTransactiontTypeDto from "../../models/saveTransactionTypeDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import TransactionTypeDto from "../../models/transactionTypeDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";

interface State extends FormState<SaveTransactiontTypeDto> {
  data: SaveTransactiontTypeDto;
}

class TransactionTypeForm extends Form<
  SaveTransactiontTypeDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} - szolgáltató módosítása`
      : "Új szolgáltató";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = yup.object().shape<SaveTransactiontTypeDto>({
    name: yup
      .string()
      .required()
      .max(255)
  });

  async componentDidMount() {
    if (this.id) await this.populateTransactionType();
  }

  async populateTransactionType() {
    try {
      const { data } = await transactionTypeService.getTransactionType(this.id);

      const transactionType = this.mapToViewModel(data);

      this.setState({ data: transactionType });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(transactionType: TransactionTypeDto): SaveTransactiontTypeDto {
    return {
      name: transactionType.name
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? transactionTypeService.modifyTransactionType(this.id, this.state.data)
        : transactionTypeService.addTransactionType(this.state.data));
      toast.success("Szolgáltató sikeresen mentve.");
      this.props.history.push(ROUTE.TRANSACTION_TYPES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a szolgáltatót?", async () => {
      try {
        await transactionTypeService.removeTransactionType(this.id);
        toast.success("Szolgáltató sikeresen törölve.");
        this.props.history.replace(ROUTE.TRANSACTION_TYPES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default TransactionTypeForm;
