import httpService from "./httpService";
import ForeignTaxRefundDto from "../models/foreignTaxRefundDto";
import SaveForeignTaxRefundDto from "../models/saveForeignTaxRefundDto";
import QueryResultDto from "../models/queryResultDto";
import QueryResultItemDto from "../models/queryResultItemDto";
import TransactionDto from "../models/transactionDto";
import ReportColumnDto from "../models/reportColumnDto";
const baseUrl = "/api/foreign-tax-refunds";

function getForeignTaxRefunds(countryId: string) {
  return httpService.get<ForeignTaxRefundDto[]>(baseUrl, {
    params: { countryId }
  });
}

function getForeignTaxRefund(id: string) {
  return httpService.get<ForeignTaxRefundDto>(`${baseUrl}/${id}`);
}

function addForeignTaxRefund(saveForeignTaxRefundDto: SaveForeignTaxRefundDto) {
  return httpService.post(baseUrl, saveForeignTaxRefundDto);
}

function modifyForeignTaxRefund(
  id: string,
  saveForeignTaxRefundDto: SaveForeignTaxRefundDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveForeignTaxRefundDto);
}

function removeForeignTaxRefund(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function exportForeignTaxRefund(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/export`, {
    responseType: "blob"
  });
}

function getForeignTaxRefundView(id: string, page: number, pageSize: number) {
  return httpService.get<QueryResultDto<QueryResultItemDto<TransactionDto>>>(
    `${baseUrl}/${id}/view`,
    {
      params: { page, pageSize }
    }
  );
}

function getForeignTaxRefundViewColumns() {
  return httpService.get<ReportColumnDto[]>(`${baseUrl}/view-columns`);
}

function exportForeignTaxRefundView(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/view-export`, {
    responseType: "blob"
  });
}

export default {
  getForeignTaxRefunds,
  getForeignTaxRefund,
  exportForeignTaxRefund,
  addForeignTaxRefund,
  modifyForeignTaxRefund,
  removeForeignTaxRefund,
  getForeignTaxRefundView,
  getForeignTaxRefundViewColumns,
  exportForeignTaxRefundView
};
