import React from "react";
import { toast } from "react-toastify";
import Form from "../common/form";
import trailerService from "../../services/trailerService";
import confirm from "../../utils/confirm";
import SaveTrailerDto from "../../models/saveTrailerDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import TrailerDto from "../../models/trailerDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";
import { numberToString } from "../../utils/format";

const initialState = {
  data: {
    licensePlate: "",
    weight: "",
    load: ""
  } as SaveTrailerDto,
  errors: {}
};

type State = typeof initialState;

class TrailerForm extends Form<
  SaveTrailerDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.licensePlate} - pótkocsi módosítása`
      : "Új pótkocsi";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  readonly state: State = initialState;

  schema = yup.object().shape<SaveTrailerDto>({
    licensePlate: yup
      .string()
      .required()
      .max(255),
    weight: yup.string().required(),
    load: yup.string().required()
  });

  async componentDidMount() {
    if (this.id) await this.populateTrailer();
  }

  async populateTrailer() {
    try {
      const { data } = await trailerService.getTrailer(this.id);
      const trailer = this.mapToViewModel(data);
      this.setState({ data: trailer });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(trailer: TrailerDto): SaveTrailerDto {
    return {
      licensePlate: trailer.licensePlate,
      weight: numberToString(trailer.weight),
      load: numberToString(trailer.load)
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? trailerService.modifyTrailer(this.id, this.state.data)
        : trailerService.addTrailer(this.state.data));
      toast.success("Pótkocsi sikeresen mentve.");
      this.props.history.push(ROUTE.TRAILERS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a pótkocsit?", async () => {
      try {
        await trailerService.removeTrailer(this.id);
        toast.success("Pótkocsi sikeresen törölve.");
        this.props.history.replace(ROUTE.TRAILERS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("licensePlate", <Required label="Rendszám" />)}
          {this.renderInput("weight", <Required label="Súly" />, "number")}
          {this.renderInput(
            "load",
            <Required label="Terhelhetőség" />,
            "number"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default TrailerForm;
