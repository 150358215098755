import client from "./client";
import LoginDto from "../models/loginDto";

const endpoint = "/auth";

const login = (loginDto: LoginDto) => {
  return client.post<string>(endpoint, loginDto);
};

export default { login };
