import React, { useState, useRef, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import commercialGasRefundService from "../../services/commercialGasRefundService";
import CommercialGasRefundDto from "../../models/commercialGasRefundDto";
import setDocumentTitle from "../../utils/document";
import {
  ColumnModel,
  GridComponent,
  AggregateColumnModel,
  QueryCellInfoEventArgs,
} from "@syncfusion/ej2-react-grids";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import TransactionDto from "../../models/transactionDto";
import pdfExport from "../../utils/pdfExport";
import PdfExportButton from "../common/pdfExportButton";
import {
  formatDate,
  formatNumber,
  FLOAT2,
  INT,
  YYYY_MM_DD_HH_mm,
  YYYY_MM_DD,
  JSON_DATE,
} from "../../utils/format";
import ReactDOM from "react-dom";
import CheckBox from "../common/checkBox";
import transactionService from "../../services/transactionService";

const aggregates: AggregateColumnModel[] = [
  {
    field: "quantity",
    type: "Sum",
    footerTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, FLOAT2)}</span>;
    },
    groupFooterTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, FLOAT2)}</span>;
    },
  } as any,
];

const columns: ColumnModel[] = [
  {
    field: "invoice",
    headerText: "Számla",
  },
  {
    field: "vehicle",
    headerText: "Jármű",
  },
  {
    field: "card",
    headerText: "Kártya",
  },
  {
    field: "date",
    headerText: "Dátum",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatDate((data as TransactionDto).date, JSON_DATE, YYYY_MM_DD_HH_mm),
  },
  {
    field: "km",
    headerText: "Km",
    textAlign: "Right",
  },
  {
    field: "station",
    headerText: "Állomás",
  },
  {
    field: "quantity",
    headerText: "Mennyiség",
    textAlign: "Right",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatNumber((data as TransactionDto).quantity, FLOAT2),
  },
  {
    field: "product",
    headerText: "Termék",
  },
  {
    field: "netUnitPrice",
    headerText: "Nettó egységár",
    textAlign: "Right",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatNumber((data as TransactionDto).netUnitPrice, INT),
  },
  {
    field: "grossUnitPrice",
    headerText: "Bruttó egységár",
    textAlign: "Right",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatNumber((data as TransactionDto).grossUnitPrice, INT),
  },
  {
    field: "netTotal",
    headerText: "Nettó összeg",
    textAlign: "Right",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatNumber((data as TransactionDto).netTotal, INT),
  },
  {
    field: "grossTotal",
    headerText: "Bruttó összeg",
    textAlign: "Right",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      formatNumber((data as TransactionDto).grossTotal, INT),
  },
  {
    field: "notRefundable",
    headerText: "Nem visszaigényelhető",
  },
];

const CommercialGasRefundView = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const [transactions, setTransactions] = useState<TransactionDto[]>();
  const [refund, setRefund] = useState<CommercialGasRefundDto>();
  const grid = useRef<GridComponent>();

  useEffect(() => {
    const populateRefund = async () => {
      const { data } = await commercialGasRefundService.getCommercialGasRefund(
        id
      );
      setRefund(data);
    };

    const populateTransactions = async () => {
      const {
        data,
      } = await commercialGasRefundService.getCommercialGasRefundView(id);
      setTransactions(data);
    };

    populateRefund();
    populateTransactions();
  }, [id]);

  const handleExcelExport = () => {
    if (!grid.current || !refund) return;
    const groupColumns = grid.current.groupSettings.columns;
    const fileName = groupColumns?.length ? `${refund.name}_${groupColumns.join('_')}.xlsx` : `${refund.name}.xlsx`;
    grid.current.excelExport({ fileName });
  };
  const handlePdfExport = () => {
    if (!grid.current || !refund) return;
    const groupColumns = grid.current.groupSettings.columns;
    const fileName = groupColumns?.length ? `${refund.name}_${groupColumns.join('_')}.pdf` : `${refund.name}.pdf`;
    pdfExport(
      grid.current,
      fileName,
      `Visszaigénylési időszak: ${formatDate(
        refund.refundFrom,
        JSON_DATE,
        YYYY_MM_DD
      )} - ${formatDate(
        refund.refundTo,
        JSON_DATE,
        YYYY_MM_DD
      )}, Visszaigényelhető összeg: ${formatNumber(
        refund.recoverableAmount,
        INT
      )} Ft`
    );
  };

  const handleNotRefundableChange = async (transaction: TransactionDto) => {
    await transactionService.modifyNotRefundable(
      transaction.id,
      transaction.notRefundable
    );

    const { data } = await commercialGasRefundService.getCommercialGasRefund(
      id
    );

    setRefund(data);
  };

  if (!refund || !transactions) return <>Betöltés...</>;

  setDocumentTitle(refund.name);

  return (
    <>
      <h1>{refund.name}</h1>
      <ExcelExportButton onClick={handleExcelExport} />
      <PdfExportButton onClick={handlePdfExport} />

      <ul className="list-group">
        <li className="list-group-item">
          Visszaigénylési időszak:&nbsp;
          <strong>
            {formatDate(refund.refundFrom, JSON_DATE, YYYY_MM_DD)}
            &nbsp;-&nbsp;
            {formatDate(refund.refundTo, JSON_DATE, YYYY_MM_DD)}
          </strong>
        </li>
        <li className="list-group-item">
          Visszaigényelhető összeg:
          <strong> {formatNumber(refund.recoverableAmount, INT)} Ft</strong>
        </li>
      </ul>

      <DataGrid
        id="commercialgasrefundview"
        aggregates={aggregates}
        columns={columns}
        data={transactions}
        onGridReady={(g) => (grid.current = g)}
        queryCellInfo={(args: QueryCellInfoEventArgs) => {
          const transaction = args.data as TransactionDto;
          if (args.column?.field === "notRefundable") {
            ReactDOM.render(
              <CheckBox
                name={`notRefundable${transaction.id}`}
                label=""
                checked={transaction.notRefundable}
                onChange={() => {
                  transaction.notRefundable = !transaction.notRefundable;
                  handleNotRefundableChange(transaction);
                  grid.current?.refresh();
                }}
              />,
              args.cell as Element
            );
          }
        }}
      />
    </>
  );
};

export default CommercialGasRefundView;
