import httpService from "./httpService";
import CompanyDto from "../models/companyDto";
import { AxiosResponse } from "axios";
import SaveCompanyDto from "../models/saveCompanyDto";

const baseUrl = "/api/companies";

function getCompanies(): Promise<AxiosResponse<CompanyDto[]>> {
  return httpService.get<CompanyDto[]>(baseUrl);
}

function getCompany(id: string): Promise<AxiosResponse<CompanyDto>> {
  return httpService.get<CompanyDto>(`${baseUrl}/${id}`);
}

function addCompany(company: SaveCompanyDto) {
  return httpService.post(baseUrl, company);
}

function modifyCompany(id: string, company: SaveCompanyDto) {
  return httpService.put(`${baseUrl}/${id}`, company);
}

function removeCompany(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getCompanies,
  getCompany,
  addCompany,
  modifyCompany,
  removeCompany
};
