import React from "react";
import { toast } from "react-toastify";

import Form, { FormState } from "../common/form";
import invoiceService from "../../services/invoiceService";
import countryService from "../../services/countryService";
import transactionTypeService from "../../services/transactionTypeService";
import FileInput from "../common/fileInput";
import confirm from "../../utils/confirm";
import SaveInvoiceDto from "../../models/saveInvoiceDto";
import TransactionTypeDto from "../../models/transactionTypeDto";
import CountryDto from "../../models/countryDto";
import CompanyDto from "../../models/companyDto";
import { RouteComponentProps } from "react-router";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import Required from "../common/required";
import * as yup from "yup";
import {
  numberToString,
  formatDate,
  YYYY_MM_DD,
  JSON_DATE,
} from "../../utils/format";
import InvoiceDto from "../../models/invoiceDto";

interface State extends FormState<SaveInvoiceDto> {
  data: SaveInvoiceDto;
  manual: boolean;
  fileName: string;
  companies: CompanyDto[];
  countries: CountryDto[];
  transactionTypes: TransactionTypeDto[];
}

class InvoiceForm extends Form<
  SaveInvoiceDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  get title() {
    return this.id
      ? `${this.state.data.number} számla módosítása`
      : "Új számla";
  }

  state: State = {
    data: {
      number: "",
      countryId: "",
      transactionTypeId: "",
      date: "",
      dateOfInvoice: "",
      paid: false,
      refundable: false,
    },
    manual: true,
    fileName: "",
    errors: {},
    companies: [],
    countries: [],
    transactionTypes: [],
  };

  schema = yup.object().shape<SaveInvoiceDto>({
    number: yup.string().required().max(255),
    countryId: yup.string().required(),
    transactionTypeId: yup.string().required(),
    date: yup.string().date(YYYY_MM_DD),
    dateOfInvoice: yup.string().date(YYYY_MM_DD),
    paid: yup.boolean(),
    refundable: yup.boolean(),
  });

  async componentDidMount() {
    await this.populateCountries();
    await this.populateTransactionTypes();
    if (this.id) await this.populateInvoice();
  }

  async populateCountries() {
    const { data: countries } = await countryService.getCountries();
    this.setState({ countries });
  }

  async populateTransactionTypes() {
    const {
      data: transactionTypes,
    } = await transactionTypeService.getTransactionTypes();
    this.setState({ transactionTypes });
  }

  async populateInvoice() {
    try {
      const { data } = await invoiceService.getInvoice(this.id);
      const invoice = this.mapToViewModel(data);
      this.setState({
        data: invoice,
        manual: data.manual,
        fileName: data.fileName,
      });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(invoice: InvoiceDto): SaveInvoiceDto {
    return {
      number: invoice.number,
      countryId: numberToString(invoice.countryId),
      transactionTypeId: numberToString(invoice.transactionTypeId),
      date: formatDate(invoice.date, JSON_DATE, YYYY_MM_DD),
      dateOfInvoice: formatDate(invoice.dateOfInvoice, JSON_DATE, YYYY_MM_DD),
      paid: invoice.paid,
      refundable: invoice.refundable,
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? invoiceService.modifyInvoice(this.id, this.state.data)
        : invoiceService.addInvoice(this.state.data));
      this.props.history.push(ROUTE.INVOICES);
      toast.success("Számla sikeresen mentve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleFileUpload = async ({ currentTarget: input }: any) => {
    try {
      const file = input.files[0];
      const { data } = await invoiceService.uploadFile(this.id, file);
      this.setState({ fileName: data });
      toast.success("Számla csatolmány sikeresen felöltve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleFileRemove = async () => {
    confirm("Biztos törölni szeretné a számla csatolmányt?", async () => {
      await invoiceService.removeFile(this.id);
      this.setState({ fileName: "" });
      toast.success("Számla sikeresen törölve.");
    });
  };

  handleFileDownload = async () => {
    await invoiceService.downloadFile(this.id);
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a számlát?", async () => {
      try {
        await invoiceService.removeInvoice(this.id);
        toast.success("Számla sikeresen törölve.");
        this.props.history.replace(ROUTE.INVOICES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    const newInvoice = this.props.match.params.id === "new";

    setDocumentTitle(this.title);

    const country = this.state.countries.find(
      (c) => c.id.toString() === this.state.data.countryId
    );
    const countryName = country ? country.name : "";

    const transactionType = this.state.transactionTypes.find(
      (t) => t.id.toString() === this.state.data.transactionTypeId
    );
    const transactionTypeName = transactionType ? transactionType.name : "";

    return (
      <div>
        <h1>{this.title}</h1>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#masterdata"
              role="tab"
            >
              Törzsadatok
            </a>
          </li>
          {!newInvoice && (
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#uploads"
                role="tab"
              >
                Dokumentum
              </a>
            </li>
          )}
        </ul>
        <br />
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="masterdata"
            role="tabpanel"
          >
            <form onSubmit={this.handleSubmit}>
              {(newInvoice || this.state.manual) &&
                this.renderInput("number", <Required label="Számlaszám" />)}

              {(newInvoice || this.state.manual) &&
                this.renderSelect(
                  "countryId",
                  <Required label="Ország" />,
                  this.state.countries,
                  "name"
                )}
              {(newInvoice || this.state.manual) &&
                this.renderSelect(
                  "transactionTypeId",
                  <Required label="Szolgáltató" />,
                  this.state.transactionTypes,
                  "name"
                )}
              {!this.state.manual && (
                <>
                  <div>Számlaszám: {this.state.data.number}</div>
                  <div>Ország: {countryName}</div>
                  <div>Szolgáltató: {transactionTypeName}</div>
                  <br />
                  <br />
                </>
              )}

              {this.renderInput("date", <Required label="Teljesítés kelte" />)}
              {this.renderInput(
                "dateOfInvoice",
                <Required label="Számla kiállításának napja" />
              )}
              {this.renderCheckBox("paid", "Kifizetve")}
              {this.renderCheckBox("refundable", "Visszaigényelhető")}
              <br />
              {this.renderButton("Mentés")}
              {this.id && this.state.manual && (
                <button
                  onClick={this.handleRemove}
                  className="btn btn-danger ml-2"
                >
                  Törlés
                </button>
              )}
            </form>
          </div>

          <div className="tab-pane fade show" id="uploads" role="tabpanel">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Dokumentum</th>
                  <th style={{ width: 150 }}>Műveletek</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Számla</td>
                  <td>
                    {!this.state.fileName ? (
                      <FileInput
                        name="invoice"
                        multiple={false}
                        label={<i className="fa fa-upload" />}
                        onChange={this.handleFileUpload}
                      />
                    ) : (
                      <React.Fragment>
                        <button
                          className="btn btn-primary mr-1"
                          onClick={this.handleFileDownload}
                        >
                          <i className="fa fa-download" />
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-danger"
                          onClick={this.handleFileRemove}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceForm;
