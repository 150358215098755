import http from "./httpService";
import CountryDto from "../models/countryDto";

const baseUrl = "/api/countries";

function getCountries() {
  return http.get<CountryDto[]>(baseUrl);
}

function getCountry(id: string) {
  return http.get<CountryDto>(`${baseUrl}/${id}`);
}

function getForeignTaxCountries() {
  return http.get<CountryDto[]>(`${baseUrl}/foreign-tax`);
}

export default {
  getCountries,
  getCountry,
  getForeignTaxCountries
};
