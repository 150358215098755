import React from 'react';
import { toast } from 'react-toastify';
import tankService from '../../services/tankService';
import supplierService from '../../services/supplierService';
import companyService from '../../services/companyService';
import supplyService from '../../services/supplyService';
import Form, { FormState } from '../common/form';
import confirm from '../../utils/confirm';
import SaveSupplyDto from '../../models/saveSupplyDto';
import { RouteComponentProps } from 'react-router';
import SupplierDto from '../../models/supplierDto';
import TankDto from '../../models/tankDto';
import CompanyDto from '../../models/companyDto';
import * as yup from 'yup';
import {
  YYYY_MM_DD,
  formatDate,
  numberToString,
  YYYY_MM_DD_HH_mm_ss,
  JSON_DATE,
} from '../../utils/format';
import SupplyDto from '../../models/supplyDto';
import setDocumentTitle from '../../utils/document';
import * as ROUTE from '../../constants/route';
import Required from '../common/required';

interface State extends FormState<SaveSupplyDto> {
  data: SaveSupplyDto;
  suppliers: SupplierDto[];
  tanks: TankDto[];
  companies: CompanyDto[];
  sumSupplies: SupplyDto[];
}

class SupplyForm extends Form<
  SaveSupplyDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get id() {
    return this.props.match.params.id === 'new'
      ? ''
      : this.props.match.params.id;
  }
  get title() {
    return this.id
      ? `${this.state.data.invoiceNumber} beszállítás módosítása`
      : 'Új beszállítás';
  }

  state: State = {
    data: {
      date: '',
      dateOfInvoice: '',
      invoiceNumber: '',
      quantity: '',
      grossTotal: '',
      supplierId: '',
      tankId: '',
      companyId: '',
      startQuantity: '',
      startDateTime: '',
      sumInvoice: false,
      subInvoice: false,
      sumSupplyId: '',
    },
    suppliers: [],
    tanks: [],
    companies: [],
    errors: {},
    sumSupplies: [],
  };

  schema = yup.object().shape<SaveSupplyDto>({
    sumInvoice: yup.boolean(),
    subInvoice: yup.boolean(),
    invoiceNumber: yup
      .string()
      .max(255)
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().required() })
      .when('subInvoice', { is: true, then: yup.string().notRequired() }),
    date: yup.string().required().date(YYYY_MM_DD_HH_mm_ss),
    dateOfInvoice: yup
      .string()
      .date(YYYY_MM_DD)
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().required() })
      .when('subInvoice', { is: true, then: yup.string().notRequired() }),
    supplierId: yup
      .string()
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().required() })
      .when('subInvoice', { is: true, then: yup.string().notRequired() }),
    tankId: yup.string(),
    sumSupplyId: yup
      .string()
      .when('subInvoice', { is: true, then: yup.string().required() }),
    companyId: yup
      .string()
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().required() })
      .when('subInvoice', { is: true, then: yup.string().notRequired() }),
    quantity: yup
      .string()
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().notRequired() })
      .when('subInvoice', { is: true, then: yup.string().required() }),
    grossTotal: yup
      .string()
      .when(['sumInvoice', 'subInvoice'], {
        is: false,
        then: yup.string().required(),
      })
      .when('sumInvoice', { is: true, then: yup.string().notRequired() })
      .when('subInvoice', { is: true, then: yup.string().required() }),
    startDateTime: yup.string().date(YYYY_MM_DD_HH_mm_ss),
    startQuantity: yup.string(),
  });

  async componentDidMount() {
    await this.populateSuppliers();
    await this.populateTanks();
    await this.populateCompanies();
    await this.populateSumSupplies();
    if (this.id) await this.populateSupply();
  }

  async populateSuppliers() {
    const { data: suppliers } = await supplierService.getSuppliers();
    this.setState({ suppliers });
  }
  async populateTanks() {
    const { data: tanks } = await tankService.getTanks();
    this.setState({ tanks });
  }
  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }
  async populateSumSupplies() {
    const { data: sumSupplies } = await supplyService.getSumSupplies();
    this.setState({ sumSupplies });
  }

  async populateSupply() {
    try {
      const { data } = await supplyService.getSupply(this.id);
      const supply = this.mapToViewModel(data);
      this.setState({ data: supply });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(supply: SupplyDto): SaveSupplyDto {
    return {
      invoiceNumber: supply.invoiceNumber,
      date: formatDate(supply.date, JSON_DATE, YYYY_MM_DD_HH_mm_ss),
      dateOfInvoice: formatDate(supply.dateOfInvoice, JSON_DATE, YYYY_MM_DD),
      supplierId: numberToString(supply.supplierId),
      tankId: numberToString(supply.tankId),
      companyId: numberToString(supply.companyId),
      quantity: numberToString(supply.quantity),
      grossTotal: numberToString(supply.grossTotal),
      startQuantity: numberToString(supply.startQuantity),
      startDateTime: formatDate(
        supply.startDateTime,
        JSON_DATE,
        YYYY_MM_DD_HH_mm_ss
      ),
      sumInvoice: supply.sumInvoice,
      subInvoice: supply.subInvoice,
      sumSupplyId: numberToString(supply.sumSupplyId),
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? supplyService.modifySupply(this.id, this.state.data)
        : supplyService.addSupply(this.state.data));
      toast.success('Beszállítás sikeresen mentve.');
      this.props.history.push(ROUTE.SUPPLIES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm('Biztos törölni szeretné a beszállítást?', async () => {
      try {
        await supplyService.removeSupply(this.id);
        toast.success('Beszállítás sikeresen törölve.');
        this.props.history.replace(ROUTE.SUPPLIES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    const { data } = this.state;

    const title = this.id
      ? `${data.invoiceNumber} beszállítás módosítása`
      : 'Új beszállítás';
    setDocumentTitle(title);

    return (
      <div>
        <h1>{title}</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col">
              {this.renderInput(
                'invoiceNumber',
                this.state.data.subInvoice ? (
                  'Számlaszám'
                ) : (
                  <Required label="Számlaszám" />
                ),
                'text',
                this.state.data.subInvoice
              )}
            </div>

            <div className="col" style={{ paddingTop: 40 }}>
              {this.renderCheckBox(
                'sumInvoice',
                'Gyűjtőszámla',
                this.state.data.subInvoice
              )}
            </div>

            <div className="col">
              {this.renderSelect(
                'sumSupplyId',
                !this.state.data.subInvoice ? (
                  'Gyűjtőszámla választása'
                ) : (
                  <Required label="Gyűjtőszámla választása" />
                ),
                this.state.sumSupplies,
                'invoiceNumber',
                undefined,
                undefined,
                undefined,
                !this.state.data.subInvoice
              )}
            </div>
            <div className="col" style={{ paddingTop: 40 }}>
              {this.renderCheckBox(
                'subInvoice',
                'Alszámla',
                this.state.data.sumInvoice
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderInput(
                'date',
                <Required
                  label={
                    this.state.data.subInvoice
                      ? 'Beszállítás kelte'
                      : 'Teljesítés kelte'
                  }
                />
              )}
            </div>
            <div className="col">
              {this.renderInput(
                'dateOfInvoice',
                this.state.data.subInvoice ? (
                  'Számla kiállításnak napja'
                ) : (
                  <Required label="Számla kiállításnak napja" />
                ),
                'text',
                this.state.data.subInvoice
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderSelect(
                'supplierId',
                this.state.data.subInvoice ? (
                  'Beszállító'
                ) : (
                  <Required label="Beszállító" />
                ),
                this.state.suppliers,
                'name',
                undefined,
                undefined,
                undefined,
                this.state.data.subInvoice
              )}
            </div>
            <div className="col">
              {this.renderSelect(
                'tankId',
                this.state.data.sumInvoice ? (
                  'Tartály'
                ) : (
                  <Required label="Tartály" />
                ),
                this.state.tanks,
                'name',
                undefined,
                undefined,
                undefined,
                this.state.data.sumInvoice
              )}
            </div>
            <div className="col">
              {this.renderSelect(
                'companyId',
                this.state.data.subInvoice ? 'Cég' : <Required label="Cég" />,
                this.state.companies,
                'nameOfTaxPlayer',
                undefined,
                undefined,
                undefined,
                this.state.data.subInvoice
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderInput(
                'quantity',
                this.state.data.sumInvoice ? (
                  'Mennyiség'
                ) : (
                  <Required label="Mennyiség" />
                ),
                'number',
                this.state.data.sumInvoice
              )}
            </div>
            <div className="col">
              {this.renderInput(
                'grossTotal',
                this.state.data.sumInvoice ? (
                  'Összeg'
                ) : (
                  <Required label="Összeg" />
                ),
                'number',
                this.state.data.sumInvoice
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.renderInput(
                'startDateTime',
                'Kezdő dátum',
                'text',
                this.state.data.sumInvoice
              )}
            </div>
            <div className="col">
              {this.renderInput(
                'startQuantity',
                'Kezdő mennyiség',
                'number',
                this.state.data.sumInvoice
              )}
            </div>
          </div>

          {this.renderButton('Mentés')}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default SupplyForm;
