import React, { Component } from "react";
import SortColumn from "../../models/sortColumn";
import Column from "../../models/column";

interface Props<T> {
  sortColumn: SortColumn;
  columns: Column<T>[];
  onSort(sortColumn: SortColumn): void;
}

class TableHeader<T> extends Component<Props<T>> {
  raiseSort = (path: string) => {
    if (!path) return;

    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path.toString() === path.toString())
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }

    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column: Column<T>) => {
    const { sortColumn } = this.props;
    if (!column.path || column.path.toString() !== sortColumn.path.toString())
      return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
    return <i className="fa fa-sort-desc" />;
  };

  render() {
    return (
      <thead>
        <tr>
          {this.props.columns.map((column, index) => (
            <th
              className={column.path ? "clickable" : ""}
              key={index}
              onClick={() => {
                if (column.path) this.raiseSort(column.path);
              }}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
        <tr>
          {this.props.columns.map((column, index) => (
            <th key={`th${index}`} style={{ padding: 0 }}>
              {column.query && column.query()}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
