import React from "react";
import { toast } from "react-toastify";
import confirm from "../../utils/confirm";
import Form, { FormState } from "../common/form";
import SaveProductCategoryDto from "../../models/saveProductCategoryDto";
import ProductDto from "../../models/productDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import productCategoryService from "../../services/productCategoryService";
import ProductCategoryDto from "../../models/productCategoryDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";

interface State extends FormState<SaveProductCategoryDto> {
  data: SaveProductCategoryDto;
  products: ProductDto[];
}

class ProductCategoryForm extends Form<
  SaveProductCategoryDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} termék kategória módosítása`
      : "Új termék kategória";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: "",
      products: []
    },
    errors: {},
    products: []
  };

  schema = yup.object().shape<SaveProductCategoryDto>({
    name: yup
      .string()
      .required()
      .max(255),
    products: yup.array()
  });

  async componentDidMount() {
    await this.populateProducts();
    if (this.id) await this.populateProductCategory();
  }

  async populateProducts() {
    const { data } = await productCategoryService.getSelectableProducts(
      this.id || "0"
    );

    this.setState({ products: data });
  }

  async populateProductCategory() {
    try {
      const { data } = await productCategoryService.getProductCategory(this.id);

      const productCategory = this.mapToViewModel(data);

      this.setState({ data: productCategory });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(productCategory: ProductCategoryDto): SaveProductCategoryDto {
    return {
      name: productCategory.name,
      products: productCategory.products
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? productCategoryService.modifyProductCategory(this.id, this.state.data)
        : productCategoryService.addProductCategory(this.state.data));

      toast.success("Termék kategória sikeresen mentve.");
      this.props.history.push(ROUTE.PRODUCT_CATEGORIES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a termék kategóriát?", async () => {
      try {
        await productCategoryService.removeProductCategory(this.id);
        toast.success("Termék kategória sikeresen törölve.");
        this.props.history.replace(ROUTE.PRODUCT_CATEGORIES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>

        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Megnevezés")}
          {this.renderMultiSelect(
            "products",
            "Termékek",
            this.state.products,
            "name"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default ProductCategoryForm;
