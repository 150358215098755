import httpService from "./httpService";
import SaveUserDto from "../models/saveUserDto";
import UserDto from "../models/userDto";
import { AxiosResponse } from "axios";

const baseUrl = "/api/users";

function getUsers(): Promise<AxiosResponse<UserDto[]>> {
  return httpService.get<UserDto[]>(baseUrl);
}

function getUser(id: string): Promise<AxiosResponse<UserDto>> {
  return httpService.get<UserDto>(baseUrl + "/" + id);
}

function addUser(user: SaveUserDto) {
  return httpService.post(baseUrl, user);
}

function modifyUser(id: string, user: SaveUserDto) {
  return httpService.put(baseUrl + "/" + id, user);
}

function removeUser(id: string) {
  return httpService.delete(baseUrl + "/" + id);
}

export default {
  getUsers,
  getUser,
  addUser,
  modifyUser,
  removeUser,
};
