export const MOL = 1;
export const EUROWAG = 2;
export const SHELL = 3;
export const OMV = 4;
export const DKV = 5;
export const OIL = 6;
export const MOBILPETROL = 7;
export const FLEETCOR = 8;
export const HUNGAROMOBILTANK = 9;
export const WEBTRANSPORT = 10;
export const REPORT = 11;
export const OBU = 12;
export const EUROTOLL = 13;
export const EMYTO = 14;
export const TOLLCOLLECT = 15;
export const MYTO = 16;
export const HUGO = 17;
export const COMMERCIALGAS = 18;
export const FOREIGNTAX = 19;
export const DELIVERYNOTE = 20;
export const HALKER = 21;
export const PSZOLG = 22;
export const EXPORT = 23;
export const TA = 24;
export const TSPAPIRUS = 25;
export const PARTNER = 26;
export const USER = 27;
export const TS = 28;
export const SPRINT = 29;
export const FER = 30;
export const SZALAI = 31;
export const ATTWIN = 32;
export const BANDUR = 33;
export const MOTO95 = 34;
export const NAV = 35;
export const TACHOGRAPH = 36;
export const ITRACK = 37;
export const SHELL_ORIGINAL = 38;
export const ARIS = 39;
export const LUKOIL = 40;
export const OPLUS = 41;
export const AS24 = 42;
export const WSZL = 43;
export const IMIX = 44;
export const VARGA_ES_TARSA = 45;
export const KONTENERKUT = 46;
export const EDO = 47;
export const JOGA = 48;
export const TRANSDANUBIA = 49;
export const DUVENBECK = 50;
export const UTA = 51;
