import React from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  label: string;
}

const NewLink = ({ to, label }: Props) => {
  return (
    <Link to={to} className="btn btn-primary my-2 mr-2">
      <i className="fa fa-plus" /> {label}
    </Link>
  );
};

export default NewLink;
