import httpService from "./httpService";
import VehicleCategoryDto from "../models/vehicleCategoryDto";
import SaveVehicleCategoryDto from "../models/saveVehicleCategoryDto";

const baseUrl = "/api/vehicle-categories";

function getVehicleCategories() {
  return httpService.get<VehicleCategoryDto[]>(baseUrl);
}

function getVehicleCategory(id: string) {
  return httpService.get<VehicleCategoryDto>(`${baseUrl}/${id}`);
}

function addVehicleCategory(saveVehicleCategory: SaveVehicleCategoryDto) {
  return httpService.post(baseUrl, saveVehicleCategory);
}

function modifyVehicleCategory(
  id: string,
  saveVehicleCategory: SaveVehicleCategoryDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveVehicleCategory);
}

function removeVehicleCategory(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function exportVehicles(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/export`, {
    responseType: "blob"
  });
}

export default {
  getVehicleCategories,
  getVehicleCategory,
  addVehicleCategory,
  modifyVehicleCategory,
  removeVehicleCategory,
  exportVehicles
};
