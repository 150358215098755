import React from "react";
import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router-dom";
import * as ROUTE from "../../constants/route";
import useAuth from "../../hooks/useAuth";

const ProtectedRoute = ({ render, component, ...routeProps }: RouteProps) => {
  const { user } = useAuth();

  return (
    <Route
      {...routeProps}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: ROUTE.LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }

        return component
          ? React.createElement(component, props)
          : render
          ? render(props)
          : null;
      }}
    />
  );
};

export default ProtectedRoute;
