import jwtDecode from "jwt-decode";
import UserPayload from "../models/userPayload";

const key = "token";

const storeToken = (authToken: string) => {
  try {
    localStorage.setItem(key, authToken);
  } catch (error) {
    console.log("Error storing the auth token", error);
  }
};

const getToken = () => {
  return localStorage.getItem(key);
};

const getUser = () => {
  const token = getToken();
  if (!token) return null;

  const user = jwtDecode<UserPayload>(token);

  user.hasModule = function (module) {
    return this.Modules.indexOf(module) > -1;
  };

  return user;
};

const removeToken = () => {
  localStorage.removeItem(key);
};

export default { getToken, getUser, removeToken, storeToken };
