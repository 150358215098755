import React from "react";
import { toast } from "react-toastify";
import confirm from "../../utils/confirm";
import Form, { FormState } from "../common/form";
import Required from "../common/required";
import MultiSelect from "../common/multiSelect";
import CheckBox from "../common/checkBox";
import reportService from "../../services/reportService";
import transactionTypeService from "../../services/transactionTypeService";
import supplyService from "../../services/supplyService";
import invoiceService from "../../services/invoiceService";
import vehicleService from "../../services/vehicleService";
import cardService from "../../services/cardService";
import countryService from "../../services/countryService";
import stationService from "../../services/stationService";
import productService from "../../services/productService";
import currencyService from "../../services/currencyService";
import vehicleCategoryService from "../../services/vehicleCategoryService";
import productCategoryService from "../../services/productCategoryService";
import columnService from "../../services/columnService";
import companyService from "../../services/companyService";
import SaveReportDto from "../../models/saveReportDto";
import { RouteComponentProps } from "react-router";
import TransactionTypeDto from "../../models/transactionTypeDto";
import InvoiceDto from "../../models/invoiceDto";
import VehicleDto from "../../models/vehicleDto";
import CardDto from "../../models/cardDto";
import CountryDto from "../../models/countryDto";
import StationDto from "../../models/stationDto";
import ProductDto from "../../models/productDto";
import CurrencyDto from "../../models/currencyDto";
import ProductCategoryDto from "../../models/productCategoryDto";
import VehicleCategoryDto from "../../models/vehicleCategoryDto";
import SupplyDto from "../../models/supplyDto";
import CompanyDto from "../../models/companyDto";
import ColumnDto from "../../models/columnDto";
import * as yup from "yup";
import {
  YYYY_MM_DD_HH_mm_ss,
  formatDate,
  numberToString,
  JSON_DATE,
} from "../../utils/format";
import ReportDto from "../../models/reportDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import { remove, max, min } from "lodash";
import ReportColumnDto from "../../models/reportColumnDto";

interface State extends FormState<SaveReportDto> {
  data: SaveReportDto;
  transactionTypes: TransactionTypeDto[];
  invoices: InvoiceDto[];
  vehicles: VehicleDto[];
  cards: CardDto[];
  countries: CountryDto[];
  stations: StationDto[];
  products: ProductDto[];
  currencies: CurrencyDto[];
  productCategories: ProductCategoryDto[];
  vehicleCategories: VehicleCategoryDto[];
  supplies: SupplyDto[];
  companies: CompanyDto[];
  columns: ColumnDto[];
  selectableColumns: ColumnDto[];
  selectedColumns: ColumnDto[];
  selectableSumColumns: ColumnDto[];
  selectableAvgColumns: ColumnDto[];
}

class ReportForm extends Form<
  SaveReportDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id ? `${this.state.data.name} riport módosítása` : "Új riport";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: "",
      startDate: "",
      endDate: "",
      transactionTypes: [],
      invoices: [],
      vehicles: [],
      cards: [],
      countries: [],
      stations: [],
      products: [],
      supplierCurrencies: [],
      issuerCurrencies: [],
      vehicleCategoryId: "",
      productCategoryId: "",
      supplyId: "",
      companyId: "",
      columns: [],
      noDeliveryNote: false,
    },
    errors: {},
    transactionTypes: [],
    invoices: [],
    vehicles: [],
    cards: [],
    countries: [],
    stations: [],
    products: [],
    currencies: [],
    productCategories: [],
    vehicleCategories: [],
    supplies: [],
    companies: [],
    columns: [],
    selectableColumns: [],
    selectedColumns: [],
    selectableSumColumns: [],
    selectableAvgColumns: [],
  };

  schema = yup.object().shape<SaveReportDto>({
    name: yup.string().required().max(255),
    startDate: yup.string().required().date(YYYY_MM_DD_HH_mm_ss),
    endDate: yup.string().required().date(YYYY_MM_DD_HH_mm_ss),
    transactionTypes: yup.array(),
    invoices: yup.array(),
    vehicles: yup.array(),
    cards: yup.array(),
    countries: yup.array(),
    stations: yup.array(),
    products: yup.array(),
    supplierCurrencies: yup.array(),
    issuerCurrencies: yup.array(),
    vehicleCategoryId: yup.string(),
    productCategoryId: yup.string(),
    supplyId: yup.string(),
    companyId: yup.string(),
    columns: yup.array(),
    noDeliveryNote: yup.boolean(),
  });

  async componentDidMount() {
    await this.populateTransactionTypes();
    await this.populateInvoices();
    await this.populateVehicles();
    await this.populateCards();
    await this.populateCountries();
    await this.populateStations();
    await this.populateProducts();
    await this.populateCurrencies();
    await this.populateVehicleCategories();
    await this.populateProductCategories();
    await this.populateSupplies();
    await this.populateCompanies();

    const { data: columns } = await columnService.getColumns();
    this.setState({ columns, selectableColumns: columns }, async () => {
      if (this.id) await this.populateReport();
    });
  }

  async populateTransactionTypes() {
    const {
      data: transactionTypes,
    } = await transactionTypeService.getTransactionTypes();
    this.setState({ transactionTypes });
  }

  async populateInvoices() {
    const { data } = await invoiceService.getInvoices();
    this.setState({ invoices: data });
  }

  async populateVehicles() {
    const { data: vehicles } = await vehicleService.getVehicles();
    this.setState({ vehicles });
  }

  async populateCards() {
    const { data: cards } = await cardService.getCards();
    this.setState({ cards });
  }

  async populateCountries() {
    const { data: countries } = await countryService.getCountries();
    this.setState({ countries });
  }

  async populateStations() {
    const { data: stations } = await stationService.getStations();
    this.setState({ stations });
  }

  async populateProducts() {
    const { data: products } = await productService.getProducts();
    this.setState({ products });
  }

  async populateCurrencies() {
    const { data: currencies } = await currencyService.getCurrencies();
    this.setState({ currencies });
  }

  async populateVehicleCategories() {
    const {
      data: vehicleCategories,
    } = await vehicleCategoryService.getVehicleCategories();
    this.setState({ vehicleCategories });
  }

  async populateProductCategories() {
    const {
      data: productCategories,
    } = await productCategoryService.getProductCategories();
    this.setState({ productCategories });
  }

  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  async populateSupplies() {
    const { data } = await supplyService.getSupplies();
    this.setState({ supplies: data });
  }

  async populateReport() {
    try {
      const { data } = await reportService.getReport(this.id);

      const report = this.mapToViewModel(data);

      const selectableColumns = [...this.state.selectableColumns];
      const selectableSumColumns: ColumnDto[] = [];
      const selectableAvgColumns: ColumnDto[] = [];

      report.columns.forEach((reportColumn) => {
        const column = reportColumn.column;
        remove(
          selectableColumns,
          (selectableColumn) => selectableColumn.id === column.id
        );
        if (column.summable) selectableSumColumns.push(column);
        if (column.avgable) selectableAvgColumns.push(column);
      });

      this.setState({
        data: report,
        selectableColumns,
        selectableSumColumns,
        selectableAvgColumns,
      });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(reportDto: ReportDto): SaveReportDto {
    return {
      name: reportDto.name,
      startDate: formatDate(
        reportDto.startDate,
        JSON_DATE,
        YYYY_MM_DD_HH_mm_ss
      ),
      endDate: formatDate(reportDto.endDate, JSON_DATE, YYYY_MM_DD_HH_mm_ss),
      transactionTypes: reportDto.transactionTypes,
      invoices: reportDto.invoices,
      vehicles: reportDto.vehicles,
      cards: reportDto.cards,
      countries: reportDto.countries,
      stations: reportDto.stations,
      products: reportDto.products,
      supplierCurrencies: reportDto.supplierCurrencies,
      issuerCurrencies: reportDto.issuerCurrencies,
      vehicleCategoryId: numberToString(reportDto.vehicleCategoryId),
      productCategoryId: numberToString(reportDto.productCategoryId),
      supplyId: numberToString(reportDto.supplyId),
      companyId: numberToString(reportDto.companyId),
      columns: reportDto.columns,
      noDeliveryNote: reportDto.noDeliveryNote,
    };
  }

  doSubmit = async () => {
    try {
      if (this.id) await reportService.modifyReport(this.id, this.state.data);
      else await reportService.addReport(this.state.data);
      toast.success("Riport sikeresen mentve.");
      this.props.history.push(ROUTE.REPORTS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a riportot?", async () => {
      try {
        await reportService.removeReport(this.id);
        toast.success("Riport sikeresen törölve.");
        this.props.history.replace(ROUTE.REPORTS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  handleColumnsSelected = (selectedColumns: ColumnDto[]) => {
    this.setState({ selectedColumns });
  };

  handleAddColumns = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const selectedColumns = [...this.state.selectedColumns];
    const selectableColumns = [...this.state.selectableColumns];
    const data = { ...this.state.data };
    const selectableSumColumns = [...this.state.selectableSumColumns];
    const selectableAvgColumns = [...this.state.selectableAvgColumns];

    selectedColumns.forEach((selectedColumn) => {
      const order =
        max(data.columns.map((reportColumn) => reportColumn.order)) || 0;

      const column: ReportColumnDto = {
        column: selectedColumn,
        sum: false,
        avg: false,
        group: false,
        order,
        sums: [],
        avgs: [],
      };

      data.columns.push(column);

      remove(
        selectableColumns,
        (selectableColumn) => selectableColumn.id === selectedColumn.id
      );

      if (selectedColumn.summable) selectableSumColumns.push(selectedColumn);
      if (selectedColumn.avgable) selectableAvgColumns.push(selectedColumn);
    });

    this.setState({
      data,
      selectedColumns: [],
      selectableColumns,
      selectableSumColumns,
      selectableAvgColumns,
    });
  };

  handleGroupChecked = (columnId: number) => {
    const data = { ...this.state.data };

    const column = data.columns.find((column) => column.column.id === columnId);

    if (!column) return;

    column.group = !column.group;

    if (!column.group) {
      column.sums = [];
      column.avgs = [];
    }

    data.columns.sort((c1, c2) => {
      if (c1.group && !c2.group) {
        return -1;
      }
      if (!c1.group && c2.group) {
        return 11;
      }
      if (c1.group && c2.group && c1.order > c2.order) {
        return 1;
      }
      if (c1.group && c2.group && c1.order < c2.order) {
        return -1;
      }
      if (!c1.group && !c2.group && c1.order > c2.order) {
        return 1;
      }
      if (!c1.group && !c2.group && c1.order < c2.order) {
        return -1;
      }

      return 0;
    });

    for (let i = 0; i < data.columns.length; i++) {
      data.columns[i].order = i;
    }

    this.setState({ data });
  };

  handleSumChecked = (columnId: number) => {
    const data = { ...this.state.data };
    const column = data.columns.find((column) => column.column.id === columnId);
    if (!column) return;
    column.sum = !column.sum;
    this.setState({ data });
  };

  handleAvgChecked = (columnId: number) => {
    let data = { ...this.state.data };
    let column = data.columns.find((column) => column.column.id === columnId);
    if (!column) return;
    column.avg = !column.avg;
    this.setState({ data });
  };

  handleSumColumnsSelected = (columns: ColumnDto[], columnId: number) => {
    const data = { ...this.state.data };
    const column = data.columns.find((column) => column.column.id === columnId);
    if (!column) return;
    column.sums = columns;
    this.setState({ data });
  };

  handleAvgColumnsSelected = (columns: ColumnDto[], columnId: number) => {
    let data = { ...this.state.data };
    let column = data.columns.find((column) => column.column.id === columnId);
    if (!column) return;
    column.avgs = columns;
    this.setState({ data });
  };

  handleColumnUp = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    columnId: number
  ) => {
    e.preventDefault();
    const data = { ...this.state.data };
    const column = data.columns.find((c) => c.column.id === columnId);
    if (!column) return;
    const prevColumn = data.columns.find((c) => c.order === column.order - 1);
    if (!prevColumn) return;

    column.order--;
    prevColumn.order++;

    data.columns.sort((c1, c2) => c1.order - c2.order);
    this.setState({ data });
  };

  handleColumnDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    columnId: number
  ) => {
    e.preventDefault();
    const data = { ...this.state.data };
    const column = data.columns.find((c) => c.column.id === columnId);
    if (!column) return;
    const nextColumn = data.columns.find((c) => c.order === column.order + 1);
    if (!nextColumn) return;

    column.order++;
    nextColumn.order--;

    data.columns.sort((c1, c2) => c1.order - c2.order);
    this.setState({ data });
  };

  handleColumnRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    columnId: number
  ) => {
    e.preventDefault();
    const data = { ...this.state.data };
    const selectableColumns = [...this.state.selectableColumns];
    const selectableSumColumns = [...this.state.selectableSumColumns];
    const selectableAvgColumns = [...this.state.selectableAvgColumns];

    remove(data.columns, (column) => column.column.id === columnId);

    data.columns.forEach((column, i) => {
      column.order = i;

      remove(column.sums, (c) => c.id === columnId);
      remove(column.avgs, (c) => c.id === columnId);
    });

    const column = this.state.columns.find((c) => c.id === columnId);
    if (!column) return;
    selectableColumns.push(column);

    if (column.summable) {
      remove(
        selectableSumColumns,
        (selectableSumColumn) => selectableSumColumn.id === columnId
      );
    }

    if (column.avgable) {
      remove(
        selectableAvgColumns,
        (selectableAvgColumn) => selectableAvgColumn.id === columnId
      );
    }

    this.setState({
      data,
      selectableColumns,
      selectableSumColumns,
      selectableAvgColumns,
    });
  };

  isFirstColumn = (columnId: number) => {
    const column = this.state.data.columns.find(
      (c) => c.column.id === columnId
    );
    if (!column) return;

    const minOrder = column.group
      ? min(this.state.data.columns.filter((c) => c.group).map((c) => c.order))
      : min(this.state.data.columns.map((c) => c.order));

    return column.order === minOrder;
  };

  isLastColumn = (columnId: number) => {
    const column = this.state.data.columns.find(
      (c) => c.column.id === columnId
    );
    if (!column) return;

    const maxOrder = column.group
      ? max(this.state.data.columns.filter((c) => c.group).map((c) => c.order))
      : max(this.state.data.columns.map((c) => c.order));

    return column.order === maxOrder;
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>

        <form onSubmit={this.handleSubmit}>
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#query"
                role="tab"
              >
                Szűrési feltételek
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#display"
                role="tab"
              >
                Megjelenítési beállítások
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="query"
              role="tabpanel"
            >
              <br />
              <div className="row">
                <div className="col-sm-6">
                  {this.renderInput("name", <Required label="Megnevezés" />)}
                </div>
                <div className="col-sm-3">
                  {this.renderInput(
                    "startDate",
                    <Required label="Időszak kezdete" />
                  )}
                </div>
                <div className="col-sm-3">
                  {this.renderInput(
                    "endDate",
                    <Required label="Időszak vége" />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "transactionTypes",
                    "Szolgáltatók",
                    this.state.transactionTypes,
                    "name"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "invoices",
                    "Számlák",
                    this.state.invoices,
                    "number"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "vehicles",
                    "Járművek",
                    this.state.vehicles,
                    "licensePlate"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "cards",
                    "Kártyák",
                    this.state.cards,
                    "number"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "countries",
                    "Országok",
                    this.state.countries,
                    "code"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "stations",
                    "Állomások",
                    this.state.stations,
                    "name"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "products",
                    "Termékek",
                    this.state.products,
                    "name"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderSelect(
                    "supplyId",
                    "Beszállítás",
                    this.state.supplies,
                    "invoiceNumber"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "supplierCurrencies",
                    "Elfogadó ország devizák",
                    this.state.currencies,
                    "name"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderMultiSelect(
                    "issuerCurrencies",
                    "Kibocsátó ország devizák",
                    this.state.currencies,
                    "name"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {this.renderSelect(
                    "vehicleCategoryId",
                    "Jármű kategória",
                    this.state.vehicleCategories,
                    "name"
                  )}
                </div>
                <div className="col-sm-6">
                  {this.renderSelect(
                    "productCategoryId",
                    "Termék kategória",
                    this.state.productCategories,
                    "name"
                  )}
                </div>
              </div>
              {this.renderSelect(
                "companyId",
                "Cég",
                this.state.companies,
                "nameOfTaxPlayer"
              )}
              {this.renderCheckBox(
                "noDeliveryNote",
                "Nem menetlevélhez rendelt tranzakciók"
              )}
              <br />
            </div>
            <div className="tab-pane fade" id="display" role="tabpanel">
              <br />
              <div className="row">
                <div className="col">
                  <MultiSelect
                    labelKey="name"
                    name="columns"
                    label="Választható oszlopok"
                    error=""
                    options={this.state.selectableColumns}
                    selected={this.state.selectedColumns}
                    onChange={this.handleColumnsSelected}
                  />
                </div>
                <div className="col-sm-3">
                  <button
                    style={{ width: "100%", marginTop: 32 }}
                    onClick={(e) => this.handleAddColumns(e)}
                    className="btn btn-primary"
                  >
                    Oszlopok hozzáadása
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr style={{ height: 50 }}>
                      <th className="align-middle" style={{ width: 150 }}>
                        Oszlop
                      </th>

                      <th className="align-middle" style={{ width: 75 }}>
                        Csoport
                      </th>
                      <th className="align-middle" style={{ width: 75 }}>
                        Összeg
                      </th>
                      <th className="align-middle" style={{ width: 75 }}>
                        Átlag
                      </th>

                      <th className="align-middle">Összegek</th>
                      <th className="align-middle">Átlagok</th>

                      <th className="align-middle" style={{ width: 150 }}>
                        Műveletek
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.columns.map((column) => (
                      <tr key={column.column.id} style={{ height: 50 }}>
                        <td className="align-middle">{column.column.name}</td>
                        <td
                          className="align-middle"
                          style={{ textAlign: "center" }}
                        >
                          {column.column.groupable && (
                            <CheckBox
                              checked={column.group}
                              onChange={() =>
                                this.handleGroupChecked(column.column.id)
                              }
                              name={"group" + column.column.id}
                              label=""
                            />
                          )}
                        </td>
                        <td
                          className="align-middle"
                          style={{ textAlign: "center" }}
                        >
                          {column.column.summable && (
                            <CheckBox
                              checked={column.sum}
                              onChange={() =>
                                this.handleSumChecked(column.column.id)
                              }
                              name={"sum" + column.column.id}
                              label=""
                            />
                          )}
                        </td>
                        <td
                          className="align-middle"
                          style={{ textAlign: "center" }}
                        >
                          {column.column.avgable && (
                            <CheckBox
                              checked={column.avg}
                              onChange={() =>
                                this.handleAvgChecked(column.column.id)
                              }
                              name={"avg" + column.column.id}
                              label=""
                            />
                          )}
                        </td>
                        <td className="align-middle">
                          {column.group && (
                            <MultiSelect
                              name={"sums" + column.column.id}
                              labelKey="name"
                              filterBy={["name"]}
                              label=""
                              error=""
                              options={this.state.selectableSumColumns}
                              selected={column.sums}
                              onChange={(columns) =>
                                this.handleSumColumnsSelected(
                                  columns,
                                  column.column.id
                                )
                              }
                            />
                          )}
                        </td>
                        <td className="align-middle">
                          {column.group && (
                            <MultiSelect
                              name={"avgs" + column.column.id}
                              labelKey="name"
                              filterBy={["name"]}
                              label=""
                              error=""
                              options={this.state.selectableAvgColumns}
                              selected={column.avgs}
                              onChange={(columns) =>
                                this.handleAvgColumnsSelected(
                                  columns,
                                  column.column.id
                                )
                              }
                            />
                          )}
                        </td>
                        <td className="align-middle">
                          <button
                            disabled={this.isFirstColumn(column.column.id)}
                            onClick={(e) =>
                              this.handleColumnUp(e, column.column.id)
                            }
                            className="btn btn-primary"
                          >
                            <i className="fa fa-arrow-up" />
                          </button>
                          <button
                            disabled={this.isLastColumn(column.column.id)}
                            onClick={(e) =>
                              this.handleColumnDown(e, column.column.id)
                            }
                            className="btn btn-primary ml-2 mr-2"
                          >
                            <i className="fa fa-arrow-down" />
                          </button>
                          <button
                            onClick={(e) =>
                              this.handleColumnRemove(e, column.column.id)
                            }
                            className="btn btn-danger"
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {this.renderButton("Mentés")}
          {/* {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )} */}
        </form>
      </div>
    );
  }
}

export default ReportForm;
