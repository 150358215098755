import http from "./httpService";

export function upload(
  companyId: string,
  fuelCard: string,
  files: File[],
  tachographs?: object[]
) {
  const formData = new FormData();

  if (tachographs)
    formData.append("tachographsJSON", JSON.stringify(tachographs));

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("files[" + i + "]", file);
  }

  return http.post(`/api/${fuelCard}/upload?companyId=${companyId}`, formData);
}
