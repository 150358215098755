import httpService from "./httpService";
import ProductCategoryDto from "../models/productCategoryDto";
import SaveProductCategoryDto from "../models/saveProductCategoryDto";
import ProductDto from "../models/productDto";

const baseUrl = "/api/product-categories";

function getProductCategories() {
  return httpService.get<ProductCategoryDto[]>(baseUrl);
}

function getProductCategory(id: string) {
  return httpService.get<ProductCategoryDto>(`${baseUrl}/${id}`);
}

function addProductCategory(saveProductCategoryDto: SaveProductCategoryDto) {
  return httpService.post(baseUrl, saveProductCategoryDto);
}

function modifyProductCategory(
  id: string,
  saveProductCategoryDto: SaveProductCategoryDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveProductCategoryDto);
}

function removeProductCategory(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function getSelectableProducts(id: string) {
  return httpService.get<ProductDto[]>(`${baseUrl}/${id}/products`);
}

export default {
  getProductCategories,
  getProductCategory,
  addProductCategory,
  modifyProductCategory,
  removeProductCategory,
  getSelectableProducts
};
