import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import driverService from "../../services/driverService";
import companyService from "../../services/companyService";
import confirm from "../../utils/confirm";
import SaveDriverDto from "../../models/saveDriverDto";
import CompanyDto from "../../models/companyDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import DriverDto from "../../models/driverDto";
import { numberToString } from "../../utils/format";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";

interface State extends FormState<SaveDriverDto> {
  data: SaveDriverDto;
  companies: CompanyDto[];
}

class DriverForm extends Form<
  SaveDriverDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id ? `${this.state.data.name} - sofőr módosítása` : "Új sofőr";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: "",
      personName: "",
      companyId: ""
    },
    errors: {},
    companies: []
  };

  schema = yup.object().shape<SaveDriverDto>({
    name: yup
      .string()
      .required()
      .max(255),
    personName: yup
      .string()
      .required()
      .max(255),
    companyId: yup.string().required()
  });

  async componentDidMount() {
    await this.populateCompanies();
    if (this.id) await this.populateDriver();
  }

  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  async populateDriver() {
    try {
      const { data } = await driverService.getDriver(this.id);

      const driver = this.mapToViewModel(data);

      this.setState({ data: driver });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(driver: DriverDto): SaveDriverDto {
    return {
      name: driver.name,
      personName: driver.personName,
      companyId: numberToString(driver.companyId)
    };
  }

  doSubmit = async () => {
    try {
      if (this.id) await driverService.modifyDriver(this.id, this.state.data);
      else await driverService.addDriver(this.state.data);
      toast.success("Sofőr sikeresen mentve.");
      this.props.history.push(ROUTE.DRIVERS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a sofőrt?", async () => {
      try {
        await driverService.removeDriver(this.id);
        toast.success("Sofőr sikeresen törölve.");
        this.props.history.replace(ROUTE.DRIVERS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderInput("personName", <Required label="Személy név" />)}
          {this.renderSelect(
            "companyId",
            <Required label="Cég" />,
            this.state.companies,
            "nameOfTaxPlayer"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default DriverForm;
