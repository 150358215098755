import React from "react";
import UserPayload from "../../models/userPayload";
import { NavLink } from "react-router-dom";

interface Props {
  label: string;
  url: string;
  module?: number;
  user?: UserPayload;
  onClick?: () => void;
}

const MenuLink = ({ label, url, module, user, onClick }: Props) => {
  if (!module || !user || (module && user && user.hasModule(module))) {
    return (
      <NavLink
        className="dropdown-item"
        to={url}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        {label}
      </NavLink>
    );
  }
  return null;
};

export default MenuLink;
