import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import productService from "../../services/productService";
import countryService from "../../services/countryService";
import foreignTaxProductCategoryService from "../../services/foreignTaxProductCategoryService";
import ProductDto from "../../models/productDto";
import { RouteComponentProps } from "react-router";
import CountryDto from "../../models/countryDto";
import ForeignTaxProductCategoryDto from "../../models/foreignTaxProductCategoryDto";
import * as yup from "yup";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";

interface Data {
  products: number[];
}

interface State extends FormState<Data> {
  data: Data;
  products: ProductDto[];
  country: CountryDto;
  productCategory: ForeignTaxProductCategoryDto;
}

class ForeignTaxProductCategoryForm extends Form<
  Data,
  RouteComponentProps<{ countryId: string; productCategoryId: string }>,
  State
> {
  state: State = {
    data: {
      products: []
    },
    errors: {},
    products: [],
    country: {
      id: 0,
      name: "",
      code: "",
      currencyId: 0,
      currency: { id: 0, name: "" }
    },
    productCategory: { id: 0, code: "", name: "", order: "" }
  };

  schema = yup.object().shape<Data>({
    products: yup.array()
  });

  async componentDidMount() {
    await this.populateCountry();
    await this.populateProductCategory();
    await this.populateProducts();
  }

  async populateCountry() {
    const countryId = this.props.match.params.countryId;

    const { data: country } = await countryService.getCountry(countryId);

    this.setState({ country });
  }

  async populateProductCategory() {
    const productCategoryId = this.props.match.params.productCategoryId;

    const {
      data: productCategory
    } = await foreignTaxProductCategoryService.getForeignTaxProductCategory(
      productCategoryId
    );

    this.setState({ productCategory });
  }

  async populateProducts() {
    const countryId = this.props.match.params.countryId;
    const productCategoryId = this.props.match.params.productCategoryId;

    const { data: products } = await productService.getProducts();

    const { data } = await productService.getForeignTaxProductCategoryProducts(
      countryId,
      productCategoryId
    );
    const selectedProducts = data.map(product => product.id);

    this.setState({ products, data: { products: selectedProducts } });
  }

  doSubmit = async () => {
    try {
      const countryId = this.props.match.params.countryId;
      const productCategoryId = this.props.match.params.productCategoryId;

      await productService.modifyForeignTaxProductCategoryProducts(
        countryId,
        productCategoryId,
        this.state.data.products
      );
      toast.success("Sikeresen mentve.");
      this.props.history.push(
        `${ROUTE.FOREIGN_TAX_COUNTRIES}/${countryId}/product-categories`
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
      if (ex.response && ex.response.status === 400)
        toast.error(ex.response.data);
    }
  };

  render() {
    const title =
      this.state.country.name +
      " - " +
      this.state.productCategory.code +
      " " +
      this.state.productCategory.name +
      " - termékek";

    setDocumentTitle(title);

    return (
      <div>
        <h1>{title}</h1>

        <form onSubmit={this.handleSubmit}>
          {this.renderMultiSelect(
            "products",
            "Termékek",
            this.state.products,
            "name"
          )}
          {this.renderButton("Mentés")}
        </form>
      </div>
    );
  }
}

export default ForeignTaxProductCategoryForm;
