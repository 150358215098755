import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import companyService from "../../services/companyService";
import CompanyDto from "../../models/companyDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import DataGrid from "../common/dataGrid";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";

const columns: ColumnModel[] = [
  {
    field: "nameOfTaxPlayer",
    headerText: "Megnevezés",
  },
];

const Companies = ({ history }: RouteComponentProps) => {
  const [supplies, setCompanies] = useState<CompanyDto[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateCompanies = async () => {
      const { data } = await companyService.getCompanies();
      setCompanies(data);
    };

    populateCompanies();
  }, []);

  const handleModify = () => {
    if (!selectedCompany) return;
    history.push(`${ROUTE.COMPANIES}/${selectedCompany.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "cegek.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.COMPANIES);

  return (
    <>
      <h1>{MENU.COMPANIES}</h1>
      <NewLink to={`${ROUTE.COMPANIES}/new`} label="Új cég" />
      <ModifyButton
        disabled={!selectedCompany}
        onClick={handleModify}
        label="Cég módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="companies"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedCompany: CompanyDto) => {
          setSelectedCompany(selectedCompany);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Companies;
