import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import foreignTaxProductCategoryService from "../../services/foreignTaxProductCategoryService";
import countryService from "../../services/countryService";
import Table from "../common/table";
import _ from "lodash";
import ForeignTaxProductCategoryDto from "../../models/foreignTaxProductCategoryDto";
import CountryDto from "../../models/countryDto";
import SortColumn from "../../models/sortColumn";
import Column from "../../models/column";
import path from "../../utils/path";
import * as ROUTE from "../../constants/route";

interface State {
  foreignTaxProductCategories: ForeignTaxProductCategoryDto[];
  country: CountryDto;
  sortColumn: SortColumn;
}

class ForeignTaxProductCategories extends Component<
  RouteComponentProps<{ countryId: string }>,
  State
> {
  state: State = {
    foreignTaxProductCategories: [],
    country: {
      id: 0,
      name: "",
      code: "",
      currencyId: 0,
      currency: {
        id: 0,
        name: ""
      }
    },
    sortColumn: {
      path: path<ForeignTaxProductCategoryDto>()("order"),
      order: "asc"
    }
  };

  columns: Column<ForeignTaxProductCategoryDto>[] = [
    {
      path: path<ForeignTaxProductCategoryDto>()("name"),
      label: "Megnevezés",
      content: productCategory => (
        <Link
          to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.state.country.id}/product-categories/${productCategory.id}`}
        >
          {productCategory.code} {productCategory.name}
        </Link>
      )
    }
  ];

  handleSort = (sortColumn: SortColumn) => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const countryId = this.props.match.params.countryId;

    const { data: country } = await countryService.getCountry(countryId);

    const {
      data: foreignTaxProductCategories
    } = await foreignTaxProductCategoryService.getForeignTaxProductCategories(
      countryId
    );

    foreignTaxProductCategories.forEach(foreignTaxProductCategory => {
      const parts = foreignTaxProductCategory.code.split(".");
      let code = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length === 1) parts[i] = "0" + parts[i];
        code += parts[i] + ".";
      }
      foreignTaxProductCategory.order = code;
    });

    this.setState({ foreignTaxProductCategories, country });
  }

  render() {
    const { foreignTaxProductCategories, sortColumn, country } = this.state;

    document.title = country.name + " - termék kategóriák | ESZAF";

    const sortedData = _.orderBy(
      foreignTaxProductCategories,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <h1>{country.name} - termék kategóriák</h1>
        <Table
          columns={this.columns}
          data={sortedData}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </React.Fragment>
    );
  }
}

export default ForeignTaxProductCategories;
