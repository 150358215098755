import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import Required from "../common/required";
import transactionTypeService from "../../services/transactionTypeService";
import productService from "../../services/productService";
import confirm from "../../utils/confirm";
import SaveProductDto from "../../models/saveProductDto";
import { RouteComponentProps } from "react-router";
import TransactionTypeDto from "../../models/transactionTypeDto";
import * as yup from "yup";
import ProductDto from "../../models/productDto";
import { numberToString } from "../../utils/format";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";

interface State extends FormState<SaveProductDto> {
  data: SaveProductDto;
  transactionTypes: TransactionTypeDto[];
}

class ProductForm extends Form<
  SaveProductDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} - termék módosítása`
      : "Új termék";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: "",
      transactionTypeId: ""
    },
    errors: {},
    transactionTypes: []
  };

  schema = yup.object().shape<SaveProductDto>({
    name: yup
      .string()
      .required()
      .max(255),
    transactionTypeId: yup.string().required()
  });

  async componentDidMount() {
    await this.populateTransactionTypes();
    if (this.id) await this.populateProduct();
  }

  async populateTransactionTypes() {
    const {
      data: transactionTypes
    } = await transactionTypeService.getTransactionTypes();
    this.setState({ transactionTypes });
  }

  async populateProduct() {
    try {
      const { data } = await productService.getProduct(this.id);

      const product = this.mapToViewModel(data);

      this.setState({ data: product });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(product: ProductDto): SaveProductDto {
    return {
      name: product.name,
      transactionTypeId: numberToString(product.transactionTypeId)
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? productService.modifyProduct(this.id, this.state.data)
        : productService.addProduct(this.state.data));
      toast.success("Termék sikeresen mentve.");
      this.props.history.push(ROUTE.PRODUCTS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a terméket?", async () => {
      try {
        await productService.removeProduct(this.id);
        toast.success("Termék sikeresen törölve.");
        this.props.history.replace(ROUTE.PRODUCTS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderSelect(
            "transactionTypeId",
            <Required label="Szolgáltató" />,
            this.state.transactionTypes,
            "name"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default ProductForm;
