import httpService from "./httpService";
import CorrectiveNormDto from "../models/correctiveNormDto";
import SaveCorrectiveNormDto from "../models/saveCorrectiveNormDto";

const baseUrl = "/api/corrective-norms";

function getCorrectiveNorms() {
  return httpService.get<CorrectiveNormDto[]>(baseUrl);
}

function getCityCorrectiveNorms() {
  return httpService.get<CorrectiveNormDto[]>(`${baseUrl}/cities`);
}

function getCorrectiveNorm(id: string) {
  return httpService.get<CorrectiveNormDto>(`${baseUrl}/${id}`);
}

function modifyCorrectiveNorm(
  id: string,
  saveCorrectiveNormDto: SaveCorrectiveNormDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveCorrectiveNormDto);
}

function removeCorrectiveNorm(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getCorrectiveNorms,
  getCityCorrectiveNorms,
  getCorrectiveNorm,
  modifyCorrectiveNorm,
  removeCorrectiveNorm
};
