import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import { isAdminTenant } from "../../utils/tenant";
import useAuth from "../../hooks/useAuth";

const TenantsMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  return user.hasModule(MODULE.PARTNER) && isAdminTenant() ? (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.TENANTS} icon={ICON.BUILDING} />
      <div className="dropdown-menu">
        <MenuLink label={MENU.TENANTS} url={ROUTE.TENANTS} />
      </div>
    </div>
  ) : null;
};

export default TenantsMenu;
