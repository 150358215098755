import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";

import Form from "../form/form";
import TextField from "../form/textField";
import SelectField from "../form/selectField";
import SubmitButton from "../form/submitButton";
import RemoveButton from "../form/removeButton";

import userService from "../../services/userService";
import SaveUserDto from "../../models/saveUserDto";
import setDocumentTitle from "../../utils/document";
import { handleError } from "../../utils/serverError";
import { toast } from "react-toastify";
import * as ROUTE from "../../constants/route";
import TenantDto from "../../models/tenantDto";
import tenantService from "../../api/tenants";

const validationSchema = yup.object().shape<SaveUserDto>({
  name: yup.string().required().max(255),
  password: yup.string().required().max(255),
  tenantId: yup.string().required(),
});

const UserForm = (props: RouteComponentProps<{ id: string }>) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [initialValues, setInitialValues] = useState<SaveUserDto>({
    name: "",
    password: "",
    tenantId: "",
  });
  const [tenants, setTenants] = useState<TenantDto[]>([]);
  const [title, setTitle] = useState("Új felhasználó");

  useEffect(() => {
    const populateTenants = async () => {
      const { data, ok, originalError } = await tenantService.getTenants();
      if (!ok) handleError(props, originalError);
      if (data) setTenants(data);
    };
    populateTenants();

    if (id === "new") return;

    const populateUser = async () => {
      try {
        const { data } = await userService.getUser(id);
        setInitialValues({
          name: data.name,
          password: "",
          tenantId: data.tenantId.toString(),
        });
      } catch (e) {
        handleError(props, e);
      }
    };

    populateUser();
  }, [id, props]);

  useEffect(() => {
    if (id === "new") return;
    setTitle(`${initialValues.name} módosítása`);
  }, [id, initialValues.name]);

  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);

  const handleOnSubmit = async (values: SaveUserDto) => {
    try {
      await (id === "new"
        ? userService.addUser(values)
        : userService.modifyUser(id, values));
      toast.success("Felhasználó sikeresen mentve.");
      props.history.push(ROUTE.USERS);
    } catch (e) {
      handleError(props, e);
    }
  };

  const handleRemove = async () => {
    try {
      await userService.removeUser(id);
      toast.success("Felhasználó sikeresen törölve.");
      props.history.replace(ROUTE.USERS);
    } catch (e) {
      handleError(props, e);
    }
  };

  return (
    <>
      <h1>{title}</h1>
      <Form
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        <TextField name="name" label="Megnevezés" required />
        <TextField name="password" label="Jelszó" required type="password" />
        <SelectField
          name="tenantId"
          label="Partner"
          labelKey="name"
          valueKey="id"
          options={tenants}
        />
        <SubmitButton />
        {id !== "new" && <RemoveButton onRemove={handleRemove} />}
      </Form>
    </>
  );
};

export default UserForm;
