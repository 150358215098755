import { useContext } from "react";
import AuthContext from "../auth/context";
import authStorage from "../auth/storage";

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const logIn = (authToken: string) => {
    authStorage.storeToken(authToken);
    setUser(authStorage.getUser());
  };

  const logOut = () => {
    authStorage.removeToken();
    setUser(null);
  };

  return { user, setUser, logIn, logOut };
};

export default useAuth;
