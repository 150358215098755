import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import placeService from "../../services/placeService";
import confirm from "../../utils/confirm";
import SavePlaceDto from "../../models/savePlaceDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import PlaceDto from "../../models/placeDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";

interface State extends FormState<SavePlaceDto> {
  data: SavePlaceDto;
}

class PlaceForm extends Form<
  SavePlaceDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} telephely módosítása`
      : "Új telephely";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  state: State = {
    data: {
      name: ""
    },
    errors: {}
  };

  schema = yup.object().shape<SavePlaceDto>({
    name: yup
      .string()
      .required()
      .max(255)
  });

  async componentDidMount() {
    if (this.id) await this.populatePlace();
  }

  async populatePlace() {
    try {
      const { data } = await placeService.getPlace(this.id);
      const place = this.mapToViewModel(data);
      this.setState({ data: place });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(place: PlaceDto): SavePlaceDto {
    return {
      name: place.name
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? placeService.modifyPlace(this.id, this.state.data)
        : placeService.addPlace(this.state.data));
      toast.success("Telephely sikeresen mentve.");
      this.props.history.push(ROUTE.PLACES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a telephelyet?", async () => {
      try {
        await placeService.removePlace(this.id);
        toast.success("Telephely sikeresen törölve.");
        this.props.history.replace(ROUTE.PLACES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default PlaceForm;
