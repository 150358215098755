import httpService from "./httpService";
import DriverDto from "../models/driverDto";
import SaveDriverDto from "../models/saveDriverDto";

const baseUrl = "/api/drivers";

function getDrivers() {
  return httpService.get<DriverDto[]>(baseUrl);
}

function getDriver(id: string) {
  return httpService.get<DriverDto>(`${baseUrl}/${id}`);
}

function addDriver(saveDriverDto: SaveDriverDto) {
  return httpService.post(baseUrl, saveDriverDto);
}

function modifyDriver(id: string, saveDriverDto: SaveDriverDto) {
  return httpService.put(`${baseUrl}/${id}`, saveDriverDto);
}

function removeDriver(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getDrivers,
  getDriver,
  addDriver,
  modifyDriver,
  removeDriver
};
