import httpService from "./httpService";
import CommercialGasRefundDto from "../models/commercialGasRefundDto";
import TransactionDto from "../models/transactionDto";
import SaveCommercialGasRefundDto from "../models/saveCommercialGasRefundDto";
const baseUrl = "/api/commercial-gas-refunds";

function getCommercialGasRefunds() {
  return httpService.get<CommercialGasRefundDto[]>(baseUrl);
}

function getCommercialGasRefund(id: string) {
  return httpService.get<CommercialGasRefundDto>(`${baseUrl}/${id}`);
}

function getCommercialGasRefundView(id: string) {
  return httpService.get<TransactionDto[]>(`${baseUrl}/${id}/view`);
}

function exportCommercialGasRefund(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/export`, {
    responseType: "blob",
  });
}

function addCommercialGasRefund(
  saveCommercialGasRefund: SaveCommercialGasRefundDto
) {
  return httpService.post(baseUrl, saveCommercialGasRefund);
}

function modifyCommercialGasRefund(
  id: string,
  saveCommercialGasRefund: SaveCommercialGasRefundDto
) {
  return httpService.put(baseUrl + "/" + id, saveCommercialGasRefund);
}

function removeCommercialGasRefund(id: string) {
  return httpService.delete(baseUrl + "/" + id);
}

export default {
  getCommercialGasRefunds,
  getCommercialGasRefund,
  exportCommercialGasRefund,
  addCommercialGasRefund,
  modifyCommercialGasRefund,
  removeCommercialGasRefund,
  getCommercialGasRefundView,
};
