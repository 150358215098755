import React, { Component } from "react";
import { Link } from "react-router-dom";
import correctiveNormService from "../../services/correctiveNormService";
import Table from "../common/table";
import _ from "lodash";
import CorrectiveNormDto from "../../models/correctiveNormDto";
import SortColumn from "../../models/sortColumn";
import Column from "../../models/column";
import path from "../../utils/path";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";

interface State {
  correctiveNorms: CorrectiveNormDto[];
  sortColumn: SortColumn;
}

class CorrectiveNorms extends Component {
  state: State = {
    correctiveNorms: [],
    sortColumn: { path: path<CorrectiveNormDto>()("name"), order: "asc" }
  };

  columns: Column<CorrectiveNormDto>[] = [
    {
      path: path<CorrectiveNormDto>()("name"),
      label: "Megnevezés",
      content: correctiveNorm => (
        <Link to={`${ROUTE.CORRECTIVE_NORMS}/${correctiveNorm.id}`}>
          {correctiveNorm.name}
        </Link>
      )
    },
    {
      path: path<CorrectiveNormDto>()("percent"),
      label: "Százalék"
    }
  ];

  handleSort = (sortColumn: SortColumn) => this.setState({ sortColumn });

  async componentDidMount() {
    const {
      data: correctiveNorms
    } = await correctiveNormService.getCorrectiveNorms();
    this.setState({ correctiveNorms });
  }

  render() {
    setDocumentTitle(MENU.CORRECTIVE_NORMS);

    const { correctiveNorms, sortColumn } = this.state;

    const sortedCorrectiveNorms = _.orderBy(
      correctiveNorms,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <h1>{MENU.CORRECTIVE_NORMS}</h1>
        <Table
          columns={this.columns}
          data={sortedCorrectiveNorms}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </React.Fragment>
    );
  }
}

export default CorrectiveNorms;
