/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import MenuHeader from "../common/menuHeader";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import useAuth from "../../hooks/useAuth";

const UserMenu = () => {
  const { user, logOut } = useAuth();
  if (!user) return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={user.Name} icon={ICON.USER} />
      <div className="dropdown-menu">
        <a className="dropdown-item" href="#" onClick={() => logOut()}>
          {MENU.LOGOUT}
        </a>
      </div>
    </div>
  );
};

export default UserMenu;
