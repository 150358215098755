import http from "./httpService";
import CurrencyDto from "../models/currencyDto";

const baseUrl = "/api/currencies";

function getCurrencies() {
  return http.get<CurrencyDto[]>(baseUrl);
}

export default {
  getCurrencies
};
