import client from "./client";
import TenantDto from "../models/tenantDto";
import SaveTenantDto from "../models/saveTenantDto";

const endpoint = "/tenants";

const getTenants = () => {
  return client.get<TenantDto[]>(endpoint);
};

const getTenant = (id: string) => {
  return client.get<TenantDto>(`${endpoint}/${id}`);
};

const removeTenant = (id: string) => {
  return client.delete(`${endpoint}/${id}`);
};

const addTenant = (tenant: SaveTenantDto) => {
  return client.post(`${endpoint}`, tenant);
};

const modifyTenant = (id: string, tenant: SaveTenantDto) => {
  return client.put(`${endpoint}/${id}`, tenant);
};

export default {
  getTenants,
  getTenant,
  removeTenant,
  addTenant,
  modifyTenant,
};
