import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import productService from "../../services/productService";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import ProductDto from "../../models/productDto";
import CheckBox from "../common/checkBox";
import {
  ColumnModel,
  GridComponent,
  QueryCellInfoEventArgs,
} from "@syncfusion/ej2-react-grids";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";
import NewLink from "../common/newLink";
import ReactDOM from "react-dom";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
  {
    field: "transactionType.name",
    headerText: "Szolgáltató",
  },
  {
    field: "commercialGasRefund",
    headerText: "Kereskedelmi gázolaj",
    type: "boolean",
  },
  {
    field: "deliveryNote",
    headerText: "Menetlevél",
    type: "boolean",
  },
  {
    field: "energyDiesel",
    headerText: "Energetika gázolaj",
    type: "boolean",
  },
  {
    field: "energyPetrol",
    headerText: "Energetika benzin",
    type: "boolean",
  },
];

const Products = ({ history }: RouteComponentProps) => {
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateProducts = async () => {
      const { data } = await productService.getProducts();
      setProducts(data);
    };

    populateProducts();
  }, []);

  const handleModify = () => {
    if (!selectedProduct || !selectedProduct.manual) return;
    history.push(`${ROUTE.PRODUCTS}/${selectedProduct.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "termekek.xlsx" });
  };

  const handleCommercialGasRefundChange = (product: ProductDto) =>
    productService.modifyProductCommercialGasRefund(
      product.id,
      product.commercialGasRefund
    );

  const handleDeliveryNoteChange = (product: ProductDto) =>
    productService.modifyProductDeliveryNote(product.id, product.deliveryNote);

  const handleEnergyDieselChange = (product: ProductDto) =>
    productService.modifyProductEnergyDielsel(product.id, product.energyDiesel);

  const handleEnergyPetrolChange = (product: ProductDto) =>
    productService.modifyProductEnergyPetrol(product.id, product.energyPetrol);

  if (!products) return <>Betöltés...</>;

  setDocumentTitle(MENU.PRODUCTS);

  return (
    <>
      <h1>{MENU.PRODUCTS}</h1>
      <NewLink to={`${ROUTE.PRODUCTS}/new`} label="Új termék" />
      <ModifyButton
        disabled={!selectedProduct || !selectedProduct.manual}
        onClick={handleModify}
        label="Termék módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="products"
        columns={columns}
        data={products}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedProduct: ProductDto) => {
          setSelectedProduct(selectedProduct);
        }}
        recordDoubleClick={handleModify}
        queryCellInfo={(args: QueryCellInfoEventArgs) => {
          const product = args.data as ProductDto;
          if (args.column?.field === "commercialGasRefund") {
            ReactDOM.render(
              <CheckBox
                name={`commercialGasRefund${product.id}`}
                label=""
                checked={product.commercialGasRefund}
                onChange={() => {
                  product.commercialGasRefund = !product.commercialGasRefund;
                  handleCommercialGasRefundChange(product);
                  grid.current?.refresh();
                }}
              />,
              args.cell as Element
            );
          }
          if (args.column?.field === "deliveryNote") {
            ReactDOM.render(
              <CheckBox
                name={`deliveryNote${product.id}`}
                label=""
                checked={product.deliveryNote}
                onChange={() => {
                  product.deliveryNote = !product.deliveryNote;
                  handleDeliveryNoteChange(product);
                  grid.current?.refresh();
                }}
              />,
              args.cell as Element
            );
          }
          if (args.column?.field === "energyDiesel") {
            ReactDOM.render(
              <CheckBox
                name={`energyDiesel${product.id}`}
                label=""
                checked={product.energyDiesel}
                onChange={() => {
                  product.energyDiesel = !product.energyDiesel;
                  handleEnergyDieselChange(product);
                  grid.current?.refresh();
                }}
              />,
              args.cell as Element
            );
          }
          if (args.column?.field === "energyPetrol") {
            ReactDOM.render(
              <CheckBox
                name={`energyPetrol${product.id}`}
                label=""
                checked={product.energyPetrol}
                onChange={() => {
                  product.energyPetrol = !product.energyPetrol;
                  handleEnergyPetrolChange(product);
                  grid.current?.refresh();
                }}
              />,
              args.cell as Element
            );
          }
        }}
      />
    </>
  );
};

export default Products;
