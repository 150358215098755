import React from "react";
import { Formik, FormikValues } from "formik";
import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "Kötelező kitölteni.",
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: "Minimum ${min} karakter hosszúnak kell lennie.",
    // eslint-disable-next-line no-template-curly-in-string
    max: "Maximum ${max} karakter hosszúnak kell lennie.",
  },
});

interface Props<T extends FormikValues> {
  initialValues: T;
  onSubmit: (values: T) => void;
  validationSchema: any;
  children: any;
}

const Form = <T extends FormikValues>({
  initialValues,
  onSubmit,
  validationSchema,
  children,
}: Props<T>) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
};

export default Form;
