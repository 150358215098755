import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";

import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import UserDto from "../../models/userDto";
import UserService from "../../services/userService";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
  {
    field: "tenant.name",
    headerText: "Partner",
  },
];

const Users = ({ history }: RouteComponentProps) => {
  const [Users, setUsers] = useState<UserDto[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    setDocumentTitle(MENU.USERS);

    const populateUsers = async () => {
      const { data } = await UserService.getUsers();
      setUsers(data);
    };

    populateUsers();
  }, []);

  const handleExcelExport = () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "felhasznalok.xlsx" });
  };

  const handleModify = () => {
    if (!selectedUser) return;
    history.push(`${ROUTE.USERS}/${selectedUser.id}`);
  };

  return (
    <>
      <h1>{MENU.USERS}</h1>
      <NewLink to={`${ROUTE.USERS}/new`} label="Új felhasználó" />
      <ModifyButton
        disabled={!selectedUser}
        onClick={handleModify}
        label="Felhasználó módosítása"
      />
      <ExcelExportButton onClick={handleExcelExport} />
      <DataGrid
        id="users"
        columns={columns}
        data={Users}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedUser: UserDto) => {
          setSelectedUser(selectedUser);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Users;
