import { AxiosError } from "axios";
import { RouteComponentProps } from "react-router";
import * as ROUTE from "../constants/route";
import { toast } from "react-toastify";
import errorConverter from "./validation";

export function handleError(props: RouteComponentProps, ex: AxiosError | null) {
  if (!ex) return;

  if (ex.response && ex.response.status === 404)
    props.history.replace(ROUTE.NOT_FOUND);
  if (ex.response && ex.response.status === 400)
    toast.error(errorConverter(ex.response.data));
}
