import React from "react";
import Form, { FormState } from "../common/form";
import correctiveNormService from "../../services/correctiveNormService";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import SaveCorrectiveNormDto from "../../models/saveCorrectiveNormDto";
import { RouteComponentProps } from "react-router";
import CorrectiveNormDto from "../../models/correctiveNormDto";
import { numberToString } from "../../utils/format";

interface State extends FormState<SaveCorrectiveNormDto> {
  data: SaveCorrectiveNormDto;
}

class CorrectiveNormForm extends Form<
  SaveCorrectiveNormDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return `${this.state.data.name} - korrekciós norma módosítása`;
  }
  get id() {
    return this.props.match.params.id;
  }

  state = {
    data: {
      name: "",
      percent: ""
    },
    errors: {}
  };

  schema = yup.object().shape<SaveCorrectiveNormDto>({
    name: yup
      .string()
      .required()
      .max(255),
    percent: yup.string()
  });

  async componentDidMount() {
    if (this.id) await this.populateCorrectiveNorm();
  }

  async populateCorrectiveNorm() {
    try {
      const { data } = await correctiveNormService.getCorrectiveNorm(this.id);
      const correctiveNorm = this.mapToViewModel(data);
      this.setState({ data: correctiveNorm });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(correctiveNorm: CorrectiveNormDto): SaveCorrectiveNormDto {
    return {
      name: correctiveNorm.name,
      percent: numberToString(correctiveNorm.percent)
    };
  }

  doSubmit = async () => {
    try {
      await correctiveNormService.modifyCorrectiveNorm(
        this.id,
        this.state.data
      );

      this.props.history.push(ROUTE.CORRECTIVE_NORMS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("percent", "Százalék", "number")}
          {this.renderButton("Mentés")}
        </form>
      </div>
    );
  }
}

export default CorrectiveNormForm;
