import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import supplyService from '../../services/supplyService';
import { toast } from 'react-toastify';
import SupplyDto from '../../models/supplyDto';
import path from '../../utils/path';
import {
  formatDate,
  formatNumber,
  INT,
  JSON_DATE,
  YYYY_MM_DD,
  YYYY_MM_DD_HH_mm_ss,
} from '../../utils/format';
import setDocumentTitle from '../../utils/document';
import * as ROUTE from '../../constants/route';
import * as MENU from '../../constants/menu';
import {
  GridComponent,
  ColumnModel,
  AggregateColumnModel,
} from '@syncfusion/ej2-react-grids';
import NewLink from '../common/newLink';
import ModifyButton from '../common/modifyButton';
import ExcelExportButton from '../common/excelExportButton';
import DataGrid from '../common/dataGrid';
import DistributionButton from '../common/distributionButton';
import Loading from '../common/loading';
import Form from '../form/form';
import SelectField from '../form/selectField';
import * as yup from 'yup';
import CompanyDto from '../../models/companyDto';
import companyService from '../../services/companyService';

const validationSchema = yup.object({
  companyId: yup.string().required(),
});

const aggregates: AggregateColumnModel[] = [
  {
    field: 'quantity',
    type: 'Sum',
    footerTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, INT)}</span>;
    },
    groupFooterTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, INT)}</span>;
    },
  } as any,
  {
    field: 'grossTotal',
    type: 'Sum',
    footerTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, INT)}</span>;
    },
    groupFooterTemplate: (props: any) => {
      return <span>{formatNumber(props.Sum, INT)}</span>;
    },
  } as any,
];

const columns: ColumnModel[] = [
  {
    field: 'invoiceNumber',
    headerText: 'Számlaszám',
  },
  {
    field: 'sumSupply.invoiceNumber',
    headerText: 'Gyűjtő számla',
  },
  {
    field: 'sumInvoice',
    headerText: 'Gyűjtő',
  },
  {
    field: 'subInvoice',
    headerText: 'Al',
  },
  {
    field: 'date',
    headerText: 'Teljesítés kelte',
    valueAccessor: (_, data: Object) =>
      formatDate((data as SupplyDto).date, JSON_DATE, YYYY_MM_DD_HH_mm_ss),
  },
  {
    field: 'dateOfInvoice',
    headerText: 'Számla kiállításának napja',
    valueAccessor: (_, data: Object) =>
      formatDate((data as SupplyDto).dateOfInvoice, JSON_DATE, YYYY_MM_DD),
  },
  {
    field: 'tank.name',
    headerText: 'Tartály',
  },
  {
    field: 'company.nameOfTaxPlayer',
    headerText: 'Cég',
  },
  {
    field: 'quantity',
    headerText: 'Mennyiség',
    type: 'number',
    textAlign: 'Right',
    format: 'N0',
  },
  {
    field: 'grossTotal',
    headerText: 'Összeg',
    type: 'number',
    textAlign: 'Right',
    format: 'N0',
  },
  {
    field: 'startDateTime',
    headerText: 'Kezdő dátum',
    valueAccessor: (_, data: Object) =>
      formatDate(
        (data as SupplyDto).startDateTime,
        JSON_DATE,
        YYYY_MM_DD_HH_mm_ss
      ),
  },
  {
    field: path<SupplyDto>()('startQuantity'),
    headerText: 'Kezdő mennyiség',
    type: 'number',
    textAlign: 'Right',
    format: 'N0',
  },
];

const Supplies = ({ history }: RouteComponentProps) => {
  const [supplies, setSupplies] = useState<SupplyDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState<SupplyDto | null>(null);
  const grid = useRef<GridComponent | null>(null);
  const [companies, setCompanies] = useState<CompanyDto[]>([]);
  const [initialValues] = useState({
    companyId: '',
  });

  useEffect(() => {
    const populateCompanies = async () => {
      const { data } = await companyService.getCompanies();
      if (data) setCompanies(data);
    };
    populateCompanies();
  }, []);

  useEffect(() => {
    const populateSupplies = async () => {
      const { data } = await supplyService.getSupplies();
      setSupplies(data);
    };

    populateSupplies();
  }, []);

  const handleModify = () => {
    if (!selectedSupply) return;
    history.push(`${ROUTE.SUPPLIES}/${selectedSupply.id}`);
  };

  const handleDistribution = async (data: { companyId: string }) => {
    setLoading(true);
    try {
      await supplyService.distribution(data.companyId);
      toast.success('Szétosztás megtörtént.');
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error(ex.response.data);
    }
    setLoading(false);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: 'beszallitasok.xlsx' });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.SUPPLIES);

  return (
    <>
      <h1>{MENU.SUPPLIES}</h1>
      <NewLink to={`${ROUTE.SUPPLIES}/new`} label="Új beszállítás" />
      <ModifyButton
        disabled={!selectedSupply}
        onClick={handleModify}
        label="Beszállítás módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <br />
      <Form
        initialValues={initialValues}
        onSubmit={handleDistribution}
        validationSchema={validationSchema}
      >
        <div className="row">
          <div className="col">
            <SelectField
              name="companyId"
              label={'Cég'}
              labelKey="nameOfTaxPlayer"
              valueKey="id"
              options={companies}
            />
          </div>
          <div className="col">
            <br />
            <DistributionButton />
            <Loading loading={loading} />
          </div>
        </div>
      </Form>

      <DataGrid
        id="supplies2"
        columns={columns}
        aggregates={aggregates}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedSupply: SupplyDto) => {
          setSelectedSupply(selectedSupply);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Supplies;
