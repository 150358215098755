import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";

import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import TenantDto from "../../models/tenantDto";
import tenantService from "../../api/tenants";
import { handleError } from "../../utils/serverError";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
  {
    field: "host",
    headerText: "Url",
    template:
      '<a href="' +
      window.location.protocol +
      // eslint-disable-next-line no-template-curly-in-string
      "//${host}.eszaf.hu:" +
      window.location.port +
      // eslint-disable-next-line no-template-curly-in-string
      '" target="_blank">${host}.eszaf.hu</a>',
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      `${(data as TenantDto).host}.eszaf.hu`,
  },
  {
    field: "modules",
    headerText: "Modulok",
    valueAccessor: (_field: string, data: Object, _column: ColumnModel) =>
      (data as TenantDto).modules.map((module) => module.name).join(", "),
  },
];

const Tenants = (props: RouteComponentProps) => {
  const [tenants, setTenants] = useState<TenantDto[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<TenantDto | null>(null);
  const grid = useRef<GridComponent>();

  useEffect(() => {
    const populateTenants = async () => {
      const { data, ok, originalError } = await tenantService.getTenants();
      if (!ok) return handleError(props, originalError);
      if (data) setTenants(data);
    };

    populateTenants();
  }, [props]);

  const handleExcelExport = () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "partnerek.xlsx" });
  };

  const handleModify = () => {
    if (!selectedTenant) return;
    props.history.push(`${ROUTE.TENANTS}/${selectedTenant.id}`);
  };

  if (!tenants) return <>Betöltés...</>;

  setDocumentTitle(MENU.TENANTS);

  return (
    <>
      <h1>{MENU.TENANTS}</h1>
      <NewLink to={`${ROUTE.TENANTS}/new`} label="Új partner" />
      <ModifyButton
        disabled={!selectedTenant}
        onClick={handleModify}
        label="Partner módosítása"
      />
      <ExcelExportButton onClick={handleExcelExport} />
      <DataGrid
        id="tenants"
        columns={columns}
        data={tenants}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedTenant: TenantDto) => {
          setSelectedTenant(selectedTenant);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Tenants;
