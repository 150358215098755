import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import vehicleCategoryService from "../../services/vehicleCategoryService";
import VehicleCategoryDto from "../../models/vehicleCategoryDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
];

const VehicleCategories = ({ history }: RouteComponentProps) => {
  const [supplies, setVehicleCategories] = useState<VehicleCategoryDto[]>([]);
  const [
    selectedVehicleCategory,
    setSelectedVehicleCategory,
  ] = useState<VehicleCategoryDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateVehicleCategories = async () => {
      const { data } = await vehicleCategoryService.getVehicleCategories();
      setVehicleCategories(data);
    };

    populateVehicleCategories();
  }, []);

  const handleModify = () => {
    if (!selectedVehicleCategory) return;
    history.push(`${ROUTE.VEHICLE_CATEGORIES}/${selectedVehicleCategory.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "jarmu-kategoriak.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.VEHICLE_CATEGORIES);

  return (
    <>
      <h1>{MENU.VEHICLE_CATEGORIES}</h1>
      <NewLink to={`${ROUTE.VEHICLE_CATEGORIES}/new`} label="Új kategória" />
      <ModifyButton
        disabled={!selectedVehicleCategory}
        onClick={handleModify}
        label="Kategória módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="vehicleCategories"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedVehicleCategory: VehicleCategoryDto) => {
          setSelectedVehicleCategory(selectedVehicleCategory);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default VehicleCategories;
