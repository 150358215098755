import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import vehicleService from "../../services/vehicleService";
import commercialGasVehicleCategoryService from "../../services/commercialGasVehicleCategoryService";
import FileInput from "../common/fileInput";
import confirm from "../../utils/confirm";
import SaveVehicleDto from "../../models/saveVehicleDto";
import { RouteComponentProps } from "react-router";
import CommercialGasVehicleCategoryDto from "../../models/commercialGasVehicleCategoryDto";
import * as yup from "yup";
import VehicleDto from "../../models/vehicleDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import {
  YYYY_MM_DD,
  formatDate,
  numberToString,
  JSON_DATE,
} from "../../utils/format";

interface State extends FormState<SaveVehicleDto> {
  data: SaveVehicleDto;
  trafficLicenseFileName: string;
  rentalContractFileName: string;
  leasingContractFileName: string;
  licensePlate: string;
  commercialGasVehicleCategories: CommercialGasVehicleCategoryDto[];
}

class VehicleForm extends Form<
  SaveVehicleDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  id = this.props.match.params.id;
  state: State = {
    data: {
      basicNorm: "",
      coolingNorm: "",
      weight: "",
      refundFrom: "",
      refundTo: "",
      commercialGasVehicleCategoryId: "",
      vehicleOwnerType: "",
      vehicleType: "",
      engineCapacity: "",
    },
    trafficLicenseFileName: "",
    rentalContractFileName: "",
    leasingContractFileName: "",
    licensePlate: "",
    errors: {},
    commercialGasVehicleCategories: [],
  };

  schema = yup.object().shape<SaveVehicleDto>({
    basicNorm: yup.string(),
    coolingNorm: yup.string(),
    weight: yup.string(),
    refundFrom: yup.string().date(YYYY_MM_DD),
    refundTo: yup.string().date(YYYY_MM_DD),
    commercialGasVehicleCategoryId: yup.string(),
    vehicleOwnerType: yup.string(),
    vehicleType: yup.string(),
    engineCapacity: yup.string(),
  });

  async componentDidMount() {
    await this.populateCommercialGasVehicleCategories();
    await this.populateVehicle();
  }

  async populateCommercialGasVehicleCategories() {
    const {
      data: commercialGasVehicleCategories,
    } = await commercialGasVehicleCategoryService.getCommercialGasVehicleCategories();
    this.setState({ commercialGasVehicleCategories });
  }

  async populateVehicle() {
    try {
      const { data } = await vehicleService.getVehicle(this.id);

      const vehicle = this.mapToViewModel(data);

      this.setState({
        data: vehicle,
        licensePlate: data.licensePlate,
        trafficLicenseFileName: data.trafficLicenseFileName,
        rentalContractFileName: data.rentalContractFileName,
        leasingContractFileName: data.leasingContractFileName,
      });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(vehicle: VehicleDto): SaveVehicleDto {
    return {
      basicNorm: numberToString(vehicle.basicNorm),
      coolingNorm: numberToString(vehicle.coolingNorm),
      weight: numberToString(vehicle.weight),
      refundFrom: formatDate(vehicle.refundFrom, JSON_DATE, YYYY_MM_DD),
      refundTo: formatDate(vehicle.refundTo, JSON_DATE, YYYY_MM_DD),
      commercialGasVehicleCategoryId: numberToString(
        vehicle.commercialGasVehicleCategoryId
      ),
      vehicleOwnerType: numberToString(vehicle.vehicleOwnerType),
      vehicleType: numberToString(vehicle.vehicleType),
      engineCapacity: numberToString(vehicle.engineCapacity),
    };
  }

  doSubmit = async () => {
    try {
      await vehicleService.modifyVehicle(this.id, this.state.data);
      this.props.history.push(ROUTE.VEHICLES);
      toast.success("Jármű sikeresen mentve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleTrafficLicenseUpload = async ({
    currentTarget: input,
  }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (input.files === null) return;
      const trafficLicenseFile = input.files[0];
      const { data } = await vehicleService.uploadTrafficLicense(
        this.id,
        trafficLicenseFile
      );
      this.setState({ trafficLicenseFileName: data });
      toast.success("Forgalmi engedély sikeresen felöltve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };
  handleTrafficLicenseRemove = async () => {
    confirm("Biztos törölni szeretné a forgalmi engedélyt?", async () => {
      await vehicleService.removeTrafficLicense(this.id);
      this.setState({ trafficLicenseFileName: "" });
      toast.success("Forgalmi engedély sikeresen törölve.");
    });
  };
  handleTrafficLicenseDownload = () => {
    vehicleService.downloadTrafficLicense(this.id);
  };

  handleRentalContractUpload = async ({
    currentTarget: input,
  }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (input.files === null) return;
      const rentalContractFile = input.files[0];
      const { data } = await vehicleService.uploadRentalContract(
        this.id,
        rentalContractFile
      );
      this.setState({ rentalContractFileName: data });
      toast.success("Bérleti szerződés sikeresen felöltve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };
  handleRentalContractRemove = async () => {
    confirm("Biztos törölni szeretné a bérleti szerződést?", async () => {
      await vehicleService.removeRentalContract(this.id);
      this.setState({ rentalContractFileName: "" });
      toast.success("Bérleti szerződés sikeresen törölve.");
    });
  };
  handleRentalContractDownload = () => {
    vehicleService.downloadRentalContract(this.id);
  };

  handleLeasingContractUpload = async ({
    currentTarget: input,
  }: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (input.files === null) return;
      const leasingContractFile = input.files[0];
      const { data } = await vehicleService.uploadLeasingContract(
        this.id,
        leasingContractFile
      );
      this.setState({ leasingContractFileName: data });
      toast.success("Lízing szerződés sikeresen felöltve.");
    } catch (ex) {
      this.handleError(ex);
    }
  };
  handleLeasingContractRemove = async () => {
    confirm("Biztos törölni szeretné a lízing szerződést?", async () => {
      await vehicleService.removeLeasingContract(this.id);
      this.setState({ leasingContractFileName: "" });
      toast.success("Lízing szerződés sikeresen törölve.");
    });
  };
  handleLeasingContractDownload = () => {
    vehicleService.downloadLeasingContract(this.id);
  };

  render() {
    const title = `${this.state.licensePlate} jármű módosítása`;

    setDocumentTitle(title);

    return (
      <div>
        <h1>{title}</h1>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#masterdata"
              role="tab"
            >
              Törzsadatok
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#uploads"
              role="tab"
            >
              Dokumentumok
            </a>
          </li>
        </ul>
        <br />
        <div className="tab-content">
          {this.renderMasterData()}
          {this.renderUploads()}
        </div>
      </div>
    );
  }

  renderMasterData = () => (
    <div className="tab-pane fade show active" id="masterdata" role="tabpanel">
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-sm-6">
            {this.renderInput("weight", "Súly (kg)", "number")}
          </div>
          <div className="col-sm-6">
            {this.renderInput(
              "engineCapacity",
              "Hengerűrtartalom (cm3)",
              "number"
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {this.renderInput("basicNorm", "Alap norma (liter)", "number")}
          </div>
          <div className="col-sm-6">
            {this.renderInput("coolingNorm", "Hűtési norma (liter)", "number")}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {this.renderInput("refundFrom", "Bejelentés dátuma")}
          </div>
          <div className="col-sm-6">
            {this.renderInput("refundTo", "Kijelentés dátuma")}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            {this.renderSelect(
              "vehicleOwnerType",
              "Tulajdonosi viszony",
              [
                { id: 1, name: "Saját" },
                { id: 2, name: "Bérelt" },
                { id: 3, name: "Lízingelt" },
              ],
              "name"
            )}
          </div>
          <div className="col-sm-6">
            {this.renderSelect(
              "vehicleType",
              "Típus",
              [
                { id: 1, name: "Személygépjármű" },
                { id: 2, name: "Tehergépgjármű" },
              ],
              "name"
            )}
          </div>
        </div>
        {this.renderSelect(
          "commercialGasVehicleCategoryId",
          "Kereskedelmi gázolaj jármű kategória",
          this.state.commercialGasVehicleCategories,
          "name"
        )}
        {this.renderButton("Mentés")}
      </form>
    </div>
  );

  renderUploads = () => (
    <div className="tab-pane fade show" id="uploads" role="tabpanel">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Dokumentum</th>
            <th style={{ width: 150 }}>Műveletek</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Forgalmi engedély</td>
            <td>
              {!this.state.trafficLicenseFileName ? (
                <FileInput
                  name="traffic-license"
                  multiple={false}
                  label={<i className="fa fa-upload" />}
                  onChange={this.handleTrafficLicenseUpload}
                />
              ) : (
                <React.Fragment>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.handleTrafficLicenseDownload}
                  >
                    <i className="fa fa-download" />
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={this.handleTrafficLicenseRemove}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </React.Fragment>
              )}
            </td>
          </tr>
          <tr>
            <td>Bérleti szerződés</td>
            <td>
              {!this.state.rentalContractFileName ? (
                <FileInput
                  name="traffic-license"
                  multiple={false}
                  label={<i className="fa fa-upload" />}
                  onChange={this.handleRentalContractUpload}
                />
              ) : (
                <React.Fragment>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.handleRentalContractDownload}
                  >
                    <i className="fa fa-download" />
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={this.handleRentalContractRemove}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </React.Fragment>
              )}
            </td>
          </tr>
          <tr>
            <td>Lízing szerződés</td>
            <td>
              {!this.state.leasingContractFileName ? (
                <FileInput
                  name="traffic-license"
                  multiple={false}
                  label={<i className="fa fa-upload" />}
                  onChange={this.handleLeasingContractUpload}
                />
              ) : (
                <React.Fragment>
                  <button
                    className="btn btn-primary mr-1"
                    onClick={this.handleLeasingContractDownload}
                  >
                    <i className="fa fa-download" />
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={this.handleLeasingContractRemove}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </React.Fragment>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default VehicleForm;
