import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import productCategoryService from "../../services/productCategoryService";
import ProductCategoryDto from "../../models/productCategoryDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
];

const ProductCategories = ({ history }: RouteComponentProps) => {
  const [supplies, setProductCategories] = useState<ProductCategoryDto[]>([]);
  const [
    selectedProductCategory,
    setSelectedProductCategory,
  ] = useState<ProductCategoryDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateProductCategories = async () => {
      const { data } = await productCategoryService.getProductCategories();
      setProductCategories(data);
    };

    populateProductCategories();
  }, []);

  const handleModify = () => {
    if (!selectedProductCategory) return;
    history.push(`${ROUTE.PRODUCT_CATEGORIES}/${selectedProductCategory.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "termek-kategoriak.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.PRODUCT_CATEGORIES);

  return (
    <>
      <h1>{MENU.PRODUCT_CATEGORIES}</h1>
      <NewLink to={`${ROUTE.PRODUCT_CATEGORIES}/new`} label="Új kategória" />
      <ModifyButton
        disabled={!selectedProductCategory}
        onClick={handleModify}
        label="Kategória módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="productCategories"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedProductCategory: ProductCategoryDto) => {
          setSelectedProductCategory(selectedProductCategory);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default ProductCategories;
