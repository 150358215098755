import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import foreignTaxRefundService from "../../services/foreignTaxRefundService";
import countryService from "../../services/countryService";
import Table from "../common/table";
import _ from "lodash";
import ForeignTaxRefundDto from "../../models/foreignTaxRefundDto";
import SortColumn from "../../models/sortColumn";
import CountryDto from "../../models/countryDto";
import path from "../../utils/path";
import Column from "../../models/column";
import * as ROUTE from "../../constants/route";

interface State {
  foreignTaxRefunds: ForeignTaxRefundDto[];
  sortColumn: SortColumn;
  country: CountryDto;
}

class ForeignTaxRefunds extends Component<
  RouteComponentProps<{ countryId: string }>
> {
  get countryId() {
    return this.props.match.params.countryId;
  }
  get title() {
    return this.state.country.name + " - visszaigénylések";
  }
  state: State = {
    foreignTaxRefunds: [],
    sortColumn: { path: path<ForeignTaxRefundDto>()("name"), order: "asc" },
    country: {
      id: 0,
      name: "",
      code: "",
      currencyId: 0,
      currency: { id: 0, name: "" }
    }
  };

  columns: Column<ForeignTaxRefundDto>[] = [
    {
      path: path<ForeignTaxRefundDto>()("name"),
      label: "Megnevezés",
      content: foreignTaxRefund => (
        <Link
          to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.countryId}/refunds/${foreignTaxRefund.id}`}
        >
          {foreignTaxRefund.name}
        </Link>
      )
    },
    {
      key: "download",
      label: "",
      style: { width: "50px" },
      content: foreignTaxRefund => (
        <button
          onClick={() => this.handleDownload(foreignTaxRefund)}
          className="btn btn-primary"
        >
          <i className="fa fa-download" />
        </button>
      )
    },
    {
      key: "view",
      label: "",
      style: { width: "50px" },
      content: foreignTaxRefund => (
        <Link
          className="btn btn-primary"
          to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.countryId}/refunds/${foreignTaxRefund.id}/view`}
        >
          <i className="fa fa-search" />
        </Link>
      )
    }
  ];

  handleDownload = async (foreignTaxRefund: ForeignTaxRefundDto) => {
    await foreignTaxRefundService.exportForeignTaxRefund(
      foreignTaxRefund.id.toString()
    );
  };

  handleSort = (sortColumn: SortColumn) => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const { data: country } = await countryService.getCountry(this.countryId);

    const {
      data: foreignTaxRefunds
    } = await foreignTaxRefundService.getForeignTaxRefunds(this.countryId);

    this.setState({ foreignTaxRefunds, country });
  }

  render() {
    const { foreignTaxRefunds, sortColumn } = this.state;

    const sortedForeignTaxRefunds = _.orderBy(
      foreignTaxRefunds,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <>
        <h1>{this.title}</h1>
        <Link
          to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${this.countryId}/refunds/new`}
          className="btn btn-primary my-2"
        >
          Új visszaigénylés
        </Link>
        <Table
          columns={this.columns}
          data={sortedForeignTaxRefunds}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </>
    );
  }
}

export default ForeignTaxRefunds;
