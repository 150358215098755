import httpService from "./httpService";
import SupplyDto from "../models/supplyDto";
import SaveSupplyDto from "../models/saveSupplyDto";

const baseUrl = "/api/supplies";

function getSupplies() {
  return httpService.get<SupplyDto[]>(baseUrl);
}

function getSumSupplies() {
  return httpService.get<SupplyDto[]>(baseUrl + "/sum");
}

function getSupply(id: string) {
  return httpService.get<SupplyDto>(`${baseUrl}/${id}`);
}

function addSupply(supply: SaveSupplyDto) {
  return httpService.post(baseUrl, supply);
}

function modifySupply(id: string, supply: SaveSupplyDto) {
  return httpService.put(`${baseUrl}/${id}`, supply);
}

function removeSupply(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function distribution(companyId: string) {
  return httpService.post(`${baseUrl}/distribution?companyId=${companyId}`);
}

export default {
  getSupplies,
  getSupply,
  addSupply,
  modifySupply,
  removeSupply,
  distribution,
  getSumSupplies,
};
