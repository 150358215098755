import httpService from "./httpService";
import TransactionDto from "../models/transactionDto";
import SaveTransactionDto from "../models/saveTransactionDto";

const baseUrl = "/api/transactions";

function getManualTransactions() {
  return httpService.get<TransactionDto[]>(baseUrl);
}

function getTransaction(id: string) {
  return httpService.get<TransactionDto>(`${baseUrl}/${id}`);
}

function addTransaction(saveTransactionDto: SaveTransactionDto) {
  return httpService.post(baseUrl, saveTransactionDto);
}

function modifyTransaction(id: string, saveTransactionDto: SaveTransactionDto) {
  return httpService.put(`${baseUrl}/${id}`, saveTransactionDto);
}

function removeTransaction(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function modifyNotRefundable(id: number, notRefundable: boolean) {
  return httpService.put(`${baseUrl}/${id}/not-refundable`, null, {
    params: { notRefundable },
  });
}

export default {
  getManualTransactions,
  getTransaction,
  addTransaction,
  modifyTransaction,
  removeTransaction,
  modifyNotRefundable,
};
