// FUEL CARDS
export const FUEL_CARDS = 'Kártya';
export const MOL_LOADER = 'Mol betöltés';
export const EUROWAG_LOADER = 'Eurowag betöltés';
export const EUROWAG_2021_LOADER = 'Eurowag 2021 betöltés';
export const EUROWAG_CSV_LOADER = 'Eurowag Csv betöltés';
export const EUROWAG_BONUS_LOADER = 'Eurowag bonus betöltés';
export const EUROWAG_MASTER_CARD_LOADER = 'Eurowag master card betöltés';
export const EUROWAG_TOLL_GB_SOURCE_LOADER = 'Eurowag toll gb source betöltés';
export const EUROWAG_TOLL_CH_SOURCE_LOADER = 'Eurowag toll ch source betöltés';
export const SHELL_LOADER = 'Shell betöltés';
export const SHELL_ORIGINAL_LOADER = 'Shell Original betöltés';
export const SHELL_ORIGINAL2_LOADER = 'Shell Original 2 betöltés';
export const OMV_LOADER = 'Omv betöltés';
export const DKV_LOADER = 'Dkv betöltés';
export const OIL_LOADER = 'Oil betöltés';
export const MOBIL_PETROL_LOADER = 'Mobil petrol betöltés';
export const FLEETCOR_LOADER = 'Fleetcor betöltés';
export const MOTO95_LOADER = 'Moto 95 betöltés';
export const ARIS_LOADER = 'Aris betöltés';
export const AS24_LOADER = 'AS24 betöltés';
export const AS24_NEW_LOADER = 'AS24 (Új) betöltés';
export const LUKOIL_LOADER = 'Lukoil betöltés';
export const OPLUS_LOADER = 'Oplus betöltés';
export const WSZL_LOADER = 'WSZL betöltés';
export const WSZL_NEW_LOADER = 'WSZL (Új) betöltés';
export const IMIX_LOADER = 'IMIX betöltés';
export const VARGA_ES_TARSA_LOADER = 'Varga és Társa betöltés';
export const EDO_LOADER = 'EDO betöltés';
export const FLEETCOR_2024_LOADER = 'Fleetcor 2024 betöltés';
export const JOGA_LOADER = 'Joga betöltés';
export const TRANSDANUBIA_LOADER = 'Transdanubia betöltés';
export const DUVENBECK_LOADER = 'Duvenbeck betöltés';
export const UTA_LOADER = 'UTA betöltés';
export const UTA_NORBI_LOADER = 'UTA-Norbi betöltés';

// LOCAL
export const LOCAL = 'Helyi';

export const HUNGARO_MOBIL_TANK_LOADER = 'Hungaro mobil tank betöltés';
export const WEBTRANSPORT_LOADER = 'Web transport betöltés';
export const HALKER_LOADER = 'Halker betöltés';
export const PSZOLG_LOADER = 'PSzolg betöltés';
export const EXPORT_LOADER = 'Export betöltés';
export const TSPAPIRUS_LOADER = 'TsPapirus betöltés';
export const TS_LOADER = 'Ts betöltés';
export const SPRINT_LOADER = 'Sprint betöltés';
export const FER_LOADER = 'Fer betöltés';
export const SZALAI_LOADER = 'Szalai betöltés';
export const ATTWIN_LOADER = 'Attwin betöltés';
export const TA_LOADER = 'Ta betöltés';
export const BANDUR_LOADER = 'Bandur betöltés';
export const KONTENERKUT_LOADER = 'Konténerkút betöltés';

export const TANKS = 'Tartályok';
export const SUPPLIERS = 'Beszállítók';
export const SUPPLIES = 'Beszállítások';

// TOLL SERVICE PROVIDER
export const TOLL_SERVICE_PROVIDER = 'Útdíj';
export const OBU_LOADER = 'Obu betöltés';
export const EUROTOLL_LOADER = 'Eurotoll betöltés';
export const EMYTO_LOADER = 'Emyto betöltés';
export const TOLLCOLLECT_LOADER = 'TollCollect betöltés';
export const MYTO_LOADER = 'Myto betöltés';
export const HUGO_LOADER = 'Hugo betöltés';

// MANUAL TRANSACTIONS
export const MANUAL = 'Kézi';
export const MANUAL_TRANSACTIONS = 'Tranzakciók';

// TRACKING
export const TRACKING = 'Nyomkövetés';
export const TACHOGRAPH = 'Tachograph betöltés';
export const ITRACK = 'ITrack betöltés';

// NAV
export const NAV = 'Nav';
export const NAV_LOADER = 'Nav betöltés';

// REPORT
export const REPORT = 'Riport';
export const REPORTS = 'Riportok';
export const VEHICLE_CATEGORIES = 'Jármű kategóriák';
export const PRODUCT_CATEGORIES = 'Termék kategóriák';

// REFUND
export const REFUND = 'Visszaigénylés';
export const COMMERCIAL_GAS_REFUNDS = 'Kereskedelmi gázolaj visszaigénylések';
export const FOREIGN_TAX_COUNTRIES = 'Külföldi áfa visszaigénylések';

// MASTER
export const MASTER = 'Törzs';
export const DRIVERS = 'Sofőrök';
export const VEHICLES = 'Járművek';
export const INVOICES = 'Számlák';
export const COMPANIES = 'Cégek';
export const FILES = 'Beolvasott fájlok';
export const TRANSACTION_TYPES = 'Szolgáltatók';
export const PRODUCTS = 'Termékek';
export const TRAILERS = 'Pótkocsik';

// DELIVERY NOTE
export const DELIVERY_NOTE = 'Menetlevél';
export const DELIVERY_NOTES = 'Menetlevelek';
export const PERIODS = 'Időszakok';
export const CORRECTIVE_NORMS = 'Korrekciós normák';
export const DESTINATIONS = 'Viszonylatok';
export const PLACES = 'Telephelyek';

// USER
export const USERS = 'Felhasználók';
export const LOGOUT = 'Kijelentkezés';
export const LOGIN = 'Bejelentkezés';
export const TENANTS = 'Partnerek';
