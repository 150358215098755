import React from "react";
import ajaxLoader from "./ajax-loader.gif";

interface LoadingProps {
  loading: boolean;
}

const Loading: React.FC<LoadingProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <img style={{ marginLeft: 10 }} src={ajaxLoader} alt="loader" />
      )}
    </>
  );
};
export default Loading;
