export default function path<T>() {
  function keys<K1 extends keyof T>(key1: K1): string;
  function keys<K1 extends keyof T, K2 extends keyof T[K1]>(
    key1: K1,
    key2: K2
  ): string;
  function keys<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2]
  >(key1: K1, key2: K2, key3: K3): string;
  function keys<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3]
  >(key1: K1, key2: K2, key3: K3, key4: K4): string;
  function keys(...keys: any[]): string {
    return keys.join(".");
  }
  return keys;
}
