import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import invoiceService from "../../services/invoiceService";
import InvoiceDto from "../../models/invoiceDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import { GridComponent, ColumnModel } from "@syncfusion/ej2-react-grids";
import {
  formatBool,
  formatDate,
  JSON_DATE,
  YYYY_MM_DD,
} from "../../utils/format";

const columns: ColumnModel[] = [
  {
    headerText: "Számlaszám",
    field: "number",
  },
  {
    headerText: "Szolgáltató",
    field: "transactionType.name",
  },
  {
    headerText: "Ország",
    field: "country.name",
  },
  {
    field: "date",
    headerText: "Teljesítés kelte",
    valueAccessor: (_, data: Object) =>
      formatDate((data as InvoiceDto).date, JSON_DATE, YYYY_MM_DD),
  },
  {
    field: "dateOfInvoice",
    headerText: "Számla kiállításának napja",
    valueAccessor: (_, data: Object) =>
      formatDate((data as InvoiceDto).dateOfInvoice, JSON_DATE, YYYY_MM_DD),
  },
  {
    field: "paid",
    headerText: "Kifizetve",
    valueAccessor: (_, data: Object) => formatBool((data as InvoiceDto).paid),
  },
  {
    field: "refundable",
    headerText: "Visszaigényelhető",
    valueAccessor: (_, data: Object) =>
      formatBool((data as InvoiceDto).refundable),
  },
  {
    headerText: "Fájlnév",
    field: "transactionFile.fileName",
  },
];

const Invoices = ({ history }: RouteComponentProps) => {
  const [supplies, setInvoices] = useState<InvoiceDto[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateInvoices = async () => {
      const { data } = await invoiceService.getInvoices();
      setInvoices(data);
    };

    populateInvoices();
  }, []);

  const handleModify = () => {
    if (!selectedInvoice) return;
    history.push(`${ROUTE.INVOICES}/${selectedInvoice.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "szamlak.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.INVOICES);

  return (
    <>
      <h1>{MENU.INVOICES}</h1>
      <NewLink to={`${ROUTE.INVOICES}/new`} label="Új számla" />
      <ModifyButton
        disabled={!selectedInvoice}
        onClick={handleModify}
        label="Számla módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="invoices3"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedInvoice: InvoiceDto) => {
          setSelectedInvoice(selectedInvoice);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Invoices;
