import React from "react";

interface Props {
  label: string;
  icon: string;
}

const MenuHeader = ({ label, icon }: Props) => {
  return (
    <a
      href={"-"}
      className="nav-link dropdown-toggle clickable"
      data-toggle="dropdown"
      style={{ color: "#f5f5f5" }}
    >
      <i className={icon} /> {label}
    </a>
  );
};

export default MenuHeader;
