import http from "./httpService";

const baseUrl = "/api/version";

function getVersion() {
  return http.get<string>(baseUrl);
}

export default {
  getVersion
};
