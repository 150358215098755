import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import supplierService from "../../services/supplierService";
import confirm from "../../utils/confirm";
import SaveSupplierDto from "../../models/saveSupplierDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import SupplierDto from "../../models/supplierDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import Required from "../common/required";

interface State extends FormState<SaveSupplierDto> {
  data: SaveSupplierDto;
}

class SupplierForm extends Form<
  SaveSupplierDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  id = this.props.match.params.id === "new" ? "" : this.props.match.params.id;

  state: State = {
    data: {
      name: "",
      vatNumber: ""
    },
    errors: {}
  };

  schema = yup.object().shape<SaveSupplierDto>({
    name: yup
      .string()
      .required()
      .max(255),
    vatNumber: yup
      .string()
      .required()
      .max(255)
  });

  async componentDidMount() {
    if (this.id) await this.populateSupplier();
  }

  async populateSupplier() {
    try {
      const { data } = await supplierService.getSupplier(this.id);
      const supplier = this.mapToViewModel(data);
      this.setState({ data: supplier });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(supplier: SupplierDto): SaveSupplierDto {
    return {
      name: supplier.name,
      vatNumber: supplier.vatNumber
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? supplierService.modifySupplier(this.id, this.state.data)
        : supplierService.addSupplier(this.state.data));
      toast.success("Beszállító sikeresen mentve.");
      this.props.history.push(ROUTE.SUPPLIERS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a beszállítót?", async () => {
      try {
        await supplierService.removeSupplier(this.id);
        toast.success("Beszállító sikeresen törölve.");
        this.props.history.replace(ROUTE.SUPPLIERS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    const { data } = this.state;

    const title = this.id
      ? `${data.name} beszállító módosítása`
      : "Új beszállító";
    setDocumentTitle(title);

    return (
      <div>
        <h1>{title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderInput("vatNumber", <Required label="Adószám" />)}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default SupplierForm;
