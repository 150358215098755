import { saveAs } from "file-saver";
import _ from "lodash";
import { AxiosResponse } from "axios";

export function saveFile(response: AxiosResponse<BlobPart>) {
  const fileName = getFileNameFromResponseContentDisposition(response);
  saveAs(new Blob([response.data]), fileName);
}

function getFileNameFromResponseContentDisposition(res: AxiosResponse) {
  const contentDisposition = res.headers["content-disposition"] || "";
  const matches = /filename=([^;]+)/gi.exec(contentDisposition);
  if (!matches) return "untitled";
  const fileName = _.trim(matches[1] || "untitled", " _");

  return fileName;
}
