import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import tankService from "../../services/tankService";
import TankDto from "../../models/tankDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
  { field: "fuelType.name", headerText: "Üzemanyag típus" },
];

const Tanks = ({ history }: RouteComponentProps) => {
  const [supplies, setTanks] = useState<TankDto[]>([]);
  const [selectedTank, setSelectedTank] = useState<TankDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateTanks = async () => {
      const { data } = await tankService.getTanks();
      setTanks(data);
    };

    populateTanks();
  }, []);

  const handleModify = () => {
    if (!selectedTank) return;
    history.push(`${ROUTE.TANKS}/${selectedTank.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "tartalyok.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.TANKS);

  return (
    <>
      <h1>{MENU.TANKS}</h1>
      <NewLink to={`${ROUTE.TANKS}/new`} label="Új tartály" />
      <ModifyButton
        disabled={!selectedTank}
        onClick={handleModify}
        label="Tartály módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="tanks"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedTank: TankDto) => {
          setSelectedTank(selectedTank);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Tanks;
