import React, { useState, useRef, useEffect } from "react";
import transactionFileService from "../../services/transactionFileService";
import TransactionFileDto from "../../models/transactionFileDto";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { saveFile } from "../../utils/file";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import Button from "../common/button";
import DataGrid from "../common/dataGrid";
import { DOWNLOAD, REFRESH, TRASH } from "../../constants/icon";
import Loading from "../common/loading";
import confirm from "../../utils/confirm";
import { filter } from "lodash";

const columns: ColumnModel[] = [
  {
    field: "fileName",
    headerText: "Fájl",
  },
  {
    field: "transactionType.name",
    headerText: "Típus",
  },
  {
    field: "transactionCount",
    headerText: "Tranzakciók száma",
  },
  {
    field: "firstTransaction.date",
    headerText: "Kezdő",
    type: "date",
    format: "yyyy.MM.dd",
  },
  {
    field: "lastTransaction.date",
    headerText: "Záró",
    type: "date",
    format: "yyyy.MM.dd",
  },
];

const Files = () => {
  const [transactionFiles, setTransactionFiles] = useState<
    TransactionFileDto[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [
    selectedTransactionFile,
    setSelectedTransactionFile,
  ] = useState<TransactionFileDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    setDocumentTitle(MENU.FILES);

    const populateTransactionFiles = async () => {
      const { data } = await transactionFileService.getTransactionFiles();
      setTransactionFiles(data);
    };

    populateTransactionFiles();
  }, []);

  const handleDownload = async () => {
    if (!selectedTransactionFile) return;
    setLoading(true);
    const file = await transactionFileService.downloadTransactionFile(
      selectedTransactionFile.id.toString()
    );
    setLoading(false);
    saveFile(file);
  };

  const handleRefresh = async () => {
    if (!selectedTransactionFile) return;
    setLoading(true);
    await transactionFileService.refreshTransactionFile(
      selectedTransactionFile.id.toString()
    );
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!selectedTransactionFile) return;

    confirm("Biztos törölni szerené a kijelölt fájlt?", async () => {
      setLoading(true);

      try {
        await transactionFileService.removeTransactionFile(
          selectedTransactionFile.id.toString()
        );

        setTransactionFiles(
          filter(
            transactionFiles,
            (transactionFile) =>
              transactionFile.id !== selectedTransactionFile.id
          )
        );
        setSelectedTransactionFile(null);
      } catch {}

      setLoading(false);
    });
  };

  return (
    <>
      <h1>{MENU.FILES}</h1>
      <Button
        onClick={handleDownload}
        icon={DOWNLOAD}
        label="Fájl letöltése"
        disabled={!selectedTransactionFile}
      />
      <Button
        disabled={!selectedTransactionFile}
        onClick={handleRefresh}
        icon={REFRESH}
        label="Fájlhoz tartozó menetlevelek frissítése"
      />
      <Button
        disabled={!selectedTransactionFile}
        onClick={handleDelete}
        icon={TRASH}
        label="Fájl törlése"
        type="danger"
      />
      <Loading loading={loading} />
      <DataGrid
        id="files3"
        columns={columns}
        data={transactionFiles}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selected) => {
          setSelectedTransactionFile(selected);
        }}
      />
    </>
  );
};

export default Files;
