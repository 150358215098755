import React from "react";
import { toast } from "react-toastify";
import Form from "../common/form";
import Required from "../common/required";
import transactionService from "../../services/transactionService";
import invoiceService from "../../services/invoiceService";
import companyService from "../../services/companyService";
import productService from "../../services/productService";
import vehicleService from "../../services/vehicleService";
import currencyService from "../../services/currencyService";
import confirm from "../../utils/confirm";
import * as ROUTE from "../../constants/route";
import * as yup from "yup";
import SaveTransactionDto from "../../models/saveTransactionDto";
import InvoiceDto from "../../models/invoiceDto";
import CompanyDto from "../../models/companyDto";
import ProductDto from "../../models/productDto";
import VehicleDto from "../../models/vehicleDto";
import CurrencyDto from "../../models/currencyDto";
import { RouteComponentProps } from "react-router-dom";
import TransactionDto from "../../models/transactionDto";
import { numberToString } from "../../utils/format";
import setDocumentTitle from "../../utils/document";

const initialState = {
  data: {
    invoiceId: "",
    companyId: "",
    productId: "",
    vehicleId: "",
    supplierGrossTotal: "",
    vatInPercent: "",
    quantity: "",
    km: ""
  } as SaveTransactionDto,

  errors: {},

  invoices: [] as InvoiceDto[],
  companies: [] as CompanyDto[],
  products: [] as ProductDto[],
  vehicles: [] as VehicleDto[],
  currencies: [] as CurrencyDto[]
};

type State = typeof initialState;

class ManualTransactionForm extends Form<
  SaveTransactionDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id ? `Kézi tranzakció módosítása` : "Új kézi tranzakció";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  readonly state: State = initialState;

  schema = yup.object().shape<SaveTransactionDto>({
    invoiceId: yup.string().required(),
    companyId: yup.string().required(),
    productId: yup.string().required(),
    vehicleId: yup.string().required(),
    supplierGrossTotal: yup.string().required(),
    vatInPercent: yup.string().required(),
    quantity: yup.string().required(),
    km: yup.string().required()
  });

  async componentDidMount() {
    await this.populateInvoices();
    await this.populateCompanies();
    await this.populateProducts();
    await this.populateVehicles();
    await this.populateCurrencies();

    if (this.id) await this.populateTransaction();
  }

  async populateInvoices() {
    const { data: invoices } = await invoiceService.getManualInvoices();
    this.setState({ invoices });
  }

  async populateCompanies() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  async populateProducts() {
    const { data: products } = await productService.getManualProducts();
    this.setState({ products });
  }

  async populateVehicles() {
    const { data: vehicles } = await vehicleService.getVehicles();
    this.setState({ vehicles });
  }

  async populateCurrencies() {
    const { data: currencies } = await currencyService.getCurrencies();
    this.setState({ currencies });
  }

  async populateTransaction() {
    try {
      const { data } = await transactionService.getTransaction(this.id);

      const transaction = this.mapToViewModel(data);

      this.setState({ data: transaction });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(transaction: TransactionDto): SaveTransactionDto {
    return {
      invoiceId: numberToString(transaction.invoiceId),
      companyId: numberToString(transaction.companyId),
      productId: numberToString(transaction.productId),
      vehicleId: numberToString(transaction.vehicleId),
      supplierGrossTotal: numberToString(transaction.supplierGrossTotal),
      vatInPercent: numberToString(transaction.vatInPercent),
      quantity: numberToString(transaction.quantity),
      km: numberToString(transaction.km)
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? transactionService.modifyTransaction(this.id, this.state.data)
        : transactionService.addTransaction(this.state.data));
      toast.success("Kézi tranzakció sikeresen mentve.");
      this.props.history.push(ROUTE.MANUAL_TRANSACTIONS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: any) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a kézi tranzakciót?", async () => {
      try {
        await transactionService.removeTransaction(this.id);
        toast.success("Kézi tranzakció sikeresen törölve.");
        this.props.history.replace(ROUTE.MANUAL_TRANSACTIONS);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          this.props.history.replace("/not-found");
        if (ex.response && ex.response.status === 400)
          toast.error(ex.response.data);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderSelect(
            "companyId",
            <Required label="Cég" />,
            this.state.companies,
            "nameOfTaxPlayer"
          )}
          {this.renderSelect(
            "invoiceId",
            <Required label="Számla" />,
            this.state.invoices,
            "number",
            () => this.populateInvoices()
          )}
          {this.renderSelect(
            "productId",
            <Required label="Termék" />,
            this.state.products,
            "name",
            () => this.populateProducts()
          )}
          {this.renderSelect(
            "vehicleId",
            <Required label="Jármű" />,
            this.state.vehicles,
            "licensePlate"
          )}
          {this.renderInput(
            "supplierGrossTotal",
            <Required label="Bruttó összeg" />,
            "number"
          )}
          {this.renderInput(
            "vatInPercent",
            <Required label="Áfa %" />,
            "number"
          )}
          {this.renderInput(
            "quantity",
            <Required label="Mennyiség" />,
            "number"
          )}
          {this.renderInput("km", <Required label="Km állás" />, "number")}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default ManualTransactionForm;
