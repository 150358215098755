import React from "react";
import { useFormikContext, FormikValues } from "formik";
import { Typeahead, TypeaheadLabelKey } from "react-bootstrap-typeahead";
import { isEmpty } from "lodash";

interface Props<T extends {}> {
  name: string;
  label: string;

  options: T[];
  labelKey: TypeaheadLabelKey<T>;
  valueKey: keyof T;
}

const MultiSelectField = <T extends {}>({
  name,
  label,
  options,
  labelKey,
  valueKey,
}: Props<T>) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormikContext<FormikValues>();

  const selected: T[] = [];

  if (!isEmpty(values[name])) {
    values[name].forEach((id: any) => {
      const option = options.find((o) => o[valueKey] === id);
      if (option) selected.push(option);
    });
  }

  return (
    <div className="form-group">
      <label htmlFor={name.toString()}>{label}</label>

      <Typeahead
        id={name.toString()}
        multiple
        options={options}
        labelKey={labelKey}
        selected={selected}
        clearButton={true}
        emptyLabel="Nincs megjeleníthető találat."
        paginationText="További találatok megjelenítése..."
        ref={(typeahead) => {
          const w = window as { [key: string]: any };
          w["_typeahead" + name] = typeahead;
        }}
        onChange={(selected: T[]) => {
          const w = window as { [key: string]: any };
          w["_typeahead" + name].getInstance().blur();
          w["_typeahead" + name].getInstance().focus();
          isEmpty(selected)
            ? setFieldValue(name, [])
            : setFieldValue(
                name,
                selected.map((item) => item[valueKey])
              );
        }}
        onBlur={handleBlur(name)}
        isInvalid={errors[name] ? true : false}
      />
      {touched[name] && errors[name] && (
        <div className="invalid-feedback">{errors[name]}</div>
      )}
    </div>
  );
};

export default MultiSelectField;
