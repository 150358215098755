import React from "react";
import logo from "./logo.jpg";
import setDocumentTitle from "../utils/document";

const Home = () => {
  setDocumentTitle("Kezdőlap");

  return (
    <React.Fragment>
      <img src={logo} className="img-fluid" alt="" />
    </React.Fragment>
  );
};

export default Home;
