import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import useAuth from "../../hooks/useAuth";

const RefundMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (
    !(user.hasModule(MODULE.COMMERCIALGAS) || user.hasModule(MODULE.FOREIGNTAX))
  )
    return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.REFUND} icon={ICON.USD} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.COMMERCIAL_GAS_REFUNDS}
          url={ROUTE.COMMERCIAL_GAS_REFUNDS}
          user={user}
          module={MODULE.COMMERCIALGAS}
        />
        <MenuLink
          label={MENU.FOREIGN_TAX_COUNTRIES}
          url={ROUTE.FOREIGN_TAX_COUNTRIES}
          user={user}
          module={MODULE.FOREIGNTAX}
        />
      </div>
    </div>
  );
};

export default RefundMenu;
