import React from "react";
import Form from "./common/form";
import Loading from "./common/loading";
import { upload } from "../services/uploadService";
import companyService from "../services/companyService";
import CompanyDto from "../models/companyDto";
import { RouteComponentProps } from "react-router-dom";
import Required from "./common/required";
import * as yup from "yup";
import setDocumentTitle from "../utils/document";
import { convertToJson } from "readesm-js";

interface Props extends RouteComponentProps {
  companySelect?: boolean;
  noFileSelect?: boolean;
  type: string;
  label: string;
}

interface Data {
  companyId: string;
  files: File[];
}

const initialState = {
  data: {
    companyId: "",
    files: [],
  } as Data,
  errors: {},
  fileResponses: [] as any[],
  companies: [] as CompanyDto[],
  loading: false,
};

type State = typeof initialState;

class Loader extends Form<Data, Props, State> {
  readonly state: State = initialState;

  schema = yup.object().shape<Data>({
    companyId: this.props.companySelect
      ? yup.string().required()
      : yup.string(),
    files: yup.array<File>().min(this.props.noFileSelect ? 0 : 1),
  });

  async componentDidMount() {
    const { data: companies } = await companyService.getCompanies();
    this.setState({ companies });
  }

  doSubmit = async () => {
    this.setState({ loading: true });

    const tachograps = [];

    if (this.props.type === "tachograph") {
      for (const file of this.state.data.files) {
        const json = convertToJson(await file.arrayBuffer());
        tachograps.push(json);
      }
    }

    try {
      const { data: fileResponses } = await upload(
        this.state.data.companyId,
        this.props.type,
        this.state.data.files,
        tachograps
      );

      this.setState({ fileResponses });
    } catch (ex) {
      this.handleError(ex);
    }
    this.setState({ loading: false });
  };

  render() {
    setDocumentTitle(this.props.label + " betöltés");

    const { files } = this.state.data;
    const { fileResponses } = this.state;

    return (
      <React.Fragment>
        <h1>{this.props.label} betöltés</h1>

        <ul className="list-group mb-3">
          {fileResponses.map(({ status, message }) => {
            let classname = "list-group-item list-group-item-";

            if (status === 1) classname += "success";
            if (status === 2) classname += "warning";
            if (status === 3) classname += "danger";

            return (
              <li key={message} className={classname}>
                <pre>
                  <code>{message}</code>
                </pre>
              </li>
            );
          })}
        </ul>

        <form onSubmit={this.handleSubmit}>
          {this.props.companySelect &&
            this.renderSelect(
              "companyId",
              <Required label="Cég" />,
              this.state.companies,
              "nameOfTaxPlayer"
            )}
          {!this.props.noFileSelect &&
            this.renderFileInput("files", "Állományok kiválasztása...")}
          <span className="ml-2">{this.renderButton("Betöltés")}</span>
          <Loading loading={this.state.loading} />
        </form>

        <ul className="list-group mt-3">
          {!this.props.noFileSelect && files.length === 0 && (
            <li className="list-group-item">Nincs állomány kiválasztva.</li>
          )}
          {!this.props.noFileSelect &&
            files.map(({ name }) => (
              <li key={name} className="list-group-item">
                {name}
              </li>
            ))}
        </ul>
      </React.Fragment>
    );
  }
}

export default Loader;
