import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
  label: string;
}

const ViewButton = ({ disabled, onClick, label }: Props) => (
  <button
    disabled={disabled}
    className="btn btn-primary my-2 mr-2"
    onClick={onClick}
  >
    <i className="fa fa-search" /> {label}
  </button>
);

export default ViewButton;
