import httpService from "./httpService";
import TransactionTypeDto from "../models/transactionTypeDto";
import SaveTransactionTypeDto from "../models/saveTransactionTypeDto";

const baseUrl = "/api/transaction-types";

function getTransactionTypes() {
  return httpService.get<TransactionTypeDto[]>(baseUrl);
}

function getTransactionType(id: string) {
  return httpService.get<TransactionTypeDto>(`${baseUrl}/${id}`);
}

function addTransactionType(saveTransactionType: SaveTransactionTypeDto) {
  return httpService.post(baseUrl, saveTransactionType);
}

function modifyTransactionType(
  id: string,
  saveTransactionType: SaveTransactionTypeDto
) {
  return httpService.put(`${baseUrl}/${id}`, saveTransactionType);
}

function removeTransactionType(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getTransactionTypes,
  getTransactionType,
  addTransactionType,
  modifyTransactionType,
  removeTransactionType
};
