import http from "./httpService";
import ColumnDto from "../models/columnDto";

const baseUrl = "/api/columns";

function getColumns() {
  return http.get<ColumnDto[]>(baseUrl);
}

export default {
  getColumns
};
