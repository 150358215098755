import React from "react";
import { Typeahead, TypeaheadLabelKey } from "react-bootstrap-typeahead";
import Option from "../../models/option";
import CSS from "csstype";

interface Props<T extends Option> {
  name: string;
  label: string | JSX.Element;
  error: string;
  options: T[];
  selected: T[];
  labelKey: TypeaheadLabelKey<T>;
  filterBy?: string[];

  onChange: (selected: T[]) => void;
}

const MultiSelect = <T extends Option>({
  name,
  label,
  error,
  options,
  selected,
  onChange,
  labelKey,
  filterBy
}: Props<T>) => {
  const divStyle: CSS.Properties = {};
  const labelStyle: CSS.Properties = {};
  if (label === "") {
    divStyle.margin = 0;
    labelStyle.margin = 0;
    labelStyle.display = "block";
  }

  return (
    <div className="form-group" style={divStyle}>
      <label htmlFor={name} style={labelStyle}>
        {label}
      </label>

      <Typeahead
        id={name}
        options={options}
        labelKey={labelKey}
        selected={selected}
        multiple={true}
        clearButton={true}
        emptyLabel="Nincs megjeleníthető találat."
        paginationText="További találatok megjelenítése..."
        onChange={selected => {
          // Retain focus to keep the menu open when making new selections.
          const w = window as { [key: string]: any };
          w["_typeahead" + name].getInstance().blur();
          w["_typeahead" + name].getInstance().focus();
          onChange(selected);
        }}
        ref={typeahead => {
          const w = window as { [key: string]: any };
          w["_typeahead" + name] = typeahead;
        }}
        isInvalid={error ? true : false}
        filterBy={filterBy}
      />
      {error && (
        <div style={{ display: "block" }} className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
