import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import LoginDto from "../models/loginDto";
import * as ROUTE from "../constants/route";
import * as yup from "yup";
import setDocumentTitle from "../utils/document";
import * as MENU from "../constants/menu";
import TextField from "./form/textField";
import SubmitButton from "./form/submitButton";
import Form from "./form/form";
import auth from "../api/auth";
import authStorage from "../auth/storage";
import { handleError } from "../utils/serverError";
import useAuth from "../hooks/useAuth";
import http from "../services/httpService";
import { USER, LOCK, SIGN_IN } from "../constants/icon";

const schema = yup.object().shape<LoginDto>({
  name: yup.string().required(),
  password: yup.string().required(),
});

const LoginForm = (props: RouteComponentProps) => {
  const [initialValues] = useState<LoginDto>({
    name: "",
    password: "",
  });

  const { user, setUser } = useAuth();

  useEffect(() => {
    setDocumentTitle(MENU.LOGIN);
    if (user) props.history.replace(ROUTE.ROOT);
  }, [props.history, user]);

  const handleOnSubmit = async (values: LoginDto) => {
    const { data, ok, originalError } = await auth.login(values);

    if (!ok) return handleError(props, originalError);

    if (data) authStorage.storeToken(data as string);

    if (setUser) setUser(authStorage.getUser());

    // remove when use apiSauce everywhere
    http.setJwt(authStorage.getToken());

    props.history.replace(ROUTE.ROOT);
  };

  return (
    <>
      <h1>{MENU.LOGIN}</h1>
      <Form
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={schema}
      >
        <TextField name="name" label="Felhasználónév" required icon={USER} />
        <TextField
          name="password"
          label="Jelszó"
          required
          type="password"
          icon={LOCK}
        />
        <SubmitButton label="Bejelentkezés" icon={SIGN_IN} />
      </Form>
    </>
  );
};

export default LoginForm;
