import React from "react";
import { useFormikContext, FormikValues } from "formik";
import Required from "../common/required";

interface Props {
  name: string;
  label: string;
  required?: boolean;
  type?: string;
  append?: string;
  prepend?: string;
  icon?: string;
}

const TextField = ({
  name,
  label,
  required = false,
  type = "text",
  append,
  prepend,
  icon,
}: Props) => {
  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
  } = useFormikContext<FormikValues>();

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {required ? <Required label={label} /> : label}
      </label>
      <div className="input-group">
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={icon}></i>
            </span>
          </div>
        )}
        <input
          type={type}
          id={name}
          name={name}
          value={values[name]}
          className={
            touched[name] && errors[name]
              ? "form-control is-invalid"
              : "form-control"
          }
          onChange={(input) => setFieldValue(name, input.target.value)}
          onBlur={() => setFieldTouched(name)}
        />
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
        {prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        {touched[name] && errors[name] && (
          <div className="invalid-feedback">{errors[name]}</div>
        )}
      </div>
    </div>
  );
};

export default TextField;
