import React from "react";

export interface RequiredProps {
  label: string;
}

const Required: React.FC<RequiredProps> = ({ label }) => {
  return (
    <span>
      {label}{" "}
      <span style={{ color: "red" }}>
        <strong>*</strong>
      </span>
    </span>
  );
};
export default Required;
