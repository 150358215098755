import http from "./httpService";
import StationDto from "../models/stationDto";

const baseUrl = "/api/stations";

function getStations() {
  return http.get<StationDto[]>(baseUrl);
}

export default {
  getStations
};
