import httpService from "./httpService";
import ReportDto from "../models/reportDto";
import SaveReportDto from "../models/saveReportDto";
import TransactionDto from "../models/transactionDto";

const baseUrl = "/api/reports";

function getReports() {
  return httpService.get<ReportDto[]>(baseUrl);
}

function getReport(id: string) {
  return httpService.get<ReportDto>(`${baseUrl}/${id}`);
}

function exportReport(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/export`, {
    responseType: "blob",
  });
}

function addReport(saveReportDto: SaveReportDto) {
  return httpService.post(baseUrl, saveReportDto);
}

function modifyReport(id: string, saveReportDto: SaveReportDto) {
  return httpService.put(`${baseUrl}/${id}`, saveReportDto);
}

function removeReport(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function getReportTransactions(id: string) {
  return httpService.get<TransactionDto[]>(`${baseUrl}/${id}/transactions`);
}

export default {
  getReports,
  getReport,
  exportReport,
  addReport,
  modifyReport,
  removeReport,
  getReportTransactions,
};
