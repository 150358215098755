import React from "react";

interface Props {
  name: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CheckBox = ({
  name,
  label,
  checked,
  onChange,
  disabled = false,
}: Props) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        className="custom-control-input"
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
