import React, { Component } from "react";
import Column from "../../models/column";
import Option from "../../models/option";
import { get } from "lodash";

interface Props<T> {
  data: T[];
  columns: Column<T>[];
}

class TableBody<T extends Option> extends Component<Props<T>> {
  renderCell = (item: T, column: Column<T>) => {
    if (column.content) return column.content(item);
    if (column.path) return get(item, column.path, "-");
    return "-";
  };

  createKey = (item: T, index: number) => {
    return item.id + index;
  };

  render() {
    const { data, columns } = this.props;

    return (
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            {columns.map((column, index) => (
              <td
                className="align-middle"
                style={column.style}
                key={this.createKey(item, index)}
              >
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
