import httpService from "./httpService";
import SupplierDto from "../models/supplierDto";
import SaveSupplierDto from "../models/saveSupplierDto";

const baseUrl = "/api/suppliers";

function getSuppliers() {
  return httpService.get<SupplierDto[]>(baseUrl);
}

function getSupplier(id: string) {
  return httpService.get<SupplierDto>(`${baseUrl}/${id}`);
}

function addSupplier(supplier: SaveSupplierDto) {
  return httpService.post(baseUrl, supplier);
}

function modifySupplier(id: string, supplier: SaveSupplierDto) {
  return httpService.put(`${baseUrl}/${id}`, supplier);
}

function removeSupplier(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getSuppliers,
  getSupplier,
  addSupplier,
  modifySupplier,
  removeSupplier
};
