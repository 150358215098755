import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import { isAdminTenant } from "../../utils/tenant";
import useAuth from "../../hooks/useAuth";

const ManualTransactionsMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  return !isAdminTenant() ? (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.MANUAL} icon={ICON.STICKY_NOTE} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.MANUAL_TRANSACTIONS}
          url={ROUTE.MANUAL_TRANSACTIONS}
          user={user}
        />
      </div>
    </div>
  ) : null;
};

export default ManualTransactionsMenu;
