import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import useAuth from "../../hooks/useAuth";

const LocalMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (
    !(
      user.hasModule(MODULE.HUNGAROMOBILTANK) ||
      user.hasModule(MODULE.WEBTRANSPORT) ||
      user.hasModule(MODULE.HALKER) ||
      user.hasModule(MODULE.PSZOLG) ||
      user.hasModule(MODULE.EXPORT) ||
      user.hasModule(MODULE.TSPAPIRUS) ||
      user.hasModule(MODULE.TS) ||
      user.hasModule(MODULE.SPRINT) ||
      user.hasModule(MODULE.FER) ||
      user.hasModule(MODULE.SZALAI) ||
      user.hasModule(MODULE.ATTWIN) ||
      user.hasModule(MODULE.TA) ||
      user.hasModule(MODULE.BANDUR) ||
      user.hasModule(MODULE.KONTENERKUT)
    )
  )
    return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.LOCAL} icon={ICON.HOME} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.HUNGARO_MOBIL_TANK_LOADER}
          url={ROUTE.HUNGARO_MOBIL_TANK_LOADER}
          module={MODULE.HUNGAROMOBILTANK}
          user={user}
        />
        <MenuLink
          label={MENU.WEBTRANSPORT_LOADER}
          url={ROUTE.WEB_TRANSPORT_LOADER}
          module={MODULE.WEBTRANSPORT}
          user={user}
        />
        <MenuLink
          label={MENU.HALKER_LOADER}
          url={ROUTE.HALKER_LOADER}
          module={MODULE.HALKER}
          user={user}
        />
        <MenuLink
          label={MENU.PSZOLG_LOADER}
          url={ROUTE.PSZOLG_LOADER}
          module={MODULE.PSZOLG}
          user={user}
        />
        <MenuLink
          label={MENU.EXPORT_LOADER}
          url={ROUTE.EXPORT_LOADER}
          module={MODULE.EXPORT}
          user={user}
        />
        <MenuLink
          label={MENU.TSPAPIRUS_LOADER}
          url={ROUTE.TSPAPIRUS_LOADER}
          module={MODULE.TSPAPIRUS}
          user={user}
        />
        <MenuLink
          label={MENU.TS_LOADER}
          url={ROUTE.TS_LOADER}
          module={MODULE.TS}
          user={user}
        />
        <MenuLink
          label={MENU.SPRINT_LOADER}
          url={ROUTE.SPRINT_LOADER}
          module={MODULE.SPRINT}
          user={user}
        />
        <MenuLink
          label={MENU.FER_LOADER}
          url={ROUTE.FER_LOADER}
          module={MODULE.FER}
          user={user}
        />
        <MenuLink
          label={MENU.SZALAI_LOADER}
          url={ROUTE.SZALAI_LOADER}
          module={MODULE.SZALAI}
          user={user}
        />
        <MenuLink
          label={MENU.ATTWIN_LOADER}
          url={ROUTE.ATTWIN_LOADER}
          module={MODULE.ATTWIN}
          user={user}
        />
        <MenuLink
          label={MENU.TA_LOADER}
          url={ROUTE.TA_LOADER}
          module={MODULE.TA}
          user={user}
        />
        <MenuLink
          label={MENU.BANDUR_LOADER}
          url={ROUTE.BANDUR_LOADER}
          module={MODULE.BANDUR}
          user={user}
        />
        <MenuLink
          label={MENU.KONTENERKUT_LOADER}
          url={ROUTE.KONTENERKUT_LOADER}
          module={MODULE.KONTENERKUT}
          user={user}
        />
        <MenuLink label={MENU.TANKS} url={ROUTE.TANKS} />
        <MenuLink label={MENU.SUPPLIERS} url={ROUTE.SUPPLIERS} />
        <MenuLink label={MENU.SUPPLIES} url={ROUTE.SUPPLIES} />
      </div>
    </div>
  );
};

export default LocalMenu;
