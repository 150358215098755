import { createContext } from "react";
import UserPayload from "../models/userPayload";

interface AuthContextInterface {
  user: UserPayload | null;
  setUser: (user: UserPayload | null) => void;
}

const AuthContext = createContext<AuthContextInterface>({
  user: null,
  setUser: () => {},
});

export default AuthContext;
