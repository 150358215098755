import React from "react";

interface Props {
  name: string;
  label: string | JSX.Element;
  multiple: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput = ({ name, label, multiple, onChange }: Props) => {
  return (
    <label className="btn btn-secondary" style={{ marginBottom: 0 }}>
      {label}
      <input
        id={name}
        name={name}
        multiple={multiple}
        onChange={onChange}
        type="file"
        style={{ display: "none" }}
      />
    </label>
  );
};

export default FileInput;
