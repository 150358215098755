import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import transactionService from "../../services/transactionService";
import TransactionDto from "../../models/transactionDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import { formatDate, JSON_DATE, YYYY_MM_DD_HH_mm } from "../../utils/format";
import setDocumentTitle from "../../utils/document";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";

const columns: ColumnModel[] = [
  {
    field: "invoice",
    headerText: "Számlaszám",
  },
  {
    field: "date",
    headerText: "Dátum",
    valueAccessor: (_, data: Object) =>
      formatDate((data as TransactionDto).date, JSON_DATE, YYYY_MM_DD_HH_mm),
  },
  {
    field: "country",
    headerText: "Ország",
  },
  {
    field: "vehicle",
    headerText: "Jármű",
  },
  {
    field: "product",
    headerText: "Termék",
  },
  {
    field: "company",
    headerText: "Cég",
  },
  {
    field: "supplierGrossTotal",
    headerText: "Bruttó",
    type: "number",
    format: "N2",
  },
  {
    field: "vatInPercent",
    headerText: "Áfa %",
    type: "number",
    format: "N2",
  },
  {
    field: "quantity",
    headerText: "Mennyiség",
    type: "number",
    format: "N2",
  },
  {
    field: "km",
    headerText: "Km óra állás",
    type: "number",
    format: "N0",
  },
];

const ManualTransactions = ({ history }: RouteComponentProps) => {
  const [transactions, setTransactions] = useState<TransactionDto[]>([]);
  const [
    selectedTransaction,
    setSelectedTransaction,
  ] = useState<TransactionDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateTransactions = async () => {
      const { data } = await transactionService.getManualTransactions();
      setTransactions(data);
    };

    populateTransactions();
  }, []);

  const handleModify = () => {
    if (!selectedTransaction) return;
    history.push(`${ROUTE.MANUAL_TRANSACTIONS}/${selectedTransaction.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "tranzakciok.xlsx" });
  };

  if (!transactions) return <>Betöltés...</>;

  setDocumentTitle(MENU.MANUAL_TRANSACTIONS);

  return (
    <>
      <h1>{MENU.MANUAL_TRANSACTIONS}</h1>
      <NewLink to={`${ROUTE.MANUAL_TRANSACTIONS}/new`} label="Új tranzakció" />
      <ModifyButton
        disabled={!selectedTransaction}
        onClick={handleModify}
        label="Tranzakció módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="manualTransactions"
        columns={columns}
        data={transactions}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedTransaction: TransactionDto) => {
          setSelectedTransaction(selectedTransaction);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default ManualTransactions;
