import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import { isAdminTenant } from "../../utils/tenant";
import useAuth from "../../hooks/useAuth";

const TrackingMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (!(user.hasModule(MODULE.ITRACK) || user.hasModule(MODULE.TACHOGRAPH)))
    return null;

  return !isAdminTenant() ? (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.TRACKING} icon={ICON.GLOBE} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.TACHOGRAPH}
          url={ROUTE.TACHOGRAPH_LOADER}
          user={user}
          module={MODULE.TACHOGRAPH}
        />
        <MenuLink
          label={MENU.ITRACK}
          url={ROUTE.ITRACK_LOADER}
          user={user}
          module={MODULE.ITRACK}
        />
      </div>
    </div>
  ) : null;
};

export default TrackingMenu;
