import React from "react";
import { useFormikContext, FormikValues } from "formik";
import { Typeahead, TypeaheadLabelKey } from "react-bootstrap-typeahead";
import { isEmpty } from "lodash";

interface Props<T extends FormikValues> {
  name: string;
  label: string;

  options: T[];
  labelKey: TypeaheadLabelKey<T>;
  valueKey: keyof T;
}

export default function SelectField<T extends FormikValues>({
  name,
  label,
  options,
  labelKey,
  valueKey,
}: Props<T>) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormikValues>();

  const option = options.find(
    (o) => o[valueKey.toString()].toString() === values[name].toString()
  );
  const selected = option ? [option] : [];

  return (
    <>
      <div className="form-group">
        <label htmlFor={name.toString()}>{label}</label>
        <Typeahead
          id={name}
          options={options}
          labelKey={labelKey}
          selected={selected}
          clearButton={true}
          emptyLabel="Nincs megjeleníthető találat."
          paginationText="További találatok megjelenítése..."
          onChange={(selected: T[]) => {
            isEmpty(selected)
              ? setFieldValue(name, "")
              : setFieldValue(
                  name,
                  selected[0][valueKey.toString()].toString()
                );
          }}
          onBlur={() => setFieldTouched(name)}
          isInvalid={touched[name] && errors[name] ? true : false}
        />
        {touched[name] && errors[name] && (
          <div style={styles.error} className="invalid-feedback">
            {errors[name]}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  error: { display: "block" },
};
