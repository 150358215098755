import React, { Component } from "react";
import { Link } from "react-router-dom";
import deliveryNotePeriodService from "../../services/deliveryNotePeriodService";
import Table from "../common/table";
import _ from "lodash";
import DeliveryNotePeriodDto from "../../models/deliveryNotePeriodDto";
import path from "../../utils/path";
import Column from "../../models/column";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import SortColumn from "../../models/sortColumn";
import { numberToString } from "../../utils/format";
import { saveFile } from "../../utils/file";

interface State {
  deliveryNotePeriods: DeliveryNotePeriodDto[];
  sortColumn: SortColumn;
}

class DeliveryNotePeriods extends Component<{}, State> {
  state: State = {
    deliveryNotePeriods: [],
    sortColumn: { path: path<DeliveryNotePeriodDto>()("name"), order: "asc" }
  };

  columns: Column<DeliveryNotePeriodDto>[] = [
    {
      path: path<DeliveryNotePeriodDto>()("name"),
      label: "Időszak",
      content: deliveryNotePeriod => (
        <Link to={`${ROUTE.DELIVERY_NOTE_PERIODS}/${deliveryNotePeriod.id}`}>
          {deliveryNotePeriod.name}
        </Link>
      )
    },
    {
      path: path<DeliveryNotePeriodDto>()("company", "nameOfTaxPlayer"),
      label: "Cég"
    },
    {
      path: path<DeliveryNotePeriodDto>()("price"),
      label: "NAV üzemanyag ár"
    },
    {
      path: path<DeliveryNotePeriodDto>()("percent"),
      label: "Maximum megtakarítás %"
    },
    {
      path: path<DeliveryNotePeriodDto>()("save"),
      label: "Maximum megtakarítás"
    },
    {
      key: "export",
      label: "Megtakarítás",
      style: { width: "50px" },
      content: deliveryNotePeriod => (
        <button
          onClick={() => this.handleDownload(deliveryNotePeriod)}
          className="btn btn-primary"
        >
          <i className="fa fa-download" />
        </button>
      )
    },
    {
      key: "daily-export",
      label: "Napi díj",
      style: { width: "50px" },
      content: deliveryNotePeriod => (
        <button
          onClick={() => this.handleDailyDownload(deliveryNotePeriod)}
          className="btn btn-primary"
        >
          <i className="fa fa-download" />
        </button>
      )
    },
    {
      key: "energy-export",
      label: "Energetikai bevallás",
      style: { width: "50px" },
      content: deliveryNotePeriod => (
        <button
          onClick={() => this.handleEnergyDownload(deliveryNotePeriod)}
          className="btn btn-primary"
        >
          <i className="fa fa-download" />
        </button>
      )
    }
  ];

  handleDownload = async (deliveryNotePeriod: DeliveryNotePeriodDto) => {
    const response = await deliveryNotePeriodService.exportDeliveryNotePeriod(
      numberToString(deliveryNotePeriod.id)
    );
    saveFile(response);
  };

  handleDailyDownload = async (deliveryNotePeriod: DeliveryNotePeriodDto) => {
    const response = await deliveryNotePeriodService.dailyExportDeliveryNotePeriod(
      numberToString(deliveryNotePeriod.id)
    );
    saveFile(response);
  };

  handleEnergyDownload = async ({ id }: DeliveryNotePeriodDto) => {
    const response = await deliveryNotePeriodService.energyExport(id);
    saveFile(response);
  };

  handleSort = (sortColumn: SortColumn) => this.setState({ sortColumn });

  async componentDidMount() {
    const {
      data: deliveryNotePeriods
    } = await deliveryNotePeriodService.getDeliveryNotePeriods();
    this.setState({ deliveryNotePeriods });
  }

  render() {
    setDocumentTitle(MENU.PERIODS);

    const { deliveryNotePeriods, sortColumn } = this.state;

    const sortedDeliveryNotePeriods = _.orderBy(
      deliveryNotePeriods,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <h1>{MENU.PERIODS}</h1>
        <Link
          to={`${ROUTE.DELIVERY_NOTE_PERIODS}/new`}
          className="btn btn-primary my-2"
        >
          Új időszak
        </Link>
        <Table
          columns={this.columns}
          data={sortedDeliveryNotePeriods}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </React.Fragment>
    );
  }
}

export default DeliveryNotePeriods;
