import React, { useEffect, useRef, useState } from "react";
import trailerService from "../../services/trailerService";
import TrailerDto from "../../models/trailerDto";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";
import { GridComponent, ColumnModel } from "@syncfusion/ej2-react-grids";
import { RouteComponentProps } from "react-router-dom";
import DataGrid from "../common/dataGrid";
import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";

const columns: ColumnModel[] = [
  {
    headerText: "Rendszám",
    field: "licensePlate",
  },
  {
    headerText: "Súly",
    field: "weight",
    textAlign: "Right",
    type: "number",
    format: "N0",
  },
  {
    headerText: "Terhelhetőség",
    field: "load",
    textAlign: "Right",
    type: "number",
    format: "N0",
  },
];

const Trailers = ({ history }: RouteComponentProps) => {
  const [trailers, setTrailers] = useState<TrailerDto[]>([]);
  const [selectedTrailer, setSelectedTrailer] = useState<TrailerDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateTrailers = async () => {
      const { data } = await trailerService.getTrailers();
      setTrailers(data);
    };

    populateTrailers();
  }, []);

  const handleModify = () => {
    if (!selectedTrailer) return;
    history.push(`${ROUTE.TRAILERS}/${selectedTrailer.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "potkocsik.xlsx" });
  };

  if (!trailers) return <>Betöltés...</>;

  setDocumentTitle(MENU.TRAILERS);

  return (
    <>
      <h1>{MENU.TRAILERS}</h1>
      <NewLink to={`${ROUTE.TRAILERS}/new`} label="Új pótkocsi" />
      <ModifyButton
        disabled={!selectedTrailer}
        onClick={handleModify}
        label="Pótkocsi módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="trailers"
        columns={columns}
        data={trailers}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedTrailer: TrailerDto) => {
          setSelectedTrailer(selectedTrailer);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Trailers;
