import http from "./httpService";
import TeaorDto from "../models/teaorDto";

const baseUrl = "/api/teaors";

function getTeaors() {
  return http.get<TeaorDto[]>(baseUrl);
}

export default {
  getTeaors
};
