import React from 'react';
import { Link } from 'react-router-dom';
import FuelCardsMenu from './menu/fuelCardsMenu';
import LocalMenu from './menu/localMenu';
import TollServiceProvidersMenu from './menu/tollServiceProvidersMenu';
import ManualTransactionsMenu from './menu/manualTransactionsMenu';
import ReportMenu from './menu/reportMenu';
import RefundMenu from './menu/refundMenu';
import DeliveryNoteMenu from './menu/deliveryNoteMenu';
import MasterMenu from './menu/masterMenu';
import UserMenu from './menu/userMenu';
import TenantsMenu from './menu/tenantsMenu';
import UsersMenu from './menu/usersMenu';
import TrackingMenu from './menu/trackingMenu';
import NavMenu from './menu/navMenu';

const NavBar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light shadow"
      style={{ backgroundColor: '#bf0a0a' }}
    >
      <div className="container">
        <Link className="navbar-brand" to="/" style={{ color: '#f5f5f5' }}>
          eszaf
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav mr-auto">
            <>
              <FuelCardsMenu />
              <LocalMenu />
              <TollServiceProvidersMenu />
              <ManualTransactionsMenu />
              <TrackingMenu />
              <NavMenu />
              <ReportMenu />
              <RefundMenu />
              <DeliveryNoteMenu />
              <MasterMenu />
              <TenantsMenu />
              <UsersMenu />
            </>
          </div>
          <div className="navbar-nav">
            <UserMenu />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
