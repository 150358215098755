import React, { useState, useRef, useEffect, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import setDocumentTitle from "../../utils/document";
import {
  ColumnModel,
  GridComponent,
  AggregateColumnModel,
} from "@syncfusion/ej2-react-grids";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import ReportDto from "../../models/reportDto";
import { formatDate, JSON_DATE, YYYY_MM_DD_HH_mm } from "../../utils/format";
import reportService from "../../services/reportService";
import TransactionDto from "../../models/transactionDto";
import { FullScreenContext } from "../../fullScreenContext";
import _ from "lodash";

const ReportView = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ id: string }>) => {
  const [transactions, setTransactions] = useState<TransactionDto[]>();
  const [report, setReport] = useState<ReportDto>();
  const grid = useRef<GridComponent>();
  const { setFullScreen } = useContext(FullScreenContext);

  useEffect(() => {
    if (setFullScreen) setFullScreen(true);

    const populateReport = async () => {
      const { data } = await reportService.getReport(id);
      setReport(data);
    };
    const populateTransactions = async () => {
      const { data } = await reportService.getReportTransactions(id);
      setTransactions(data);
    };

    populateReport();
    populateTransactions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (setFullScreen) setFullScreen(false);
    };
  }, [id, setFullScreen]);

  const handleExcelExport = () => {
    if (!grid.current || !report) return;
    grid.current.excelExport({
      fileName: report.name + ".xlsx",
      header: {
        headerRows: 2,
        rows: [
          {
            cells: [
              {
                value: "Riport neve: ",
                style: {
                  fontSize: 13,
                  bold: true,
                },
              },
              {
                value: report.name,
                style: {
                  fontSize: 14,
                  bold: true,
                },
              },
            ],
          },
        ],
      },
    });
  };

  if (!report || !transactions) return <>Betöltés...</>;

  setDocumentTitle(report.name);

  const numericField = [
    "vatInPercent",
    "quantity",
    "supplierGrossUnitPrice",
    "supplierNetUnitPrice",
    "supplierNetTotal",
    "supplierVatPrice",
    "supplierGrossTotal",
    "issuerGrossUnitPrice",
    "issuerNetUnitPrice",
    "issuerNetTotal",
    "issuerVatPrice",
    "issuerGrossTotal",
    "grossUnitPrice",
    "netUnitPrice",
    "netTotal",
    "vatPrice",
    "grossTotal",
    "km",
  ];

  const columns: ColumnModel[] = _.orderBy(
    report.columns,
    ["order"],
    "asc"
  ).map<ColumnModel>(({ column }) => {
    return {
      field: column.field,
      headerText: column.name,
      textAlign: numericField.includes(column.field) ? "Right" : "Left",
      type: numericField.includes(column.field) ? "number" : "string",
      format: numericField.includes(column.field) ? "N2" : "",
      valueAccessor: (field, data, _) => {
        switch (field) {
          case "date":
            return formatDate(
              (data as any)[field],
              JSON_DATE,
              YYYY_MM_DD_HH_mm
            );
          default:
            return (data as any)[field];
        }
      },
    };
  });

  columns.push({
    field: "vehicle2",
    headerText: "Rendszám(-)",
    textAlign: "Left",
    type: "string",
    format: "",
    visible: false,
    valueAccessor: (field, data, _) => {
      const vehicle = (data as TransactionDto)["vehicle"];

      if (
        vehicle &&
        vehicle.length === 6 &&
        !isNaN((vehicle.substring(3, 6) as unknown) as number)
      )
        return vehicle.substring(0, 3) + "-" + vehicle.substring(3, 6);

      return vehicle;
    },
  });

  const aggregates: AggregateColumnModel[] = report.columns.reduce<
    AggregateColumnModel[]
  >((prev, current) => {
    if (current.sum)
      prev.push({
        field: current.column.field,
        type: "Sum",
        format: "N0",
      });
    if (current.avg)
      prev.push({
        field: current.column.field,
        type: "Average",
        format: "N0",
      });
    return prev;
  }, []);

  const groupColumns: string[] = _.orderBy(
    report.columns,
    ["order"],
    "asc"
  ).reduce<string[]>((prev, current) => {
    if (current.group) prev.push(current.column.field);
    return prev;
  }, []);

  return (
    <>
      <h1>{report.name}</h1>
      <ExcelExportButton onClick={handleExcelExport} />
      <DataGrid
        enablePersistence={false}
        id={"report_" + report.id}
        columns={columns}
        aggregates={aggregates}
        groupSettings={{ columns: groupColumns }}
        data={transactions}
        onGridReady={(g) => {
          grid.current = g;
        }}
      />
    </>
  );
};

export default ReportView;
