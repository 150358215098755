import bootbox from "bootbox";

export default function confirm(message: string, callback: () => void) {
  bootbox.confirm({
    message: message,
    buttons: {
      cancel: {
        label: "Nem",
        className: "btn-default",
      },
      confirm: {
        label: "Igen",
        className: "btn-danger",
      },
    },
    callback: async (result) => {
      if (result === false) return;
      callback();
    },
  });
}
