import React, { Component } from "react";

class GroupTable extends Component {
  getColumnByFieldName = fieldName => {
    return this.props.columns.find(c => c.column.field === fieldName);
  };

  getCellAlign = (data, field) => {
    return typeof data[field] === "number" ? "right" : "left";
  };

  renderHeaderRow = () => {
    return (
      <tr>
        <td style={{ width: 25 }} />
        {this.props.columns.map((column, index) => {
          if (column.group) {
            return <td key={"C_" + index} style={{ width: 25 }} />;
          } else {
            return <td key={"C_" + index}>{column.column.name}</td>;
          }
        })}
      </tr>
    );
  };

  groupBackgroundColors = [
    "#F5F5F5",
    "#E8E8E8",
    "#DCDCDC",
    "#D3D3D3",
    "#A9A9A9",
    "#808080"
  ];

  getGroupBackgroundColors = index => {
    return this.groupBackgroundColors[index + 1];
  };

  renderGroupRow = (item, rowIndex) => {
    let groupIdent = [];
    for (let i = 0; i < item.groupLevel; i++) groupIdent.push(i);

    return (
      <tr key={"G_" + rowIndex}>
        <td
          style={{
            backgroundColor: this.groupBackgroundColors[0],
            borderBottomColor: this.groupBackgroundColors[0],
            borderRightColor: this.groupBackgroundColors[0]
          }}
        />
        {groupIdent.map(i => (
          <td
            key={"G_" + rowIndex + "_" + i}
            style={{
              backgroundColor: this.getGroupBackgroundColors(i),
              borderBottomColor: this.getGroupBackgroundColors(i),
              borderRightColor: this.getGroupBackgroundColors(i)
            }}
          />
        ))}
        <td
          style={{
            backgroundColor: this.getGroupBackgroundColors(item.groupLevel),
            borderBottomColor: this.getGroupBackgroundColors(item.groupLevel),
            borderRightColor: this.getGroupBackgroundColors(item.groupLevel)
          }}
          colSpan={this.props.columns.length - item.groupLevel}
        >
          {this.getColumnByFieldName(item.groupField).column.name +
            ": " +
            item.groupKey +
            " (" +
            item.itemCount +
            " db)"}
        </td>
      </tr>
    );
  };

  renderGroupAggregateRow = (item, rowIndex, aggregateType) => {
    return (
      <tr key={aggregateType + "_" + rowIndex}>
        <td
          style={{
            backgroundColor: this.groupBackgroundColors[0],
            borderBottomColor: this.groupBackgroundColors[0],
            borderRightColor: this.groupBackgroundColors[0]
          }}
        />
        {this.props.columns.map((column, index) => {
          if (column.group) {
            return (
              <td
                key={aggregateType + "_" + rowIndex + "_" + index}
                style={{
                  backgroundColor: this.getGroupBackgroundColors(
                    index < item.groupLevel ? index : item.groupLevel
                  ),
                  borderBottomColor: this.getGroupBackgroundColors(
                    index < item.groupLevel ? index : item.groupLevel
                  ),
                  borderRightColor: this.getGroupBackgroundColors(
                    index < item.groupLevel ? index : item.groupLevel
                  )
                }}
              >
                {index === item.groupLevel
                  ? aggregateType === "S"
                    ? "Ö"
                    : "Á"
                  : ""}
              </td>
            );
          } else {
            let aggregateValue;
            if (
              aggregateType === "S" &&
              this.props.columns[item.groupLevel].sums.find(
                c => c.id === column.column.id
              )
            ) {
              aggregateValue = this.props.getCellValue(
                item.data,
                column.column.field
              );
            } else if (
              aggregateType === "A" &&
              this.props.columns[item.groupLevel].avgs.find(
                c => c.id === column.column.id
              )
            ) {
              aggregateValue = this.props.getCellValue(
                item.data,
                column.column.field
              );
            }
            return (
              <td
                key={aggregateType + "_" + rowIndex + "_" + index}
                style={{
                  backgroundColor: this.getGroupBackgroundColors(
                    item.groupLevel
                  ),
                  borderBottomColor: this.getGroupBackgroundColors(
                    item.groupLevel
                  ),
                  borderRightColor: this.getGroupBackgroundColors(
                    item.groupLevel
                  ),
                  textAlign: this.getCellAlign(item.data, column.column.field)
                }}
              >
                {aggregateValue}
              </td>
            );
          }
        })}
      </tr>
    );
  };

  renderTotalAggregateRow = (item, rowIndex, aggregateType) => {
    return (
      <tr key={"T" + aggregateType + "_" + rowIndex}>
        <td
          style={{
            backgroundColor: this.groupBackgroundColors[0],
            borderBottomColor: this.groupBackgroundColors[0],
            borderRightColor: this.groupBackgroundColors[0]
          }}
        >
          {aggregateType === "S" ? "Ö" : "Á"}
        </td>
        {this.props.columns.map((column, index) => {
          let aggregateValue;
          if (aggregateType === "S" && column.sum) {
            aggregateValue = this.props.getCellValue(
              item.data,
              column.column.field
            );
          } else if (aggregateType === "A" && column.avg) {
            aggregateValue = this.props.getCellValue(
              item.data,
              column.column.field
            );
          }
          return (
            <td
              key={"T" + aggregateType + "_" + rowIndex + "_" + index}
              style={{
                textAlign: this.getCellAlign(item.data, column.column.field),
                backgroundColor: this.groupBackgroundColors[0],
                borderBottomColor: this.groupBackgroundColors[0],
                borderRightColor: this.groupBackgroundColors[0]
              }}
            >
              {aggregateValue}
            </td>
          );
        })}
      </tr>
    );
  };

  renderDataRow = (item, rowIndex) => {
    return (
      <tr key={"D_" + rowIndex}>
        <td
          style={{
            backgroundColor: this.groupBackgroundColors[0],
            borderBottomColor: this.groupBackgroundColors[0]
          }}
        />
        {this.props.columns.map((column, index) => {
          if (column.group) {
            return (
              <td
                key={"D_" + rowIndex + "_" + index}
                style={{
                  borderBottomColor: this.getGroupBackgroundColors(index),
                  backgroundColor: this.getGroupBackgroundColors(index),
                  borderRightColor: this.getGroupBackgroundColors(index)
                }}
              />
            );
          } else
            return (
              <td
                key={"D_" + rowIndex + "_" + index}
                style={{
                  textAlign: this.getCellAlign(item.data, column.column.field)
                }}
              >
                {this.props.getCellValue(item.data, column.column.field)}
              </td>
            );
        })}
      </tr>
    );
  };

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>{this.renderHeaderRow()}</thead>
          <tbody>
            {this.props.items.map((item, index) => {
              if (item.group) {
                return this.renderGroupRow(item, index);
              } else if (item.sum && item.groupLevel !== null) {
                return this.renderGroupAggregateRow(item, index, "S");
              } else if (item.avg && item.groupLevel !== null) {
                return this.renderGroupAggregateRow(item, index, "A");
              } else if (item.sum && item.groupLevel === null) {
                return this.renderTotalAggregateRow(item, index, "S");
              } else if (item.avg && item.groupLevel === null) {
                return this.renderTotalAggregateRow(item, index, "A");
              } else {
                return this.renderDataRow(item, index);
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default GroupTable;
