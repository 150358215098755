import httpService from "./httpService";
import axios from "axios";
import { saveFile } from "../utils/file";
import SaveVehicleDto from "../models/saveVehicleDto";
import VehicleDto from "../models/vehicleDto";

const baseUrl = "/api/vehicles";

function getVehicles() {
  return httpService.get<VehicleDto[]>(baseUrl);
}

function getVehicle(id: string) {
  return httpService.get<VehicleDto>(`${baseUrl}/${id}`);
}

function modifyVehicle(id: string, vehicle: SaveVehicleDto) {
  return httpService.put(`${baseUrl}/${id}`, vehicle);
}

function uploadTrafficLicense(id: string, file: File) {
  let formData = new FormData();
  formData.append("file", file);
  return httpService.post<string>(`${baseUrl}/${id}/traffic-license`, formData);
}

function uploadRentalContract(id: string, file: File) {
  let formData = new FormData();
  formData.append("file", file);
  return httpService.post<string>(`${baseUrl}/${id}/rental-contract`, formData);
}

function uploadLeasingContract(id: string, file: File) {
  let formData = new FormData();
  formData.append("file", file);
  return httpService.post<string>(
    `${baseUrl}/${id}/leasing-contract`,
    formData
  );
}

function removeTrafficLicense(id: string) {
  return httpService.delete(`${baseUrl}/${id}/traffic-license`);
}

function removeRentalContract(id: string) {
  return httpService.delete(`${baseUrl}/${id}/rental-contract`);
}

function removeLeasingContract(id: string) {
  return httpService.delete(`${baseUrl}/${id}/leasing-contract`);
}

function downloadTrafficLicense(id: string) {
  axios({
    url: `${baseUrl}/${id}/traffic-license`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    saveFile(response);
  });
}

function downloadRentalContract(id: string) {
  axios({
    url: `${baseUrl}/${id}/rental-contract`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    saveFile(response);
  });
}

function downloadLeasingContract(id: string) {
  axios({
    url: `${baseUrl}/${id}/leasing-contract`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    saveFile(response);
  });
}
export default {
  getVehicles,
  getVehicle,
  modifyVehicle,
  uploadTrafficLicense,
  uploadRentalContract,
  uploadLeasingContract,
  removeTrafficLicense,
  removeRentalContract,
  removeLeasingContract,
  downloadTrafficLicense,
  downloadRentalContract,
  downloadLeasingContract,
};
