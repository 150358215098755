import httpService from "./httpService";
import DestinationDto from "../models/destinationDto";
import SaveDestinationDto from "../models/saveDestinationDto";

const baseUrl = "/api/destinations";

function getDestinations() {
  return httpService.get<DestinationDto[]>(baseUrl);
}

function getDestination(id: string) {
  return httpService.get<DestinationDto>(`${baseUrl}/${id}`);
}

function addDestination(destination: SaveDestinationDto) {
  return httpService.post(baseUrl, destination);
}

function modifyDestination(id: string, destination: SaveDestinationDto) {
  return httpService.put(`${baseUrl}/${id}`, destination);
}

function removeDestination(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getDestinations,
  getDestination,
  addDestination,
  modifyDestination,
  removeDestination
};
