interface Error {
  [key: string]: string[];
}
export default function errorConverter(error: string | Error) {
  if (typeof error === "string") return error;

  let ret = "";
  for (let item in error) {
    ret = ret.concat(error[item][0]);
  }
  return ret;
}
