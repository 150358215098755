import React from "react";
import { toast } from "react-toastify";
import confirm from "../../utils/confirm";
import Form, { FormState } from "../common/form";
import vehicleCategoryService from "../../services/vehicleCategoryService";
import vehicleService from "../../services/vehicleService";
import SaveVehicleCategoryDto from "../../models/saveVehicleCategoryDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import VehicleCategoryDto from "../../models/vehicleCategoryDto";
import VehicleDto from "../../models/vehicleDto";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";

interface State extends FormState<SaveVehicleCategoryDto> {
  data: SaveVehicleCategoryDto;
  vehicles: VehicleDto[];
}

class VehicleCategoryForm extends Form<
  SaveVehicleCategoryDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} jármű kategória módosítása`
      : "Új jármű kategória";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }
  state: State = {
    data: {
      name: "",
      vehicles: [],
    },
    errors: {},
    vehicles: [],
  };

  schema = yup.object().shape<SaveVehicleCategoryDto>({
    name: yup.string().required().max(255),
    vehicles: yup.array(),
  });

  async componentDidMount() {
    await this.populateVehicles();
    if (this.id) await this.populateVehicleCategory();
  }

  async populateVehicles() {
    const { data: vehicles } = await vehicleService.getVehicles();
    this.setState({ vehicles });
  }

  async populateVehicleCategory() {
    try {
      const { data } = await vehicleCategoryService.getVehicleCategory(this.id);

      const vehicleCategory = this.mapToViewModel(data);

      this.setState({ data: vehicleCategory });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(vehicleCategory: VehicleCategoryDto): SaveVehicleCategoryDto {
    return {
      name: vehicleCategory.name,
      vehicles: vehicleCategory.vehicles,
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? vehicleCategoryService.modifyVehicleCategory(this.id, this.state.data)
        : vehicleCategoryService.addVehicleCategory(this.state.data));
      toast.success("Jármű kategória sikeresen mentve.");
      this.props.history.push(ROUTE.VEHICLE_CATEGORIES);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a jármű kategóriát?", async () => {
      try {
        await vehicleCategoryService.removeVehicleCategory(this.id);
        toast.success("Jármű kategória sikeresen törölve.");
        this.props.history.replace(ROUTE.VEHICLE_CATEGORIES);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>

        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Megnevezés")}
          {this.renderMultiSelect(
            "vehicles",
            "Járművek",
            this.state.vehicles,
            "licensePlate"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default VehicleCategoryForm;
