import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import useAuth from "../../hooks/useAuth";

const TollServiceProvidersMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (
    !(
      user.hasModule(MODULE.OBU) ||
      user.hasModule(MODULE.EUROTOLL) ||
      user.hasModule(MODULE.EMYTO) ||
      user.hasModule(MODULE.TOLLCOLLECT) ||
      user.hasModule(MODULE.MYTO) ||
      user.hasModule(MODULE.HUGO)
    )
  )
    return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.TOLL_SERVICE_PROVIDER} icon={ICON.ROAD} />
      <div className="dropdown-menu">
        <MenuLink
          label={MENU.OBU_LOADER}
          url={ROUTE.OBU_LOADER}
          module={MODULE.OBU}
          user={user}
        />
        <MenuLink
          label={MENU.EUROTOLL_LOADER}
          url={ROUTE.EUROTOLL_LOADER}
          module={MODULE.EUROTOLL}
          user={user}
        />
        <MenuLink
          label={MENU.EMYTO_LOADER}
          url={ROUTE.EMYTO_LOADER}
          module={MODULE.EMYTO}
          user={user}
        />
        <MenuLink
          label={MENU.TOLLCOLLECT_LOADER}
          url={ROUTE.TOLLCOLLECT_LOADER}
          module={MODULE.TOLLCOLLECT}
          user={user}
        />
        <MenuLink
          label={MENU.MYTO_LOADER}
          url={ROUTE.MYTO_LOADER}
          module={MODULE.MYTO}
          user={user}
        />
        <MenuLink
          label={MENU.HUGO_LOADER}
          url={ROUTE.HUGO_LOADER}
          module={MODULE.HUGO}
          user={user}
        />
      </div>
    </div>
  );
};

export default TollServiceProvidersMenu;
