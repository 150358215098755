import React from "react";
import confirm from "../../utils/confirm";

interface Props {
  onRemove: () => void;
  label?: string;
  question?: string;
}

const RemoveButton = ({
  onRemove,
  label = "Törlés",
  question = "Biztos törölni szeretné?",
}: Props) => {
  return (
    <button
      type="submit"
      className="btn btn-danger mr-1"
      onClick={(e) => {
        e.preventDefault();
        confirm(question, onRemove);
      }}
    >
      <i className="fa fa-trash" /> {label}
    </button>
  );
};

export default RemoveButton;
