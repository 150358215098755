import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import * as MODULE from "../../constants/module";
import useAuth from "../../hooks/useAuth";

const DeliveryNoteMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  if (!user.hasModule(MODULE.DELIVERYNOTE)) return null;

  return (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.DELIVERY_NOTE} icon={ICON.TRUCK} />
      <div className="dropdown-menu">
        <MenuLink label={MENU.DELIVERY_NOTES} url={ROUTE.DELIVERY_NOTES} />
        <MenuLink label={MENU.PERIODS} url={ROUTE.DELIVERY_NOTE_PERIODS} />
        <MenuLink label={MENU.CORRECTIVE_NORMS} url={ROUTE.CORRECTIVE_NORMS} />
        <MenuLink label={MENU.DESTINATIONS} url={ROUTE.DESTINATIONS} />
        <MenuLink label={MENU.PLACES} url={ROUTE.PLACES} />
      </div>
    </div>
  );
};

export default DeliveryNoteMenu;
