import React, { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import DeliveryNoteDto from "../../models/deliveryNoteDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import { FullScreenContext } from "../../fullScreenContext";
import {
  ColumnModel,
  GridComponent,
  AggregateColumnModel,
} from "@syncfusion/ej2-react-grids";

import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGridOData from "../common/dataGridOData";
import { FLOAT2, formatNumber } from "../../utils/format";

const aggregates: AggregateColumnModel[] = [
  {
    field: "quantity",
    type: "Sum",
    format: "N2",
  },
  {
    field: "km",
    type: "Sum",
    format: "N0",
  },
  {
    field: "hours",
    type: "Sum",
    format: "N2",
  },
  {
    field: "coolingHours",
    type: "Sum",
    format: "N2",
  },
  {
    field: "saveQuantity",
    type: "Sum",
    format: "N0",
  },
  {
    field: "save",
    type: "Sum",
    format: "N0",
  },
  {
    field: "averageQuantity",
    type: "Custom",
    customAggregate: (data: any) => {
      console.log(data);
      const result = Array.isArray(data)
        ? data
        : data.items || data.result || data.records || data.result.records;

      const averageQuantity =
        (result.reduce((prev: number, current: DeliveryNoteDto) => {
          return prev + current.quantity;
        }, 0) /
          result.reduce((prev: number, current: DeliveryNoteDto) => {
            return prev + current.km;
          }, 0)) *
        100;

      return averageQuantity;
    },

    footerTemplate: (props: any) => {
      return <span>{formatNumber(props.Custom, FLOAT2)}</span>;
    },
  } as any,
  {
    field: "layeredWeight",
    type: "Sum",
    format: "N2",
  },
];

const columns: ColumnModel[] = [
  {
    field: "number",
    headerText: "Menetlevél szám",
    allowGrouping: false,
  },
  {
    field: "deliveryNotePeriod.name",
    headerText: "Időszak",
  },
  {
    field: "vehicle.licensePlate",
    headerText: "Jármű",
  },
  {
    field: "trailer.licensePlate",
    headerText: "Pótkocsi",
  },
  {
    field: "flightNumber",
    headerText: "Járat szám",
    allowGrouping: false,
  },
  {
    field: "driver.name",
    headerText: "Sofőr",
  },
  {
    field: "user.place.name",
    headerText: "Telephely",
  },
  {
    field: "destination.name",
    headerText: "Viszonylat",
  },
  {
    field: "startDate",
    headerText: "Indulási idő",
    type: "date",
    format: "yyyy.MM.dd HH:mm",
    allowGrouping: false,
  },
  {
    field: "endDate",
    headerText: "Érkezési idő",
    type: "date",
    format: "yyyy.MM.dd HH:mm",
    allowGrouping: false,
  },
  {
    field: "hours",
    headerText: "Menetlevél idő",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    field: "coolingHours",
    headerText: "Hűtés idő",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "startKm",
    headerText: "Kezdő km",
    format: "N0",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "endKm",
    headerText: "Záró km",
    format: "N0",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "km",
    headerText: "Megtett km",
    format: "N0",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "quantity",
    headerText: "Elhasznált üzemanyag liter",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "normQuantity",
    headerText: "Norma szerint elhasználható üzemanyag liter",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "averageQuantity",
    headerText: "Átlag fogyasztás",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "averageNormQuantity",
    headerText: "Norma szerinti átlag fogyasztás",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "saveQuantity",
    headerText: "Megtakarítás liter",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "save",
    headerText: "Megtakarítás forint",
    format: "N0",
    type: "number",
    allowGrouping: false,
  },
  {
    textAlign: "Right",
    field: "layeredWeight",
    headerText: "Rakott súly",
    format: "N2",
    type: "number",
    allowGrouping: false,
  },
];

const DeliveryNotes = ({ history }: RouteComponentProps) => {
  const [
    selectedDeliveryNote,
    setSelectedDeliveryNote,
  ] = useState<DeliveryNoteDto | null>(null);
  const grid = useRef<GridComponent | null>(null);
  const { setFullScreen } = useContext(FullScreenContext);

  useEffect(() => {
    if (setFullScreen) setFullScreen(true);

    return () => {
      if (setFullScreen) setFullScreen(false);
    };
  }, [setFullScreen]);

  const handleModify = () => {
    if (!selectedDeliveryNote) return;
    history.push(`${ROUTE.DELIVERY_NOTES}/${selectedDeliveryNote.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "menetlevelek.xlsx" });
  };

  const contextMenuClick = () => {
    if (!selectedDeliveryNote) return;
    window.open(`${ROUTE.DELIVERY_NOTES}/${selectedDeliveryNote.id}`, "_blank");
  };

  setDocumentTitle(MENU.DELIVERY_NOTES);

  return (
    <>
      <h1>{MENU.DELIVERY_NOTES}</h1>
      <NewLink to={`${ROUTE.DELIVERY_NOTES}/new`} label="Új menetlevél" />
      <ModifyButton
        disabled={false}
        onClick={handleModify}
        label="Menetlevél módosítása"
      />
      <ExcelExportButton onClick={handleExport} />

      <DataGridOData
        id="deliverynotes"
        columns={columns}
        aggregates={aggregates}
        api="/odata/deliveryNotes?$expand=deliveryNotePeriod,vehicle,trailer,driver,user($expand=place),destination"
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedDeliveryNote: DeliveryNoteDto) => {
          setSelectedDeliveryNote(selectedDeliveryNote);
        }}
        recordDoubleClick={handleModify}
        contextMenuItems={[
          {
            text: "Módosítás új lapon",
            target: ".e-content",
            id: "modifyNewPage",
          },
        ]}
        contextMenuClick={contextMenuClick}
      />
    </>
  );
};
export default DeliveryNotes;
