import React, { Component } from "react";
import { Link } from "react-router-dom";
import destinationService from "../../services/destinationService";
import Table from "../common/table";
import _ from "lodash";
import DestinationDto from "../../models/destinationDto";
import SortColumn from "../../models/sortColumn";
import path from "../../utils/path";
import Column from "../../models/column";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";

interface State {
  destinations: DestinationDto[];
  sortColumn: SortColumn;
}

class Destinations extends Component {
  state: State = {
    destinations: [],
    sortColumn: { path: path<DestinationDto>()("name"), order: "asc" }
  };

  columns: Column<DestinationDto>[] = [
    {
      path: "name",
      label: "Megnevezés",
      content: destination => (
        <Link to={`${ROUTE.DESTINATIONS}/${destination.id}`}>
          {destination.name}
        </Link>
      )
    }
  ];

  handleSort = (sortColumn: SortColumn) => this.setState({ sortColumn });

  async componentDidMount() {
    const { data: destinations } = await destinationService.getDestinations();
    this.setState({ destinations });
  }

  render() {
    setDocumentTitle(MENU.DESTINATIONS);

    const { destinations, sortColumn } = this.state;

    const sortedDestinations = _.orderBy(
      destinations,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <>
        <h1>{MENU.DESTINATIONS}</h1>
        <Link to={`${ROUTE.DESTINATIONS}/new`} className="btn btn-primary my-2">
          Új viszonylat
        </Link>
        <Table
          columns={this.columns}
          data={sortedDestinations}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </>
    );
  }
}

export default Destinations;
