import { create } from "apisauce";
import authStorage from "../auth/storage";

const client = create({
  baseURL: "/api",
});

client.addAsyncRequestTransform(async (request) => {
  const token = authStorage.getToken();
  if (!token) return;
  request.headers["x-auth-token"] = token;
});

export default {
  get: client.get,
  post: client.post,
  put: client.put,
  delete: client.delete,
};
