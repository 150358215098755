import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NavBar from './components/navBar';
import NotFound from './components/not-found';
import Home from './components/home';
import UserForm from './components/user/userForm';
import Users from './components/user/users';
import LoginForm from './components/loginForm';
import ProtectedRoute from './components/common/protectedRoute';

import Loader from './components/loader';

import Tanks from './components/local/tanks';
import TankForm from './components/local/tankForm';
import Suppliers from './components/local/suppliers';
import SupplierForm from './components/local/supplierForm';
import Supplies from './components/local/supplies';
import SupplyForm from './components/local/supplyForm';

import VehicleCategories from './components/report-wizard/vehicleCategories';
import VehicleCategoryForm from './components/report-wizard/vehicleCategoryForm';
import ProductCategories from './components/report-wizard/productCategories';
import ProductCategoryForm from './components/report-wizard/productCategoryForm';
import Reports from './components/report-wizard/reports';
import ReportForm from './components/report-wizard/reportForm';
import ReportView from './components/report-wizard/reportView';

import CommercialGasRefunds from './components/refund/commercialGasRefunds';
import CommercialGasRefundForm from './components/refund/commercialGasRefundForm';
import CommercialGasRefundView from './components/refund/commercialGasRefundView';

import ForeignTaxCountries from './components/refund/foreignTaxCountries';
import ForeignTaxProductCategories from './components/refund/foreignTaxProductCategories';
import ForeignTaxProductCategoryForm from './components/refund/foreignTaxProductCategoryForm';
import ForeignTaxRefunds from './components/refund/foreignTaxRefunds';
import ForeignTaxRefundForm from './components/refund/foreignTaxRefundForm';
import ForeignTaxRefundView from './components/refund/foreignTaxRefundView';

import DriverForm from './components/master-data/driverForm';
import Drivers from './components/master-data/drivers';
import Vehicles from './components/master-data/vehicles';
import VehicleForm from './components/master-data/vehicleForm';
import Files from './components/master-data/files';
import Invoices from './components/master-data/invoices';
import InvoiceForm from './components/master-data/invoiceForm';
import Companies from './components/master-data/companies';
import CompanyForm from './components/master-data/companyForm';
import TransactionTypes from './components/master-data/transactionTypes';
import TransactionTypeForm from './components/master-data/transactionTypeForm';
import Products from './components/master-data/products';
import ProductForm from './components/master-data/productForm';

import CorrectiveNorms from './components/delivery-note/correctiveNorms';
import CorrectiveNormForm from './components/delivery-note/correctiveNormForm';
import Destinations from './components/delivery-note/destinations';
import DestinationForm from './components/delivery-note/destinationForm';
import DeliveryNotes from './components/delivery-note/deliveryNotes';
import DeliveryNoteForm from './components/delivery-note/deliveryNoteForm';
import DeliveryNotePeriods from './components/delivery-note/deliveryNotePeriods';
import DeliveryNotePeriodForm from './components/delivery-note/deliveryNotePeriodForm';
import PlaceForm from './components/delivery-note/placeForm';
import Places from './components/delivery-note/places';

import ManualTransactions from './components/manual-transactions/manualTransactions';
import ManualTransactionForm from './components/manual-transactions/manualTransactionForm';
import UserPayload from './models/userPayload';
import * as ROUTE from './constants/route';
import { FullScreenContext } from './fullScreenContext';
import TrailerForm from './components/master-data/trailerForm';
import Trailers from './components/master-data/trailers';
import Tenants from './components/user/tenants';
import TenantForm from './components/user/tenantForm';
import AuthContext from './auth/context';

import authStorage from './auth/storage';
import http from './services/httpService';

// remove when use apiSauce everywhere
http.setJwt(authStorage.getToken());

const App = () => {
  const [user, setUser] = useState<UserPayload | null>(authStorage.getUser());
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <FullScreenContext.Provider value={{ setFullScreen }}>
        <ToastContainer />
        <NavBar />
        <main
          className={
            fullScreen
              ? 'bg-white rounded shadow my-3 p-3'
              : 'container bg-white rounded shadow my-3 p-3'
          }
        >
          <Switch>
            <Route path={ROUTE.LOGIN} component={LoginForm} />
            <ProtectedRoute path={ROUTE.REPORT_VIEW} component={ReportView} />
            <ProtectedRoute path={ROUTE.REPORT_FORM} component={ReportForm} />
            <ProtectedRoute path={ROUTE.REPORTS} component={Reports} />
            <ProtectedRoute
              path={ROUTE.PRODUCT_CATEGORY_FORM}
              component={ProductCategoryForm}
            />
            <ProtectedRoute
              path={ROUTE.PRODUCT_CATEGORIES}
              component={ProductCategories}
            />
            <ProtectedRoute
              path={ROUTE.VEHICLE_CATEGORY_FORM}
              component={VehicleCategoryForm}
            />
            <ProtectedRoute
              path={ROUTE.VEHICLE_CATEGORIES}
              component={VehicleCategories}
            />
            <ProtectedRoute path={ROUTE.SUPPLY_FORM} component={SupplyForm} />
            <ProtectedRoute path={ROUTE.SUPPLIES} component={Supplies} />
            <ProtectedRoute
              path={ROUTE.SUPPLIER_FORM}
              component={SupplierForm}
            />
            <ProtectedRoute path={ROUTE.SUPPLIERS} component={Suppliers} />
            <ProtectedRoute path={ROUTE.TANK_FORM} component={TankForm} />
            <ProtectedRoute path={ROUTE.TANKS} component={Tanks} />
            <ProtectedRoute
              path={ROUTE.HUNGARO_MOBIL_TANK_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="hungaro-mobil-tank"
                  label="Hungaro Mobil Tank"
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.WEB_TRANSPORT_LOADER}
              render={(props) => (
                <Loader {...props} type="web-transport" label="WebTransport" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.HALKER_LOADER}
              render={(props) => (
                <Loader {...props} type="halker" label="Halker" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.PSZOLG_LOADER}
              render={(props) => (
                <Loader {...props} type="pszolg" label="PSzolg" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EXPORT_LOADER}
              render={(props) => (
                <Loader {...props} type="export" label="Export" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.TSPAPIRUS_LOADER}
              render={(props) => (
                <Loader {...props} type="ts-papirus" label="TsPapirus" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.TS_LOADER}
              render={(props) => <Loader {...props} type="ts" label="Ts" />}
            />
            <ProtectedRoute
              path={ROUTE.SPRINT_LOADER}
              render={(props) => (
                <Loader {...props} type="sprint" label="Sprint" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.FER_LOADER}
              render={(props) => <Loader {...props} type="fer" label="Fer" />}
            />
            <ProtectedRoute
              path={ROUTE.SZALAI_LOADER}
              render={(props) => (
                <Loader {...props} type="szalai" label="Szalai" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.ATTWIN_LOADER}
              render={(props) => (
                <Loader {...props} type="attwin" label="Attwin" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.TA_LOADER}
              render={(props) => <Loader {...props} type="ta" label="Ta" />}
            />
            <ProtectedRoute
              path={ROUTE.BANDUR_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="bandur"
                  label="Bandur"
                  noFileSelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.OBU_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="obu"
                  label="Obu"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROTOLL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurotoll"
                  label="Eurotoll"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EMYTO_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="emyto"
                  label="Emyto"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.TOLLCOLLECT_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="tollcollect"
                  label="Tollcollect"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.MYTO_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="myto"
                  label="Myto"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.HUGO_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="hugo"
                  label="Hugo"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.MOL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="mol"
                  label="Mol"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.NAV_LOADER}
              render={(props) => <Loader {...props} type="nav" label="Nav" />}
            />
            <ProtectedRoute
              path={ROUTE.TACHOGRAPH_LOADER}
              render={(props) => (
                <Loader {...props} type="tachograph" label="TACHOGRAPH" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.ITRACK_LOADER}
              render={(props) => (
                <Loader {...props} type="itrack" label="ITRACK" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.OMV_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="omv"
                  label="Omv"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.DKV_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="dkv"
                  label="Dkv"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.OIL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="oil"
                  label="Oil"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.MOBIL_PETROL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="mobil-petrol"
                  label="Mobil petrol"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.MOTO95_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="moto95"
                  label="Moto 95"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.ARIS_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="aris"
                  label="Aris"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.AS24_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="as24"
                  label="AS24"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.AS24_NEW_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="as24-new"
                  label="AS24"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.LUKOIL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="lukoil"
                  label="Lukoil"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.OPLUS_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="oplus"
                  label="Oplus"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.SHELL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="shell"
                  label="Shell"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.SHELL_ORIGINAL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="shell-original"
                  label="Shell Original"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.SHELL_ORIGINAL2_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="shell-original-2"
                  label="Shell Original 2"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.FLEETCOR_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="fleetcor"
                  label="Fleetcor"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.FLEETCOR_2024_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="fleetcor2024"
                  label="Fleetcor 2024"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag"
                  label="Eurowag"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_2021_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag2021"
                  label="Eurowag 2021"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_CSV_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag-csv"
                  label="Eurowag Csv"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_BONUS_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag-bonus"
                  label="Eurowag bonus"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_MASTER_CARD_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag-master-card"
                  label="Eurowag master card"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_TOLL_GB_SOURCE_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag-toll-gb-source"
                  label="Eurowag toll gb source"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EUROWAG_TOLL_CH_SOURCE_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="eurowag-toll-ch-source"
                  label="Eurowag toll ch source"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.WSZL_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="wszl"
                  label="WSZL"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.WSZL_NEW_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="wszl-new" 
                  label="WSZL"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.IMIX_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="imix"
                  label="IMIX"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.VARGA_ES_TARSA_LOADER}
              render={(props) => (
                <Loader
                  {...props}
                  type="varga-es-tarsa"
                  label="Varga és Társa"
                  companySelect={true}
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.KONTENERKUT_LOADER}
              render={(props) => (
                <Loader {...props} type="kontenerkut" label="Konténerkút" />
              )}
            />
            <ProtectedRoute
              path={ROUTE.EDO_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="edo" 
                  label="EDO"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.JOGA_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="joga" 
                  label="Joga"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.TRANSDANUBIA_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="transdanubia" 
                  label="Transdanubia"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.DUVENBECK_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="duvenbeck" 
                  label="Duvenbeck"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.UTA_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="uta" 
                  label="UTA"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.UTA_NORBI_LOADER}
              render={(props) => (
                <Loader 
                  {...props} 
                  type="uta-norbi" 
                  label="UTA-Norbi"
                  companySelect={true} 
                />
              )}
            />
            <ProtectedRoute
              path={ROUTE.COMMERCIAL_GAS_REFUND_VIEW}
              component={CommercialGasRefundView}
            />
            <ProtectedRoute
              path={ROUTE.COMMERCIAL_GAS_REFUND_FORM}
              component={CommercialGasRefundForm}
            />
            <ProtectedRoute
              path={ROUTE.COMMERCIAL_GAS_REFUNDS}
              component={CommercialGasRefunds}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_PRODUCT_CATEGORY_FORM}
              component={ForeignTaxProductCategoryForm}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_PRODUCT_CATEGORIES}
              component={ForeignTaxProductCategories}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_REFUND_VIEW}
              component={ForeignTaxRefundView}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_REFUND_FORM}
              component={ForeignTaxRefundForm}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_REFUNDS}
              component={ForeignTaxRefunds}
            />
            <ProtectedRoute
              path={ROUTE.FOREIGN_TAX_COUNTRIES}
              component={ForeignTaxCountries}
            />
            <ProtectedRoute
              path={ROUTE.CORRECTIVE_NORM_FORM}
              component={CorrectiveNormForm}
            />
            <ProtectedRoute
              path={ROUTE.CORRECTIVE_NORMS}
              component={CorrectiveNorms}
            />
            <ProtectedRoute
              path={ROUTE.DESTINATION_FORM}
              component={DestinationForm}
            />
            <ProtectedRoute
              path={ROUTE.DESTINATIONS}
              component={Destinations}
            />
            <ProtectedRoute
              path={ROUTE.DELIVERY_NOTE_FORM}
              component={DeliveryNoteForm}
            />
            <ProtectedRoute
              path={ROUTE.DELIVERY_NOTES}
              component={DeliveryNotes}
            />
            <ProtectedRoute
              path={ROUTE.DELIVERY_NOTE_PERIOD_FORM}
              component={DeliveryNotePeriodForm}
            />
            <ProtectedRoute
              path={ROUTE.DELIVERY_NOTE_PERIODS}
              component={DeliveryNotePeriods}
            />
            <ProtectedRoute path={ROUTE.PLACE_FORM} component={PlaceForm} />
            <ProtectedRoute path={ROUTE.PLACES} component={Places} />
            <ProtectedRoute path={ROUTE.INVOICE_FORM} component={InvoiceForm} />
            <ProtectedRoute path={ROUTE.INVOICES} component={Invoices} />
            <ProtectedRoute path={ROUTE.DRIVER_FORM} component={DriverForm} />
            <ProtectedRoute path={ROUTE.DRIVERS} component={Drivers} />
            <ProtectedRoute path={ROUTE.VEHICLE_FORM} component={VehicleForm} />
            <ProtectedRoute path={ROUTE.VEHICLES} component={Vehicles} />
            <ProtectedRoute path={ROUTE.TRAILER_FORM} component={TrailerForm} />
            <ProtectedRoute path={ROUTE.TRAILERS} component={Trailers} />
            <ProtectedRoute path={ROUTE.COMPANY_FORM} component={CompanyForm} />
            <ProtectedRoute path={ROUTE.COMPANIES} component={Companies} />
            <ProtectedRoute path={ROUTE.FILES} component={Files} />
            <ProtectedRoute
              path={ROUTE.TRANSACTION_TYPE_FORM}
              component={TransactionTypeForm}
            />
            <ProtectedRoute
              path={ROUTE.TRANSACTION_TYPES}
              component={TransactionTypes}
            />
            <ProtectedRoute path={ROUTE.PRODUCT_FORM} component={ProductForm} />
            <ProtectedRoute path={ROUTE.PRODUCTS} component={Products} />

            <ProtectedRoute
              path={ROUTE.MANUAL_TRANSACTION_FORM}
              component={ManualTransactionForm}
            />
            <ProtectedRoute
              path={ROUTE.MANUAL_TRANSACTIONS}
              component={ManualTransactions}
            />
            <ProtectedRoute path={ROUTE.USER_FORM} component={UserForm} />
            <ProtectedRoute path={ROUTE.USERS} component={Users} />
            <ProtectedRoute path={ROUTE.TENANT_FORM} component={TenantForm} />
            <ProtectedRoute path={ROUTE.TENANTS} component={Tenants} />
            <ProtectedRoute path={ROUTE.HOME} component={Home} />
            <ProtectedRoute path={ROUTE.NOT_FOUND} component={NotFound} />
            <Redirect from={ROUTE.ROOT} exact to={ROUTE.HOME} />
            <Redirect to={ROUTE.NOT_FOUND} />
          </Switch>
        </main>
      </FullScreenContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
