import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import Column from "../../models/column";
import SortColumn from "../../models/sortColumn";
import Option from "../../models/option";

interface Props<T extends Option> {
  data: T[];
  columns: Column<T>[];
  sortColumn: SortColumn;
  onSort(sortColumn: SortColumn): void;
}

const Table = <T extends Option>({
  data,
  columns,
  sortColumn,
  onSort
}: Props<T>) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <TableBody data={data} columns={columns} />
      </table>
    </div>
  );
};

export default Table;
