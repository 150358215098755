// FUEL CARDS
export const MOL_LOADER = '/mol-loader';
export const EUROWAG_LOADER = '/eurowag-loader';
export const EUROWAG_2021_LOADER = '/eurowag-2021-loader';
export const EUROWAG_CSV_LOADER = '/eurowag-csv-loader';
export const EUROWAG_BONUS_LOADER = '/eurowag-bonus-loader';
export const EUROWAG_MASTER_CARD_LOADER = '/eurowag-master-card-loader';
export const EUROWAG_TOLL_GB_SOURCE_LOADER = '/eurowag-toll-gb-source-loader';
export const EUROWAG_TOLL_CH_SOURCE_LOADER = '/eurowag-toll-ch-source-loader';
export const SHELL_LOADER = '/shell-loader';
export const SHELL_ORIGINAL_LOADER = '/shell-original--loader';
export const SHELL_ORIGINAL2_LOADER = '/shell-original-2-loader';
export const OMV_LOADER = '/omv-loader';
export const DKV_LOADER = '/dkv-loader';
export const OIL_LOADER = '/oil-loader';
export const MOBIL_PETROL_LOADER = '/mobil-petrol-loader';
export const FLEETCOR_LOADER = '/fleetcor-loader';
export const MOTO95_LOADER = '/moto95-loader';
export const ARIS_LOADER = '/aris-loader';
export const AS24_LOADER = '/as24-loader';
export const AS24_NEW_LOADER = '/as24-new-loader';
export const LUKOIL_LOADER = '/lukoil-loader';
export const OPLUS_LOADER = '/oplus-loader';
export const WSZL_LOADER = '/wszl-loader';
export const WSZL_NEW_LOADER = '/wszl-new-loader';
export const IMIX_LOADER = '/imix-loader';
export const VARGA_ES_TARSA_LOADER = '/varga-es-tarsa-loader';
export const EDO_LOADER = '/edo-loader';
export const FLEETCOR_2024_LOADER = '/fleetcor-2024-loader';
export const JOGA_LOADER = '/joga-loader';
export const TRANSDANUBIA_LOADER = '/transdanubia-loader';
export const DUVENBECK_LOADER = '/duvenbeck-loader';
export const UTA_LOADER = '/uta-loader';
export const UTA_NORBI_LOADER = '/uta-norbi-loader';

// NAV
export const NAV_LOADER = '/nav-loader';

// TRACKING
export const TACHOGRAPH_LOADER = '/tachograph-loader';
export const ITRACK_LOADER = '/itrack-loader';

// LOCAL
export const HUNGARO_MOBIL_TANK_LOADER = '/hungaro-mobil-tank-loader';
export const WEB_TRANSPORT_LOADER = '/web-transport-loader';
export const HALKER_LOADER = '/halker-loader';
export const PSZOLG_LOADER = '/pszolg-loader';
export const EXPORT_LOADER = '/export-loader';
export const TSPAPIRUS_LOADER = '/ts-papirus-loader';
export const TS_LOADER = '/ts-loader';
export const SPRINT_LOADER = '/sprint-loader';
export const FER_LOADER = '/fer-loader';
export const SZALAI_LOADER = '/szalai-loader';
export const ATTWIN_LOADER = '/attwin-loader';
export const TA_LOADER = '/ta-loader';
export const BANDUR_LOADER = '/bandur-loader';
export const KONTENERKUT_LOADER = '/kontenerkut-loader'

export const TANKS = '/tanks';
export const TANK_FORM = `${TANKS}/:id`;

export const SUPPLIERS = '/suppliers';
export const SUPPLIER_FORM = `${SUPPLIERS}/:id`;

export const SUPPLIES = '/supplies';
export const SUPPLY_FORM = `${SUPPLIES}/:id`;

// TOLL SERVICE PROVIDER
export const OBU_LOADER = '/obu-loader';
export const EUROTOLL_LOADER = '/eurotoll-loader';
export const EMYTO_LOADER = '/emyto-loader';
export const TOLLCOLLECT_LOADER = '/tollcollect-loader';
export const MYTO_LOADER = '/myto-loader';
export const HUGO_LOADER = '/hugo-loader';

// MANUAL TRANSACTIONS
export const MANUAL_TRANSACTIONS = '/manual-transactions';
export const MANUAL_TRANSACTION_FORM = `${MANUAL_TRANSACTIONS}/:id`;

// REPORTS
export const REPORTS = '/reports';
export const REPORT_FORM = `${REPORTS}/:id`;
export const REPORT_VIEW = `${REPORT_FORM}/view`;

export const VEHICLE_CATEGORIES = '/vehicle-categories';
export const VEHICLE_CATEGORY_FORM = `${VEHICLE_CATEGORIES}/:id`;

export const PRODUCT_CATEGORIES = '/product-categories';
export const PRODUCT_CATEGORY_FORM = `${PRODUCT_CATEGORIES}/:id`;

// REFUNDS
export const COMMERCIAL_GAS_REFUNDS = '/commercial-gas-refunds';
export const COMMERCIAL_GAS_REFUND_FORM = `${COMMERCIAL_GAS_REFUNDS}/:id`;
export const COMMERCIAL_GAS_REFUND_VIEW = `${COMMERCIAL_GAS_REFUND_FORM}/view`;

export const FOREIGN_TAX_COUNTRIES = '/foreign-tax-countries';
export const FOREIGN_TAX_REFUNDS = `${FOREIGN_TAX_COUNTRIES}/:countryId/refunds`;
export const FOREIGN_TAX_REFUND_FORM = `${FOREIGN_TAX_REFUNDS}/:foreignTaxRefundId`;
export const FOREIGN_TAX_REFUND_VIEW = `${FOREIGN_TAX_REFUND_FORM}/view`;
export const FOREIGN_TAX_PRODUCT_CATEGORIES = `${FOREIGN_TAX_COUNTRIES}/:countryId/product-categories`;
export const FOREIGN_TAX_PRODUCT_CATEGORY_FORM = `${FOREIGN_TAX_PRODUCT_CATEGORIES}/:productCategoryId`;

// MASTER
export const DRIVERS = '/drivers';
export const DRIVER_FORM = `${DRIVERS}/:id`;

export const VEHICLES = '/vehicles';
export const VEHICLE_FORM = `${VEHICLES}/:id`;

export const INVOICES = '/invoices';
export const INVOICE_FORM = `${INVOICES}/:id`;

export const COMPANIES = '/companies';
export const COMPANY_FORM = `${COMPANIES}/:id`;

export const FILES = '/files';

export const TRANSACTION_TYPES = '/transaction-types';
export const TRANSACTION_TYPE_FORM = `${TRANSACTION_TYPES}/:id`;

export const PRODUCTS = '/products';
export const PRODUCT_FORM = `${PRODUCTS}/:id`;

export const TRAILERS = '/trailers';
export const TRAILER_FORM = `${TRAILERS}/:id`;

// DELIVERY NOTE
export const DELIVERY_NOTES = '/delivery-notes';
export const DELIVERY_NOTE_FORM = `${DELIVERY_NOTES}/:id`;

export const DELIVERY_NOTE_PERIODS = '/delivery-note-periods';
export const DELIVERY_NOTE_PERIOD_FORM = `${DELIVERY_NOTE_PERIODS}/:id`;

export const CORRECTIVE_NORMS = '/corrective-norms';
export const CORRECTIVE_NORM_FORM = `${CORRECTIVE_NORMS}/:id`;

export const DESTINATIONS = '/destinations';
export const DESTINATION_FORM = `${DESTINATIONS}/:id`;

export const PLACES = '/places';
export const PLACE_FORM = `${PLACES}/:id`;

// USER
export const USERS = '/users';
export const USER_FORM = `${USERS}/:id`;
export const LOGOUT = '/logout';
export const LOGIN = '/login';
export const TENANTS = '/tenants';
export const TENANT_FORM = `${TENANTS}/:id`;

// ETC
export const HOME = '/home';
export const NOT_FOUND = '/not-found';
export const ROOT = '/';
