import client from "../api/client";
import ModuleDto from "../models/moduleDto";

const baseUrl = "/modules";

function getModules() {
  return client.get<ModuleDto[]>(baseUrl);
}

export default {
  getModules,
};
