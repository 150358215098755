import React, { Component } from "react";
import { Link } from "react-router-dom";
import countryService from "../../services/countryService";
import Table from "../common/table";
import _ from "lodash";
import SortColumn from "../../models/sortColumn";
import CountryDto from "../../models/countryDto";
import Column from "../../models/column";
import path from "../../utils/path";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";

interface State {
  countries: CountryDto[];
  sortColumn: SortColumn;
}

class ForeignTaxCountries extends Component {
  state: State = {
    countries: [],
    sortColumn: { path: path<CountryDto>()("name"), order: "asc" }
  };

  columns: Column<CountryDto>[] = [
    {
      path: path<CountryDto>()("name"),
      label: "Megnevezés",
      content: country => (
        <Link to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${country.id}/refunds`}>
          {country.name}
        </Link>
      )
    },
    {
      path: path<CountryDto>()("code"),
      label: "Kód"
    },
    {
      key: "categories",
      label: "",
      style: { width: "250px" },
      content: country => (
        <Link
          className="btn btn-primary"
          to={`${ROUTE.FOREIGN_TAX_COUNTRIES}/${country.id}/product-categories`}
        >
          <i className="fa fa-sitemap" /> Termékek kategorizálása
        </Link>
      )
    }
  ];

  handleSort = (sortColumn: SortColumn) => {
    this.setState({ sortColumn });
  };

  async componentDidMount() {
    const { data: countries } = await countryService.getForeignTaxCountries();
    this.setState({ countries });
  }

  render() {
    setDocumentTitle(MENU.FOREIGN_TAX_COUNTRIES);

    const { countries, sortColumn } = this.state;

    const sortedData = _.orderBy(
      countries,
      [sortColumn.path],
      [sortColumn.order]
    );

    return (
      <React.Fragment>
        <h1>{MENU.FOREIGN_TAX_COUNTRIES}</h1>
        <Table
          columns={this.columns}
          data={sortedData}
          sortColumn={sortColumn}
          onSort={this.handleSort}
        />
      </React.Fragment>
    );
  }
}

export default ForeignTaxCountries;
