import React from "react";
import { TEXT_FILE } from "../../constants/icon";

interface Props {
  onClick: () => void;
}

const ImpExportButton = ({ onClick }: Props) => (
  <button className="btn btn-primary my-2 mr-2" onClick={onClick}>
    <i className={TEXT_FILE} /> Imp export
  </button>
);

export default ImpExportButton;
