import React from "react";

interface Props {
  onClick: () => void;
}

const ExcelExportButton = ({ onClick }: Props) => (
  <button className="btn btn-primary my-2 mr-2" onClick={onClick}>
    <i className="fa fa-file-excel-o" /> Excel export
  </button>
);

export default ExcelExportButton;
