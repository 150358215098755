import React from "react";
import MenuHeader from "../common/menuHeader";
import MenuLink from "../common/menuLink";
import * as MENU from "../../constants/menu";
import * as ICON from "../../constants/icon";
import * as ROUTE from "../../constants/route";
import { isAdminTenant } from "../../utils/tenant";
import useAuth from "../../hooks/useAuth";

const MasterMenu = () => {
  const { user } = useAuth();

  if (!user) return null;

  return !isAdminTenant() ? (
    <div className="nav-item dropdown">
      <MenuHeader label={MENU.MASTER} icon={ICON.DATABASE} />
      <div className="dropdown-menu">
        <MenuLink label={MENU.DRIVERS} url={ROUTE.DRIVERS} />
        <MenuLink label={MENU.VEHICLES} url={ROUTE.VEHICLES} />
        <MenuLink label={MENU.INVOICES} url={ROUTE.INVOICES} />
        <MenuLink label={MENU.COMPANIES} url={ROUTE.COMPANIES} />
        <MenuLink label={MENU.FILES} url={ROUTE.FILES} />
        <MenuLink
          label={MENU.TRANSACTION_TYPES}
          url={ROUTE.TRANSACTION_TYPES}
        />
        <MenuLink label={MENU.PRODUCTS} url={ROUTE.PRODUCTS} />
        <MenuLink label={MENU.TRAILERS} url={ROUTE.TRAILERS} />
      </div>
    </div>
  ) : null;
};

export default MasterMenu;
