import moment from "moment";
import numeral from "numeral";
import { isNil } from "lodash";

export const YYYY_MM_DD = "YYYY.MM.DD";
export const YYYY_MM_DD_HH_mm_ss = "YYYY.MM.DD HH:mm:ss";
export const YYYY_MM_DD_HH_mm = "YYYY.MM.DD HH:mm";
export const JSON_DATE = "YYYY-MM-DDTHH:mm:ss";
export const INT = "0,0";
export const FLOAT2 = "0,0.00";
export const FLOAT3 = "0,0.000";
export const FLOAT4 = "0,0.0000";

export const formatDate = (
  from: string,
  fromFormat: string,
  toFormat: string
): string => {
  if (!from) return "";
  return moment(from, fromFormat).format(toFormat);
};

export const formatBool = (flag: boolean): string => {
  return flag ? "Igen" : "Nem";
};

export const formatNumber = (from: number | string, format: string): string => {
  if (isNil(from) || from === "") return "";
  return numeral(from).format(format);
};

export const numberToString = (from: number | string): string => {
  if (!from) return "";
  return from.toString();
};
