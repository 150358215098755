import httpService from "./httpService";
import FuelTypeDto from "../models/fuelTypeDto";

const baseUrl = "/api/fuel-types";

function getFuelTypes() {
  return httpService.get<FuelTypeDto[]>(baseUrl);
}

export default {
  getFuelTypes
};
