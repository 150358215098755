import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import VehicleDto from '../../models/vehicleDto';
import * as MENU from '../../constants/menu';
import * as ROUTE from '../../constants/route';
import setDocumentTitle from '../../utils/document';
import { ColumnModel, GridComponent } from '@syncfusion/ej2-react-grids';
import ModifyButton from '../common/modifyButton';
import ExcelExportButton from '../common/excelExportButton';
import DataGridOData from '../common/dataGridOData';

const columns: ColumnModel[] = [
  {
    headerText: 'Rendszám',
    field: 'licensePlate',
  },
  {
    headerText: 'Cég',
    field: 'companyName',
  },
  {
    headerText: 'Ker.gáz. kategória',
    field: 'commercialGasVehicleCategory.name',
  },
  {
    headerText: 'Súly',
    field: 'weight',
    textAlign: 'Right',
    format: 'N0',
    type: 'number',
  },
  {
    headerText: 'Hengerűrtartalom',
    field: 'engineCapacity',
    textAlign: 'Right',
    format: 'N0',
    type: 'number',
  },
  {
    headerText: 'Alapnorma',
    field: 'basicNorm',
    textAlign: 'Right',
    format: 'N4',
    type: 'number',
  },
  {
    headerText: 'Hűtési norma',
    field: 'coolingNorm',
    textAlign: 'Right',
    format: 'N4',
    type: 'number',
  },
  {
    headerText: 'Bejelentés dátuma',
    field: 'refundFrom',
    type: 'date',
    format: 'yyyy.MM.dd',
  },
  {
    headerText: 'Kijelentés dátuma',
    field: 'refundTo',
    type: 'date',
    format: 'yyyy.MM.dd',
  },
];

const Vehicles = ({ history }: RouteComponentProps) => {
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleDto | null>(
    null
  );
  const grid = useRef<GridComponent | null>(null);

  const handleModify = () => {
    if (!selectedVehicle) return;
    history.push(`${ROUTE.VEHICLES}/${selectedVehicle.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: 'jarmuvek.xlsx' });
  };

  setDocumentTitle(MENU.VEHICLES);

  return (
    <>
      <h1>{MENU.VEHICLES}</h1>
      <ModifyButton
        disabled={!selectedVehicle}
        onClick={handleModify}
        label="Jármű módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGridOData
        id="vehicles1"
        columns={columns}
        api="/odata/vehicles?$expand=commercialGasVehicleCategory"
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedVehicle: VehicleDto) => {
          setSelectedVehicle(selectedVehicle);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Vehicles;
