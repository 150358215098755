import httpService from "./httpService";
import PlaceDto from "../models/placeDto";
import SavePlaceDto from "../models/savePlaceDto";

const baseUrl = "/api/places";

function getPlaces() {
  return httpService.get<PlaceDto[]>(baseUrl);
}

function getPlace(id: string) {
  return httpService.get<PlaceDto>(`${baseUrl}/${id}`);
}

function addPlace(savePlaceDto: SavePlaceDto) {
  return httpService.post(baseUrl, savePlaceDto);
}

function modifyPlace(id: string, savePlaceDto: SavePlaceDto) {
  return httpService.put(`${baseUrl}/${id}`, savePlaceDto);
}

function removePlace(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getPlaces,
  getPlace,
  addPlace,
  modifyPlace,
  removePlace
};
