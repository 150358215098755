import React from "react";
import { toast } from "react-toastify";
import Form, { FormState } from "../common/form";
import tankService from "../../services/tankService";
import fuelTypeService from "../../services/fuelTypeService";
import confirm from "../../utils/confirm";
import SaveTankDto from "../../models/saveTankDto";
import FuelTypeDto from "../../models/fuelTypeDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import TankDto from "../../models/tankDto";
import { numberToString } from "../../utils/format";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";

interface State extends FormState<SaveTankDto> {
  data: SaveTankDto;
  fuelTypes: FuelTypeDto[];
}

class TankForm extends Form<
  SaveTankDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  id = this.props.match.params.id === "new" ? "" : this.props.match.params.id;

  state: State = {
    data: {
      name: "",
      fuelTypeId: ""
    },
    fuelTypes: [],
    errors: {}
  };

  schema = yup.object().shape<SaveTankDto>({
    name: yup
      .string()
      .required()
      .max(255),
    fuelTypeId: yup.string().required()
  });

  async componentDidMount() {
    await this.populateFueltypes();
    if (this.id) await this.populateTank();
  }

  async populateFueltypes() {
    const { data: fuelTypes } = await fuelTypeService.getFuelTypes();
    this.setState({ fuelTypes });
  }

  async populateTank() {
    try {
      const { data } = await tankService.getTank(this.id);
      const tank = this.mapToViewModel(data);
      this.setState({ data: tank });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(tank: TankDto): SaveTankDto {
    return {
      name: tank.name,
      fuelTypeId: numberToString(tank.fuelTypeId)
    };
  }

  doSubmit = async () => {
    const { data } = this.state;

    try {
      await (this.id
        ? tankService.modifyTank(this.id, data)
        : tankService.addTank(data));
      toast.success("Tartály sikeresen mentve.");
      this.props.history.push(ROUTE.TANKS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    confirm("Biztos törölni szeretné a tartályt?", async () => {
      try {
        await tankService.removeTank(this.id);
        toast.success("Tartály sikeresen törölve.");
        this.props.history.replace(ROUTE.TANKS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    const { data } = this.state;

    const title = this.id ? `${data.name} tartály módosítása` : "Új tartály";
    setDocumentTitle(title);

    return (
      <div>
        <h1>{title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderSelect(
            "fuelTypeId",
            <Required label="Üzemanyag típus" />,
            this.state.fuelTypes,
            "name"
          )}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default TankForm;
