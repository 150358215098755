import http from "./httpService";
import CommercialGasVehicleCategoryDto from "../models/commercialGasVehicleCategoryDto";
import { AxiosResponse } from "axios";

const baseUrl = "/api/commercial-gas-vehicle-categories";

function getCommercialGasVehicleCategories(): Promise<
  AxiosResponse<CommercialGasVehicleCategoryDto[]>
> {
  return http.get<CommercialGasVehicleCategoryDto[]>(baseUrl);
}

export default {
  getCommercialGasVehicleCategories
};
