import React from "react";
import { toast } from "react-toastify";
import Form from "../common/form";
import destinationService from "../../services/destinationService";
import confirm from "../../utils/confirm";
import SaveDestinationDto from "../../models/saveDestinationDto";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";
import * as ROUTE from "../../constants/route";
import setDocumentTitle from "../../utils/document";
import Required from "../common/required";
import DestinationDto from "../../models/destinationDto";

const initialState = {
  data: {
    name: ""
  } as SaveDestinationDto,
  errors: {}
};

type State = typeof initialState;

class DestinationForm extends Form<
  SaveDestinationDto,
  RouteComponentProps<{ id: string }>,
  State
> {
  get title() {
    return this.id
      ? `${this.state.data.name} viszonylat módosítása`
      : "Új viszonylat";
  }
  get id() {
    return this.props.match.params.id === "new"
      ? ""
      : this.props.match.params.id;
  }

  readonly state: State = initialState;

  schema = yup.object().shape<SaveDestinationDto>({
    name: yup
      .string()
      .required()
      .max(255)
  });

  async componentDidMount() {
    if (this.id) await this.populateDestination();
  }

  async populateDestination() {
    try {
      const { data } = await destinationService.getDestination(this.id);
      const destination = this.mapToViewModel(data);
      this.setState({ data: destination });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  mapToViewModel(destination: DestinationDto): SaveDestinationDto {
    return {
      name: destination.name
    };
  }

  doSubmit = async () => {
    try {
      await (this.id
        ? destinationService.modifyDestination(this.id, this.state.data)
        : destinationService.addDestination(this.state.data));
      toast.success("Viszonylat sikeresen mentve.");
      this.props.history.push(ROUTE.DESTINATIONS);
    } catch (ex) {
      this.handleError(ex);
    }
  };

  handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    confirm("Biztos törölni szeretné a viszonylatot?", async () => {
      try {
        await destinationService.removeDestination(this.id);
        toast.success("Viszonylat sikeresen törölve.");
        this.props.history.replace(ROUTE.DESTINATIONS);
      } catch (ex) {
        this.handleError(ex);
      }
    });
  };

  render() {
    setDocumentTitle(this.title);

    return (
      <div>
        <h1>{this.title}</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", <Required label="Megnevezés" />)}
          {this.renderButton("Mentés")}
          {this.id && (
            <button onClick={this.handleRemove} className="btn btn-danger ml-2">
              Törlés
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default DestinationForm;
