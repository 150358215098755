import httpService from "./httpService";
import InvoiceDto from "../models/invoiceDto";
import SaveInvoiceDto from "../models/saveInvoiceDto";

const baseUrl = "/api/invoices";

function getInvoices() {
  return httpService.get<InvoiceDto[]>(baseUrl);
}

function getManualInvoices() {
  return httpService.get<InvoiceDto[]>(`${baseUrl}/manual`);
}

function getInvoice(id: string) {
  return httpService.get<InvoiceDto>(`${baseUrl}/${id}`);
}

function modifyInvoice(id: string, saveInvoiceDto: SaveInvoiceDto) {
  return httpService.put(`${baseUrl}/${id}`, saveInvoiceDto);
}

function removeInvoice(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function addInvoice(invoice: SaveInvoiceDto) {
  return httpService.post(baseUrl, invoice);
}

function uploadFile(id: string, file: Blob) {
  const formData = new FormData();
  formData.append("file", file);
  return httpService.post(`${baseUrl}/${id}/file`, formData);
}

function removeFile(id: string) {
  return httpService.delete(`${baseUrl}/${id}/file`);
}

function downloadFile(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/file`, {
    responseType: "blob",
  });
}

export default {
  getInvoice,
  getManualInvoices,
  getInvoices,
  addInvoice,
  removeInvoice,
  modifyInvoice,
  uploadFile,
  removeFile,
  downloadFile,
};
