import httpService from "./httpService";
import TransactionFileDto from "../models/transactionFileDto";

const baseUrl = "/api/transaction-files";

function getTransactionFiles() {
  return httpService.get<TransactionFileDto[]>(baseUrl);
}

function refreshTransactionFile(id: string) {
  return httpService.post(`${baseUrl}/${id}/refresh`);
}

function removeTransactionFile(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

function downloadTransactionFile(id: string) {
  return httpService.get<BlobPart>(`${baseUrl}/${id}/download`, {
    responseType: "blob",
  });
}

export default {
  getTransactionFiles,
  downloadTransactionFile,
  refreshTransactionFile,
  removeTransactionFile,
};
