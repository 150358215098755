import React, { useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";

import NewLink from "../common/newLink";
import ModifyButton from "../common/modifyButton";
import ViewButton from "../common/viewButton";
import ExcelExportButton from "../common/excelExportButton";
import DataGrid from "../common/dataGrid";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import setDocumentTitle from "../../utils/document";

import ReportDto from "../../models/reportDto";
import reportService from "../../services/reportService";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
];

const Reports = ({ history }: RouteComponentProps) => {
  const [reports, setReports] = useState<ReportDto[]>([]);
  const [selectedReport, setSelectedReport] = useState<ReportDto | null>(null);
  const grid = useRef<GridComponent>();

  useEffect(() => {
    const populateReports = async () => {
      const { data } = await reportService.getReports();
      setReports(data);
    };

    populateReports();
  }, []);

  const handleExcelExport = () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "riportok.xlsx" });
  };

  const handleView = () => {
    if (!selectedReport) return;
    history.push(`${ROUTE.REPORTS}/${selectedReport.id}/view`);
  };

  const handleModify = () => {
    if (!selectedReport) return;
    history.push(`${ROUTE.REPORTS}/${selectedReport.id}`);
  };

  if (!reports) return <>Betöltés...</>;

  setDocumentTitle(MENU.REPORTS);

  return (
    <>
      <h1>{MENU.REPORTS}</h1>
      <NewLink to={`${ROUTE.REPORTS}/new`} label="Új riport" />
      <ModifyButton
        disabled={!selectedReport}
        onClick={handleModify}
        label="Riport módosítása"
      />
      <ViewButton
        disabled={!selectedReport}
        onClick={handleView}
        label="Riport megtekintése"
      />
      <ExcelExportButton onClick={handleExcelExport} />
      <DataGrid
        id="reports"
        columns={columns}
        data={reports}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedReport: ReportDto) => {
          setSelectedReport(selectedReport);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default Reports;
