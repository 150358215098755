import React from "react";
import { Typeahead, TypeaheadLabelKey } from "react-bootstrap-typeahead";
import Option from "../../models/option";

interface Props<T extends Option> {
  name: string;
  label: string | JSX.Element;
  error: string;
  options: T[];
  selected: T[];
  labelKey: TypeaheadLabelKey<T>;
  onRefresh?: () => void;
  filterBy?: string[];

  onChange: (selected: T[]) => void;
  disabled: boolean;
}

const Select = <T extends Option>({
  name,
  label,
  error,
  options,
  selected,
  onChange,
  labelKey,
  onRefresh,
  filterBy,
  disabled = false,
}: Props<T>) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="input-group">
        <Typeahead
          id={name}
          options={options}
          labelKey={labelKey}
          selected={selected}
          clearButton={true}
          emptyLabel="Nincs megjeleníthető találat."
          paginationText="További találatok megjelenítése..."
          onChange={onChange}
          isInvalid={error ? true : false}
          filterBy={filterBy}
          disabled={disabled}
        />
        {onRefresh && (
          <div className="input-group-append">
            <button
              className="btn btn-default"
              type="button"
              onClick={onRefresh}
            >
              <i className="fa fa-refresh" />
            </button>
          </div>
        )}
      </div>

      {error && (
        <div style={{ display: "block" }} className="invalid-feedback">
          {error}
        </div>
      )}
    </div>
  );
};

export default Select;
