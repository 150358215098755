import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import transactionTypeService from "../../services/transactionTypeService";
import TransactionTypeDto from "../../models/transactionTypeDto";
import setDocumentTitle from "../../utils/document";
import * as ROUTE from "../../constants/route";
import * as MENU from "../../constants/menu";
import { ColumnModel, GridComponent } from "@syncfusion/ej2-react-grids";
import DataGrid from "../common/dataGrid";
import ExcelExportButton from "../common/excelExportButton";
import ModifyButton from "../common/modifyButton";
import NewLink from "../common/newLink";

const columns: ColumnModel[] = [
  {
    field: "name",
    headerText: "Megnevezés",
  },
];
const TransactionTypes = ({ history }: RouteComponentProps) => {
  const [supplies, setTransactionTypes] = useState<TransactionTypeDto[]>([]);
  const [
    selectedTransactionType,
    setSelectedTransactionType,
  ] = useState<TransactionTypeDto | null>(null);
  const grid = useRef<GridComponent | null>(null);

  useEffect(() => {
    const populateTransactionTypes = async () => {
      const { data } = await transactionTypeService.getTransactionTypes();
      setTransactionTypes(data);
    };

    populateTransactionTypes();
  }, []);

  const handleModify = () => {
    if (!selectedTransactionType || !selectedTransactionType.manual) return;
    history.push(`${ROUTE.TRANSACTION_TYPES}/${selectedTransactionType.id}`);
  };

  const handleExport = async () => {
    if (!grid.current) return;
    grid.current.excelExport({ fileName: "szolgaltatok.xlsx" });
  };

  if (!supplies) return <>Betöltés...</>;

  setDocumentTitle(MENU.TRANSACTION_TYPES);

  return (
    <>
      <h1>{MENU.TRANSACTION_TYPES}</h1>
      <NewLink to={`${ROUTE.TRANSACTION_TYPES}/new`} label="Új szolgáltató" />
      <ModifyButton
        disabled={!selectedTransactionType || !selectedTransactionType.manual}
        onClick={handleModify}
        label="Szolgáltató módosítása"
      />
      <ExcelExportButton onClick={handleExport} />
      <DataGrid
        id="transactionTypes"
        columns={columns}
        data={supplies}
        onGridReady={(g) => {
          grid.current = g;
        }}
        rowSelected={(selectedTransactionType: TransactionTypeDto) => {
          setSelectedTransactionType(selectedTransactionType);
        }}
        recordDoubleClick={handleModify}
      />
    </>
  );
};

export default TransactionTypes;
