import httpService from "./httpService";
import TankDto from "../models/tankDto";
import SaveTankDto from "../models/saveTankDto";

const baseUrl = "/api/tanks";

function getTanks() {
  return httpService.get<TankDto[]>(baseUrl);
}

function getTank(id: string) {
  return httpService.get<TankDto>(`${baseUrl}/${id}`);
}

function addTank(tank: SaveTankDto) {
  return httpService.post(baseUrl, tank);
}

function modifyTank(id: string, tank: SaveTankDto) {
  return httpService.put(`${baseUrl}/${id}`, tank);
}

function removeTank(id: string) {
  return httpService.delete(`${baseUrl}/${id}`);
}

export default {
  getTanks,
  getTank,
  addTank,
  modifyTank,
  removeTank
};
